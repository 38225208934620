import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { TransitTimes } from './model'

export const transitTimesFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.transittimes.map(
      ({
        forwardDuration,
        reverseDuration,
        extraForwardDuration,
        extraReverseDuration,
        ...data
      }) => {
        const item = new TransitTimes({
          forwardDuration: Duration.fromSeconds(forwardDuration),
          reverseDuration: Duration.fromSeconds(reverseDuration),
          extraForwardDuration: Duration.fromSeconds(extraForwardDuration),
          extraReverseDuration: Duration.fromSeconds(extraReverseDuration),
          ...data,
        })
        item.setExternals(externals)
        return item
      },
    ),
  setRelsFromSpec((t: TransitTimes, state: any) => ({
    corridor: registry
      .provide('corridorRepo')(state)
      .byId(t.corridorId),
    trainType: registry
      .provide('trainTypeRepo')(state)
      .byId(t.trainTypeId),
  })),
)
