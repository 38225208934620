import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { LocoEvent } from './model'

export const locoEventFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.locoevents.map(data => {
      const item = new LocoEvent(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((locoEvent: LocoEvent, state: any) => ({
    lococlass: registry
      .provide('lococlassRepo')(state)
      .byId(locoEvent._lococlassId),
    start: registry
      .provide('trainStartRepo')(state)
      .byId(locoEvent.startId),

    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(locoEvent.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${locoEvent.startId}:${locoEvent.templateLegId}`),
    foreignRailroad: registry
      .provide('foreignRailroadRepo')(state)
      .byId(locoEvent.foreignRailroadId),
  })),
)
