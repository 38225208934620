import {
  getRepairFactory,
  InstanceRepair,
} from 'src/service-design/shared/document/repairs'

import { LoadingAssignment } from './model'

export class MissingTaskForLoadingAssignment extends InstanceRepair {
  static collectionName = 'loadingassignments'

  static type = 'service-design::Missing Task for Loading Assignment'

  static message =
    'service-design::{{entity.kind}} assignment for {{entity.startLeg.start.name}} at {{location.name}} has no matching task'

  constructor(context) {
    super(context, {
      location:
        context.entity.kind === 'loading'
          ? context.entity.startLeg.origin
          : context.entity.startLeg.dest,
    })
  }

  static check(assignment: LoadingAssignment) {
    return !assignment.task
  }
}

export const getLoadingAssignmentRepairs = getRepairFactory([
  MissingTaskForLoadingAssignment,
])
