import { createSelector } from 'reselect'

import { YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION } from 'src/service-design/sd-plan/constants'
import { getFilteredCustomTasks } from 'src/service-design/sd-plan/selectors/custom-tasks'
import { getLocationSummaries } from 'src/service-design/sd-plan/selectors/location-summaries'
import { getIsSelected } from 'src/service-design/sd-plan/selectors/selection'
import { getFilteredServices } from 'src/service-design/sd-plan/selectors/services'
import { getFilteredTrainStarts } from 'src/service-design/sd-plan/selectors/trains'
import { getSelectedResourceProfiles } from 'src/service-design/sd-plan/selectors/yards'
import * as constants from 'src/service-design/shared/constants'
import { intersectionInclusive } from 'src/service-design/shared/utils/dates'

const adjustedEndTime = resourceProfile => {
  if (
    resourceProfile.endTimeLocalNormalized <
    resourceProfile.startTimeLocalNormalized
  ) {
    return resourceProfile.endTimeLocalNormalized + constants.SECONDS_PER_WEEK
  }
  return resourceProfile.endTimeLocalNormalized
}

export const adjustedTimes = (l1, l2) => {
  const startL1 =
    l1.startTimeLocalNormalized - YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION
  const startL2 =
    l2.startTimeLocalNormalized - YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION
  const endL1 = adjustedEndTime(l1) + YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION
  const endL2 = adjustedEndTime(l2) + YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION
  return [
    [startL1, endL1],
    [startL2, endL2],
  ]
}

export const pack = annotateProfiles => {
  const sorted = [...annotateProfiles].sort(
    (a, b) =>
      a.resourceProfile.startTimeLocalNormalized -
      b.resourceProfile.startTimeLocalNormalized,
  )

  const rows = []
  for (const lw of sorted) {
    let inserted = false
    for (const row of rows) {
      const first = row[0] && {
        startTimeLocalNormalized:
          row[0].resourceProfile.startTimeLocalNormalized +
          constants.SECONDS_PER_WEEK,
        endTimeLocalNormalized:
          row[0].resourceProfile.endTimeLocalNormalized +
          constants.SECONDS_PER_WEEK,
      }
      const last = row.slice(-1)[0]
      if (
        !last ||
        (!intersectionInclusive(
          ...adjustedTimes(lw.resourceProfile, last.resourceProfile),
        ) &&
          first &&
          !intersectionInclusive(...adjustedTimes(lw.resourceProfile, first)))
      ) {
        row.push(lw)
        inserted = true
        break
      }
    }
    if (!inserted) {
      rows.push([lw])
    }
  }

  return rows
    .map((row, idx) =>
      row.map(lw => ({
        yPosition: idx,
        ...lw,
      })),
    )
    .reduce((acc, row) => [...acc, ...row], [])
}

export const getYardGraphData = createSelector(
  getFilteredTrainStarts,
  getFilteredServices,
  getFilteredCustomTasks,
  getIsSelected, // note: getIsSelected takes (state, selected)
  getSelectedResourceProfiles,
  getLocationSummaries,
  (
    filterTrainStarts,
    filterServices,
    filterCustomTasks,
    isSelected,
    resourceProfilesByLocation,
    locationSummaries,
  ) =>
    resourceProfilesByLocation.map(({ location, resourceProfiles }) => {
      const locationSummary = locationSummaries.find(
        x => x.location === location,
      )
      return {
        location,
        resourceProfiles: pack(
          resourceProfiles
            // Below prevents problems with ShiftResourceProfile not having a target,
            //   results in the gantt not being shown for it
            .filter(r => r.target)
            .map(l => ({
              classes: {
                warnings: l.target.warnings.length > 0,
                selected: isSelected(l.target),
                dimmed:
                  !filterTrainStarts.includes(l.target) &&
                  !filterServices.includes(l.target) &&
                  !filterCustomTasks.includes(l.target),
              },
              resourceProfile: {
                startTimeLocalNormalized: l.startTimeLocalNormalized,
                endTimeLocalNormalized: l.endTimeLocalNormalized,
                target: l.target,
                activities: l.activities.map(a => {
                  const [startTimeLocal, endTimeLocal] =
                    a.startTimeLocal > a.endTimeLocal
                      ? [a.endTimeLocal, a.startTimeLocal]
                      : [a.startTimeLocal, a.endTimeLocal]
                  return {
                    kind: a.kind,
                    startTimeLocal,
                    endTimeLocal,
                    classes: {
                      'start-bookend': a.kind === 'post-arrival',
                      'end-bookend': a.kind === 'pre-departure',
                    },
                  }
                }),
              },
            })),
        ),
        locationSummary: {
          min: locationSummary.min,
          max: locationSummary.max,
          resourceBalances: locationSummary.resourceBalances
            .map(x => ({
              ...x,
              classes: {
                selected: isSelected(x.resource),
              },
            }))
            .sort((a, b) => a.classes.selected - b.classes.selected),
        },
      }
    }),
)
