import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { RemoteRest } from './model'

export const remoteRestFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.remoterests.map(data => {
      const item = new RemoteRest(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((remoteRest: RemoteRest, state: any) => ({
    startShift: registry
      .provide('shiftRepo')(state)
      .byId(remoteRest.startShiftId),
    nextShift: registry
      .provide('shiftRepo')(state)
      .byId(remoteRest.nextShiftId),
    pool: registry
      .provide('crewPoolRepo')(state)
      .byId(remoteRest.poolId),
    location: registry
      .provide('locationRepo')(state)
      .byId(remoteRest.locationId),
  })),
)
