import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { Corridor } from './model'

export const corridorFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.corridors.map(({ minHeadway, ...data }) => {
      const item = new Corridor({
        minHeadway: Duration.fromSeconds(minHeadway),
        ...data,
      })
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((corridor: Corridor, state: any) => ({
    accessGroup: registry
      .provide('accessGroupRepo')(state)
      .byId(corridor.accessGroupId),
    loc1: registry
      .provide('locationRepo')(state)
      .byId(corridor.loc1Id),
    loc2: registry
      .provide('locationRepo')(state)
      .byId(corridor.loc2Id),
    loadtables: registry
      .provide('loadTableRepo')(state)
      .byCorridorId(corridor.id),
    transittimes: registry
      .provide('transitTimesRepo')(state)
      .byCorridorId(corridor.id),
  })),
)
