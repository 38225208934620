import { Indexer } from 'src/service-design/shared/document-factory/indexer'
import { IRepo } from 'src/service-design/shared/document-factory/IRepo'

import { CompoundShift } from './model'

export interface ICompoundShiftRepo extends IRepo<CompoundShift> {
  byPoolId(id: string): CompoundShift[]
  byShiftId(shiftId: string): CompoundShift
}

export class CompoundShiftRepo implements ICompoundShiftRepo {
  values: CompoundShift[]
  idMap: { [key: string]: CompoundShift }
  byPoolIdMap: Indexer<CompoundShift>
  byShiftIdMap: Map<string, CompoundShift>

  constructor(values: CompoundShift[]) {
    this.values = values
    this.idMap = Object.fromEntries(values.map(v => [v.id, v]))
    this.byPoolIdMap = new Indexer(values, x => x.poolId)
    this.byShiftIdMap = new Map(
      values.flatMap(value => value.shiftIds.map(shiftId => [shiftId, value])),
    )
  }

  byId(id: string): CompoundShift {
    return this.idMap[id]
  }

  byPoolId(poolId: string): CompoundShift[] {
    return this.byPoolIdMap.lookup(poolId)
  }

  byShiftId(shiftId: string): CompoundShift {
    return this.byShiftIdMap.get(shiftId)
  }
}
