import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { trainGraphFactory } from './factory'
import { ITrainGraphRepo, TrainGraphRepo } from './repo'

const trainGraphRepo = defaultMemoize(
  (input: FactoryInput) => new TrainGraphRepo(trainGraphFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  trainGraphRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type TrainGraphRepoReg = {
  trainGraphRepo: FromDocumentFactory<FactoryInput, ITrainGraphRepo>
}

export function setup<R extends TrainGraphRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('trainGraphRepo', trainGraphRepo)
}

export { TrainGraph } from './model'
