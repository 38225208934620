import { LogMessage } from 'src/service-design/shared/models/log-message'

export class CoupledWagonAttach extends LogMessage {
  static type = 'service-design::Infeasible wagon allocation'

  static message =
    'service-design::Allocation of {{entity.quantity}} {{entity.wagon.name}} wagon(s) is not a multiple of the coupled set size ({{entity.wagon.coupledSetSize}})'

  static check(allocation) {
    return allocation.quantity % allocation.wagon.coupledSetSize !== 0
  }
}

export default {
  warnings: [CoupledWagonAttach],
}
