import URLSearchParams from 'url-search-params'

import { DocumentTypeToDocument } from 'src/service-design/shared/document/types'

export const getDocuments = state => state.documents
export const getDocumentErrors = state => state.errors
export const getNotifications = state => state.notifications

export const getDocument = (state, type) => getDocuments(state)[type]
export const getCollection = <
  T extends keyof DocumentTypeToDocument,
  K extends keyof DocumentTypeToDocument[T]
>(
  state,
  documentType: T,
  collectionName: K,
): DocumentTypeToDocument[T][K] =>
  getDocument(state, documentType).data[collectionName]

export const getDependenciesFactory = (documentType, relationships) => (
  state,
  collection,
  row,
) => {
  const arr = []
  const { collections } = relationships
  collections.forEach(targetCollection => {
    relationships.getRels(targetCollection).forEach(resource => {
      if (resource.collection === collection) {
        const ids = getCollection(state, documentType, targetCollection)
          .filter(o => o[resource.foreign] === row.id)
          .map(o => ({ id: o.id }))

        arr.push({
          allowCascadeDelete: resource.allowCascadeDelete,
          collection: targetCollection,
          foreignKey: resource.foreign,
          dependencyIds: ids,
        })
      }
    })
  })
  return {
    allowDelete:
      arr.filter(d => !d.allowCascadeDelete && d.dependencyIds.length)
        .length === 0,
    dependencies: arr,
  }
}

export const getFinderUrl = (document, location) => {
  if (document) {
    const { meta } = document
    if (meta.type === 'service-design') {
      return `/scenario/${meta.parentId}/service-design/${meta.id}`
    }
    return `/scenario/${meta.id}`
  }
  if (location) {
    if (
      ['/service-design/import', '/kcs-importer'].includes(location.pathname)
    ) {
      const search = new URLSearchParams(location.search)
      return `/scenario/${search.get('parentId')}`
    }
  }
  return ''
}
