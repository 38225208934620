import { decode as base64Decode } from 'base64-arraybuffer'
import { saveAs } from 'file-saver'

import { fetchDocuments } from 'src/service-design/shared/document/actions'
import { Document } from 'src/service-design/shared/document/types'

import { EXPORT_FLEET_PLAN } from './constants'
import * as ExportWorker from './export.worker'
import { exportWorker as untypedExportWorker } from './worker-shim'

// this is a hack so we can stub saveAs in cypress testing of file download
window.saveAs = saveAs

const exportWorker = untypedExportWorker as typeof ExportWorker

export const DOCUMENTS_EXPORT_STARTED = 'DOCUMENTS_EXPORT_STARTED'
export const DOCUMENTS_EXPORT_LOADED = 'DOCUMENTS_EXPORT_LOADED'
export const DOCUMENTS_EXPORT_SUCCESS = 'DOCUMENTS_EXPORT_SUCCESS'
export const DOCUMENTS_EXPORT_FAILURE = 'DOCUMENTS_EXPORT_FAILURE'

export function documentsExportStarted() {
  return { type: DOCUMENTS_EXPORT_STARTED }
}

export function documentsForExportLoaded(documents) {
  return { type: DOCUMENTS_EXPORT_LOADED, payload: documents }
}

export function documentsExportSuccess() {
  return { type: DOCUMENTS_EXPORT_SUCCESS }
}

export function documentsExportFailure(error) {
  return { type: DOCUMENTS_EXPORT_FAILURE, payload: error }
}

const downloadExportResult = async (base64Result: string, fileName: string) => {
  const contentType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const b64array = base64Decode(base64Result)
  const blobbed = new Blob([b64array], { type: contentType })
  const fileToSave = new File([blobbed], fileName, { type: contentType })
  window.saveAs(fileToSave)
}

const getFileName = (documents: Document[]): string => {
  const documentType = documents[0].meta.type
  let exportName =
    documents.length === 1 ? documents[0].meta.name : `${documentType}`
  if (exportName.slice(-5) !== '.xlsx') {
    exportName += '.xlsx'
  }
  return exportName
}

export function exportDocumentAction(documentId: number) {
  return exportDocumentsAction([documentId])
}

export function exportFleetPlansAction(documentIds: number[]) {
  // TODO finder-restructure this is a kcs-specific action and so should belong in the kcs top level folder
  return exportDocumentsAction(documentIds, EXPORT_FLEET_PLAN)
}

export function exportDocumentsAction(
  documentIds: number[],
  exportTypeOverride: string = null,
) {
  return async dispatch => {
    dispatch(documentsExportStarted())
    const { documents, parentDocuments } = await fetchDocuments(documentIds)

    const exportType = exportTypeOverride || documents[0].meta.type

    const fileStr = await exportWorker.exportDocuments(
      exportType,
      documents,
      parentDocuments,
    )
    const fileName = getFileName(documents)
    await downloadExportResult(fileStr, fileName)
    dispatch(documentsExportSuccess())
  }
}
