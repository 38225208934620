import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { customTaskFactory } from './factory'
import { ICustomTaskRepo, CustomTaskRepo } from './repo'

const customTaskRepo = defaultMemoize(
  (input: FactoryInput) => new CustomTaskRepo(customTaskFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  customTaskRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type CustomTaskRepoReg = {
  customTaskRepo: FromDocumentFactory<FactoryInput, ICustomTaskRepo>
}

export function setup<R extends CustomTaskRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('customTaskRepo', customTaskRepo)

  relationships.addConstraints('customtasks', {
    rels: [
      {
        collection: 'locations',
        foreign: 'startLocationId',
        name: 'startLocation',
      },
      {
        collection: 'locations',
        foreign: 'endLocationId',
        name: 'endLocation',
      },
    ],
  })
}

export { CustomTask } from './model'
