import { WorkbookDefinition } from 'src/service-design/shared/exporter/worksheet'
import * as LocationChangeoverLock from 'src/service-design/shared/models/changeover-lock/location'
import * as TrainChangeoverLock from 'src/service-design/shared/models/changeover-lock/train'
import * as LocationChangeover from 'src/service-design/shared/models/changeover/location'
import * as TrainChangeover from 'src/service-design/shared/models/changeover/train'
import * as CrewPool from 'src/service-design/shared/models/crew-pool'
import * as CustomTask from 'src/service-design/shared/models/custom-task'
import * as EmptyWagonEvent from 'src/service-design/shared/models/empty-wagon-event'
import * as RosterHead from 'src/service-design/shared/models/linked-list-head/roster'
import * as LoadingWorkSplit from 'src/service-design/shared/models/loading-work-split'
import * as LocoEvent from 'src/service-design/shared/models/loco-event'
import * as RDO from 'src/service-design/shared/models/rdo'
import * as RemoteRest from 'src/service-design/shared/models/remote-rest'
import * as ResourceAvailability from 'src/service-design/shared/models/resource-availability'
import * as ResourceUnavailability from 'src/service-design/shared/models/resource-unavailability'
import * as RosterLine from 'src/service-design/shared/models/roster-line'
import * as Service from 'src/service-design/shared/models/service'
import * as ServiceEvent from 'src/service-design/shared/models/service-event'
import * as ServiceLock from 'src/service-design/shared/models/service-lock'
import * as Shift from 'src/service-design/shared/models/shift'
import * as CustomTaskAssignment from 'src/service-design/shared/models/shift-assignment/custom-task'
import * as DriverAssignment from 'src/service-design/shared/models/shift-assignment/driver'
import * as LoadingAssignment from 'src/service-design/shared/models/shift-assignment/loading'
import * as LocalAssignment from 'src/service-design/shared/models/shift-assignment/local'
import * as UncrewedLegTask from 'src/service-design/shared/models/shift-assignment/uncrewed-leg-task'
import * as ShiftLine from 'src/service-design/shared/models/shift-line'
import * as StartLegTaskOffset from 'src/service-design/shared/models/start-leg-task-offset'
import * as TemplateLeg from 'src/service-design/shared/models/template-leg'
import * as TrainBlock from 'src/service-design/shared/models/train-block'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import * as TrainTemplate from 'src/service-design/shared/models/train-template'
import * as WagonAllocation from 'src/service-design/shared/models/wagon-allocation'
import * as WagonSet from 'src/service-design/shared/models/wagon-set'
import * as WorkingLocoLock from 'src/service-design/shared/models/working-loco-lock'
import * as YardBlock from 'src/service-design/shared/models/yard-block'
import * as YardBlockAssignment from 'src/service-design/shared/models/yard-block-assignment'

import { flags } from './flags'
import * as schemas from './schemas'
import * as selectors from './selectors'

export const workSheets = new WorkbookDefinition([
  {
    sheet: 'Demands',
    type: 'collection',
    collection: 'services',
    schema: schemas.Service,
    selector: Service.values,
    exclude: !flags.freightServices,
  },
  {
    sheet: 'Demand Events',
    type: 'collection',
    collection: 'serviceevents',
    schema: schemas.ServiceEvent,
    selector: ServiceEvent.values,
    exclude: !flags.freightServices,
  },
  {
    sheet: 'Demand Locks',
    type: 'collection',
    collection: 'servicelocks',
    selector: ServiceLock.values,
    schema: schemas.ServiceLock,
    exclude: !flags.freightServices,
  },
  {
    sheet: 'Wagon Sets',
    type: 'collection',
    collection: 'wagonsets',
    selector: WagonSet.values,
    schema: schemas.WagonSet,
    exclude: !flags.freightServices,
  },
  {
    sheet: 'Wagon Allocations',
    type: 'collection',
    collection: 'wagonallocations',
    selector: WagonAllocation.values,
    schema: schemas.WagonAllocation,
    exclude: !flags.freightServices,
  },
  {
    sheet: 'Empty Wagon Events',
    type: 'collection',
    collection: 'emptywagonevents',
    schema: schemas.EmptyWagonEvent,
    selector: EmptyWagonEvent.values,
    exclude: !flags.emptyWagons,
  },
  {
    sheet: 'Resource Availability',
    type: 'collection',
    collection: 'resourceavailabilities',
    schema: schemas.ResourceAvailability,
    selector: ResourceAvailability.values,
  },
  {
    sheet: 'Resource Unavailabilities',
    type: 'collection',
    collection: 'resourceunavailabilities',
    schema: schemas.ResourceUnavailability,
    selector: ResourceUnavailability.values,
  },
  {
    sheet: 'Train Templates',
    type: 'collection',
    collection: 'traintemplates',
    schema: schemas.TrainTemplate,
    selector: TrainTemplate.values,
  },
  {
    sheet: 'Train Template Legs',
    type: 'collection',
    collection: 'templatelegs',
    schema: schemas.TemplateLeg,
    selector: TemplateLeg.values,
  },
  {
    sheet: 'Train Starts',
    type: 'collection',
    collection: 'trainstarts',
    schema: schemas.TrainStart,
    selector: TrainStart.values,
  },
  {
    sheet: 'Loco Events',
    type: 'collection',
    collection: 'locoevents',
    schema: schemas.LocoEvent,
    selector: LocoEvent.values,
  },
  {
    sheet: 'Working Loco Locks',
    type: 'collection',
    collection: 'workinglocolocks',
    schema: schemas.WorkingLocoLock,
    selector: WorkingLocoLock.values,
  },
  {
    sheet: 'Demand Chart#',
    type: 'export-collection',
    selector: selectors.getResourceEvents,
    schema: schemas.LocationSummary,
  },
  {
    sheet: 'Fleet Plan#',
    type: 'export-collection',
    selector: selectors.getFleetPlan,
    schema: schemas.FleetPlan,
  },
  {
    sheet: 'Crew Pools',
    type: 'collection',
    collection: 'crewpools',
    schema: schemas.CrewPool,
    selector: CrewPool.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Shifts',
    type: 'collection',
    collection: 'shifts',
    schema: schemas.Shift,
    selector: Shift.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Shift Lines',
    type: 'collection',
    collection: 'shiftlines',
    schema: schemas.ShiftLine,
    selector: ShiftLine.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Remote Rests',
    type: 'collection',
    collection: 'remoterests',
    schema: schemas.RemoteRest,
    selector: RemoteRest.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Driver Assignments',
    type: 'collection',
    collection: 'driverassignments',
    schema: schemas.DriverAssignment,
    selector: DriverAssignment.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Local Assignments',
    type: 'collection',
    collection: 'localassignments',
    schema: schemas.LocalAssignment,
    selector: LocalAssignment.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Uncrewed Leg Tasks',
    type: 'collection',
    collection: 'uncrewedlegtasks',
    schema: schemas.UncrewedLegTask,
    selector: UncrewedLegTask.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Start Leg Task Offsets',
    type: 'collection',
    collection: 'startlegtaskoffsets',
    schema: schemas.StartLegTaskOffset,
    selector: StartLegTaskOffset.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Location Changeovers',
    type: 'collection',
    collection: 'locationchangeovers',
    schema: schemas.LocationChangeover,
    selector: LocationChangeover.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Train Changeovers',
    type: 'collection',
    collection: 'trainchangeovers',
    schema: schemas.TrainChangeover,
    selector: TrainChangeover.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Location Changeover Locks',
    type: 'collection',
    collection: 'locationchangeoverlocks',
    schema: schemas.LocationChangeoverLock,
    selector: LocationChangeoverLock.values,
  },
  {
    sheet: 'Train Changeover Locks',
    type: 'collection',
    collection: 'trainchangeoverlocks',
    schema: schemas.TrainChangeoverLock,
    selector: TrainChangeoverLock.values,
  },
  {
    sheet: 'Miscellaneous Tasks',
    type: 'collection',
    collection: 'customtasks',
    schema: schemas.CustomTask,
    selector: CustomTask.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Miscellaneous Task Assignments',
    type: 'collection',
    collection: 'customtaskassignments',
    schema: schemas.CustomTaskAssignment,
    selector: CustomTaskAssignment.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Loading Assignments',
    type: 'collection',
    collection: 'loadingassignments',
    schema: schemas.LoadingAssignment,
    selector: LoadingAssignment.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Loading Work Handovers',
    type: 'collection',
    collection: 'loadingworksplits',
    schema: schemas.LoadingWorkSplit,
    selector: LoadingWorkSplit.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'LV Assignments#',
    type: 'export-collection',
    selector: selectors.getLVTasks,
    schema: schemas.LVAssignment,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Roster Lines',
    type: 'collection',
    collection: 'rosterlines',
    schema: schemas.RosterLine,
    selector: RosterLine.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Roster Heads',
    type: 'collection',
    collection: 'rosterheads',
    schema: schemas.RosterHead,
    selector: RosterHead.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'RDOs',
    type: 'collection',
    collection: 'rdos',
    schema: schemas.RDO,
    selector: RDO.values,
    exclude: !flags.crewing,
  },
  {
    sheet: 'Loco Engine',
    type: 'singleton',
    singleton: 'swiss',
    schema: schemas.SwissParameters,
  },
  {
    sheet: 'Demand and Wagon Engine',
    type: 'singleton',
    singleton: 'pluto',
    schema: schemas.PlutoParameters,
  },
  {
    sheet: 'Shift Engine',
    type: 'singleton',
    singleton: 'ceto',
    schema: schemas.CetoParameters,
  },
  {
    sheet: 'Pathing Engine',
    type: 'singleton',
    singleton: 'pallas',
    schema: schemas.PallasParameters,
  },
  {
    sheet: 'Rostering Engine',
    type: 'singleton',
    singleton: 'sedna',
    schema: schemas.SednaParameters,
  },
  {
    sheet: 'Costs',
    type: 'export-collection',
    selector: selectors.getCosts,
    schema: schemas.Cost,
  },
  {
    sheet: 'Yard Blocks',
    type: 'collection',
    collection: 'yardblocks',
    schema: schemas.YardBlock,
    selector: YardBlock.values,
    exclude: !flags.blocks,
  },
  {
    sheet: 'Train Blocks',
    type: 'collection',
    collection: 'trainblocks',
    schema: schemas.TrainBlock,
    selector: TrainBlock.values,
    exclude: !flags.blocks,
  },
  {
    sheet: 'Yard Block Assignments',
    type: 'collection',
    collection: 'yardblockassignments',
    schema: schemas.YardBlockAssignment,
    selector: YardBlockAssignment.values,
    exclude: !flags.blocks,
  },
])

export const fleetPlanWorkbook = new WorkbookDefinition(
  workSheets.sheets.filter(x => x.sheet === 'Fleet Plan#'),
)

if (fleetPlanWorkbook.sheets.length !== 1) {
  throw new Error('Expected exactly one sheet in the fleet plan workbook')
}
