import { maxBy } from 'lodash'

import { SECONDS_PER_WEEK } from 'src/service-design/shared/constants'
import { ChangeoverLock } from 'src/service-design/shared/models/changeover-lock/model'
import { Location } from 'src/service-design/shared/models/location'
import { TemporalMapper } from 'src/service-design/shared/models/mapper/temporal'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { normalize, Duration } from 'src/service-design/shared/utils/dates'

import validators from './validator'

export interface Timing {
  start: number
  end: number
}

function normalizeIt({ start, end }) {
  const startNormalized = normalize(start)
  return {
    start: startNormalized,
    end: end - start + startNormalized,
  }
}

function normalizeMeets(meets: Timing[]): Timing[] {
  // Sync all the meets to they are as close together as
  // possible in an unwrapped sense.
  const normalized = meets.map(normalizeIt)
  const latest = maxBy(normalized, m => m.start)
  return normalized.map(({ start, end }) =>
    Math.abs(start + SECONDS_PER_WEEK - latest.start) <
    Math.abs(start - latest.start)
      ? { start: start + SECONDS_PER_WEEK, end: end + SECONDS_PER_WEEK }
      : { start, end },
  )
}

abstract class Changeover extends TemporalMapper {
  id: string
  atDeparture: boolean
  _timeOffset: Duration
  singletons: any
  changeoverLock: ChangeoverLock<Changeover>

  static validators = validators

  /**
   * A Changeover is a transaction where one or more TrainStarts change their
   * driver. See TrainChangeover and LocationChangeover.
   *
   * A Changeover associated with a TrainStart is used to break its
   * journey into crewable DriverTasks.
   *
   * Since TrainStarts moving during pre-departure and post-arrival tasks,
   * Changeovers need to be fully contained within the end of pre-departure
   * and the start of post-arrival. The length of changeover is configured in
   * the scenario `crew.changeoverSecs`.
   *
   * Related models:
   * - `DriverTask`;
   * - `LocationChangeover`;
   * - `TrainChangeover`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {boolean} atDeparture - If true, the changeover should happen as
   *  late as possible within the dwell. If false, the changeover should
   *  commence as soon as post-arrival completes.

   **/
  constructor({
    id,
    atDeparture,
    timeOffset,
  }: {
    id: string
    atDeparture: boolean
    timeOffset: Duration
  }) {
    super()
    this.id = id
    this.atDeparture = atDeparture
    this._timeOffset = timeOffset
  }

  setRels({
    singletons,
    changeoverLock,
  }: {
    singletons: any
    changeoverLock: ChangeoverLock<Changeover>
  }) {
    this.singletons = singletons
    this.changeoverLock = changeoverLock
  }

  protected abstract get timing(): Timing
  abstract get name(): string
  abstract get location(): Location
  abstract get legs(): StartLeg[]
  abstract get dwellWindow(): Timing
  abstract legFor(trainStart: TrainStart): StartLeg

  get timeOffset(): number {
    return this._timeOffset.toSeconds()
  }

  get startTimeLocal(): number {
    return this.timing.start + this.relativeOffset
  }

  get endTimeLocal(): number {
    return this.timing.end + this.relativeOffset
  }

  get relativeOffset(): number {
    return this.timeOffset * (this.atDeparture ? -1 : 1)
  }

  get desc(): string {
    return `${this.atDeparture ? 'dep' : 'arr'} ${this.location.code}`
  }

  static meets(startLegs: StartLeg[], atDeparture: boolean): Timing[] {
    if (atDeparture) {
      return normalizeMeets(
        startLegs.map(leg => ({
          start: leg.prev
            ? leg.prev.getTask('post-arrival').endTimeLocal
            : leg.getTask('attach').startTimeLocal,
          end: leg.getTask('pre-departure').startTimeLocal,
        })),
      )
    }

    return normalizeMeets(
      startLegs.map(leg => ({
        start: leg.getTask('post-arrival').endTimeLocal,
        end: leg.next
          ? leg.next.getTask('pre-departure').startTimeLocal
          : leg.getTask('detach').endTimeLocal,
      })),
    )
  }

  static timingsFromLegs(
    startLegs: StartLeg[],
    atDeparture: boolean,
    changeoverSecs: number,
  ): Timing {
    const timing = {} as Timing
    const meets = this.meets(startLegs, atDeparture)
    if (atDeparture) {
      timing.end = Math.min(...meets.map(m => m.end))
      timing.start = Math.max(
        timing.end - changeoverSecs,
        ...meets.map(m => m.start),
      )
    } else {
      timing.start = Math.max(...meets.map(m => m.start))
      timing.end = Math.min(
        timing.start + changeoverSecs,
        ...meets.map(m => m.end),
      )
    }
    return normalizeIt(timing)
  }

  static dwellWindow(startLegs: StartLeg[], atDeparture: boolean): Timing {
    const meets = this.meets(startLegs, atDeparture)
    return normalizeIt({
      start: Math.max(...meets.map(m => m.start)),
      end: Math.min(...meets.map(m => m.end)),
    })
  }
}

export { Changeover }
