import { flags } from 'src/service-design/shared/flags'
import { MissingShiftAssignment } from 'src/service-design/shared/models/shift-assignment/validator'

export class MissingLegTaskAssignment extends MissingShiftAssignment {
  static check(legTask) {
    return super.check(legTask) && legTask.requiresAssignment
  }
}

export default {
  warnings: flags.crewing ? [MissingLegTaskAssignment] : [],
}
