type ValidChecker = any

export class Validator {
  static validators: { warnings?: ValidChecker[]; errors?: ValidChecker[] }
  _externals: any // TODO DJH
  _warnings: any[] // TODO DJH

  /**
   * A class that understands how to compute warnings and errors. Presently
   * that majority of Warnings and Errors are generated via this model.
   */
  constructor() {
    this._externals = {}
  }

  static getValidators(type: string) {
    let constructor = this
    const result = new Set<ValidChecker>()
    while (constructor !== Validator) {
      const v = constructor.validators || {}
      if (v[type]) {
        v[type].forEach(i => result.add(i))
      }

      constructor = Object.getPrototypeOf(constructor)
    }
    return [...result]
  }

  static getValidatorTree() {
    return this.getValidators('warnings')
  }

  getValidators(type: string): ValidChecker[] {
    return (this.constructor as any).getValidators(type) // TODO DJH
  }

  get externals() {
    return this._externals
  }

  setExternals(externals: any) {
    this._externals = externals
  }

  get preferences() {
    return this.externals.preferences
  }

  validate(type: string) {
    return this.getValidators(type)
      .filter(v => {
        const { warningsBlacklist = [] } = this.preferences
        return !warningsBlacklist.includes(v.type) && v.check(this)
      })
      .map(V => new (V as any)({ entity: this })) // TODO DJH
  }

  get warnings() {
    if (!this._warnings) {
      this._warnings = this.validate('warnings')
    }
    return this._warnings
  }
}
