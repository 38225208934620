import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { timezoneFactory } from './factory'
import { ITimezoneRepo, TimezoneRepo } from './repo'

const timezoneRepo = defaultMemoize(
  (input: FactoryInput) => new TimezoneRepo(timezoneFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  timezoneRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type TimezoneRepoReg = {
  timezoneRepo: FromDocumentFactory<FactoryInput, ITimezoneRepo>
}

export function setup<R extends TimezoneRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('timezoneRepo', timezoneRepo)
}

export { Timezone } from './model'
