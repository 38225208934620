import React from 'react'
import { Form, Select, Dropdown } from 'semantic-ui-react'

import { FormLabel } from './FormLabel'

interface Input {
  name: string
  value: any
  onChange: (value: any) => void
  onFocus: (
    event: React.SyntheticEvent<HTMLElement>,
    data: { value: any },
  ) => void
  onBlur: (
    event: React.KeyboardEvent<HTMLElement>,
    data: { value: any },
  ) => void
}

interface Props {
  input: Input
  type?: string
  label?: string
  placeholder?: string
  meta?: {
    touched: boolean
    error?: string
    warning?: string
  }
  as?: any
  noLabel?: boolean
  noBlur?: boolean
  search?: boolean
  [x: string]: any
  className?: string
}

export const FormField: React.FC<Props> = ({
  input,
  type = null,
  label = null,
  placeholder = null,
  noLabel = false,
  noBlur = false,
  meta: { touched, error, warning } = {
    touched: false,
    error: null,
    warning: null,
  },
  as: As = Form.Input,
  className = '',
  ...props
}) => {
  function handleChange(
    event: React.SyntheticEvent<HTMLElement>,
    data: { value: any },
  ) {
    return input.onChange(data.value)
  }

  function handleFocus(event: React.SyntheticEvent<HTMLElement>, data: any) {
    return input.onFocus(event, data)
  }

  function handleBlur(
    event: React.KeyboardEvent<HTMLElement>,
    data: { value: any },
  ) {
    if (noBlur) {
      return null
    }
    // This is a fix extracted from FormDropbox for onBlur not working with searchable dropdowns
    if (
      props.search === true &&
      As.name &&
      (As.name === Form.Select.name ||
        As.name === Form.Dropdown.name ||
        As.name === Select.name ||
        As.name === Dropdown.name)
    ) {
      return input.onChange(data.value)
    }
    return input.onBlur(event, data)
  }
  return (
    <Form.Field className={className}>
      {!noLabel && (
        <FormLabel
          htmlFor={input.name}
          label={label}
          touched={touched}
          error={error}
          warning={warning}
        />
      )}
      <As
        {...props}
        {...input}
        value={input.value}
        type={type}
        error={Boolean(error) && touched}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Form.Field>
  )
}
