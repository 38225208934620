import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { localAssignmentFactory } from './factory'
import { ILocalAssignmentRepo, LocalAssignmentRepo } from './repo'

const localAssignmentRepo = defaultMemoize(
  (input: FactoryInput) =>
    new LocalAssignmentRepo(localAssignmentFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  localAssignmentRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type LocalAssignmentRepoReg = {
  localAssignmentRepo: FromDocumentFactory<FactoryInput, ILocalAssignmentRepo>
}

export function setup<R extends LocalAssignmentRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('localAssignmentRepo', localAssignmentRepo)

  relationships.addConstraints('localassignments', {
    unique: [['templateLegId', 'startId', 'kind']],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
      {
        collection: 'shifts',
        foreign: 'shiftId',
        name: 'shift',
      },
    ],
  })
}

export { LocalAssignment } from './model'
