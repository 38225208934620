import { Mapper } from 'src/service-design/shared/models/mapper'
import validators from 'src/service-design/shared/models/shift-assignment/validator'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'

type UncrewedLegTaskKind =
  | 'attach'
  | 'detach'
  | 'pre-departure'
  | 'loco-provisioning'

class UncrewedLegTask extends Mapper {
  static validators = validators

  id: string
  startId: string
  templateLegId: string
  kind: UncrewedLegTaskKind
  start: TrainStart
  templateLeg: TemplateLeg
  startLeg: StartLeg

  /**
   * A UncrewedLegTask marks a LegTask as not requiring crew. This gives the
   * operator the ability to hide the task from the list of assignable tasks
   * and ensure that the task will not warning about being assigned.
   *
   * Related models:
   * - `LegTask`;
   * - `LocalAssignment`;
   * - `TrainStart`.
   *
   * @constructor
   * @param {string} id - The Entity id.
   * @param {string} startId - The id of the TrainStart which has the LegTask.
   * @param {string} templateLegId - The id of the TemplateLeg which has the
   *  LegTask.
   * @param {string} kind - The kind of the LegTask.
   **/
  constructor({
    id,
    startId,
    templateLegId,
    kind,
  }: {
    id: string
    startId: string
    templateLegId: string
    kind: UncrewedLegTaskKind
  }) {
    super()
    this.id = id
    this.startId = startId
    this.templateLegId = templateLegId
    this.kind = kind
  }

  setRels({
    start,
    templateLeg,
    startLeg,
  }: {
    start: TrainStart
    templateLeg: TemplateLeg
    startLeg: StartLeg
  }) {
    this.start = start
    this.templateLeg = templateLeg
    this.startLeg = startLeg
  }
}

export { UncrewedLegTask }
