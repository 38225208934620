import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { RosterHead } from './model'

export const rosterHeadFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.rosterheads.map(data => {
      const item = new RosterHead(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((rosterHead: RosterHead, state: any) => ({
    parent: registry
      .provide('crewPoolRepo')(state)
      .byId(rosterHead.parentId),
    head: registry
      .provide('rosterLineRepo')(state)
      .byId(rosterHead.headId),
  })),
)
