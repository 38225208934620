import { FactoryInput } from 'src/service-design/shared/document-factory'
import { createFactoryFromDocument } from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { Timezone } from './model'

export const timezoneFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.timezones.map(({ offset, ...data }) => {
      const item = new Timezone({
        offset: Duration.fromSeconds(offset),
        ...data,
      })
      item.setExternals(externals)
      return item
    }),
)
