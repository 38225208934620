import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { resourceAvailabilityFactory } from './factory'
import { IResourceAvailabilityRepo, ResourceAvailabilityRepo } from './repo'

const resourceAvailabilityRepo = defaultMemoize(
  (input: FactoryInput) =>
    new ResourceAvailabilityRepo(resourceAvailabilityFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  resourceAvailabilityRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type ResourceAvailabilityRepoReg = {
  resourceAvailabilityRepo: FromDocumentFactory<
    FactoryInput,
    IResourceAvailabilityRepo
  >
}

export function setup<R extends ResourceAvailabilityRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('resourceAvailabilityRepo', resourceAvailabilityRepo)

  relationships.addConstraints('resourceavailabilities', {
    rels: [
      {
        collection: 'lococlasses',
        foreign: 'lococlassId',
        name: 'lococlass',
      },
      {
        collection: 'wagons',
        foreign: 'wagonId',
        name: 'wagon',
      },
    ],
  })
}

export { ResourceAvailability } from './model'
