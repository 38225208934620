import { flags } from 'src/service-design/shared/flags'
import { MissingShiftAssignment } from 'src/service-design/shared/models/shift-assignment/validator'

class LoadingWorkTaskMissingShiftAssignment extends MissingShiftAssignment {
  static check(task) {
    return task.requiresAssignment && super.check(task)
  }
}
export default {
  warnings: flags.crewing ? [LoadingWorkTaskMissingShiftAssignment] : [],
}
