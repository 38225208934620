import { Mapper } from 'src/service-design/shared/models/mapper'
import { RosterLine } from 'src/service-design/shared/models/roster-line'
import { Shift } from 'src/service-design/shared/models/shift'

interface Attrs {
  id: string
  shiftId: string
  rosterLineId: string
}

interface Rels {
  shift: Shift
  rosterLine: RosterLine
}

class ShiftLine extends Mapper {
  /**
   * A ShiftLine associates a Shift with a RosterLine.
   *
   * Related models:
   * - `ShiftLine`;
   * - `Shift`;
   * - `RosterLine`;
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} shiftId - The Shift id.
   * @param {string} rosterLineId - The RosterLine id.
   **/
  constructor({ id, shiftId, rosterLineId }: Attrs) {
    super()
    this.id = id
    this.shiftId = shiftId
    this.rosterLineId = rosterLineId
  }

  setRels({ shift, rosterLine }: Rels) {
    this.shift = shift
    this.rosterLine = rosterLine
  }

  get fatigue() {
    return this.externals.fatigue[this.id]
  }
}

interface ShiftLine extends Attrs, Rels {}
export { ShiftLine }
