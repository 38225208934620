import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { remoteRestFactory } from './factory'
import { getRemoteRestRepairs } from './repairs'
import { IRemoteRestRepo, RemoteRestRepo } from './repo'

const remoteRestRepo = defaultMemoize(
  (input: FactoryInput) => new RemoteRestRepo(remoteRestFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  remoteRestRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type RemoteRestRepoReg = {
  remoteRestRepo: FromDocumentFactory<FactoryInput, IRemoteRestRepo>
}

export function setup<R extends RemoteRestRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('remoteRestRepo', remoteRestRepo)

  relationships.addConstraints('remoterests', {
    // each shift may only have one remoteRest at its start or end
    unique: [['startShiftId'], ['nextShiftId']],
    rels: [
      {
        collection: 'crewpools',
        foreign: 'poolId',
        name: 'pool',
      },
      {
        collection: 'shifts',
        foreign: 'startShiftId',
        name: 'startShift',
      },
      {
        collection: 'shifts',
        foreign: 'nextShiftId',
        name: 'nextShift',
      },
      {
        collection: 'locations',
        foreign: 'locationId',
        name: 'location',
      },
    ],
  })

  repairs.register(createSelector(values, getRemoteRestRepairs))
}

export { RemoteRest } from './model'
