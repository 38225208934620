import { uniqBy } from 'lodash'
import { flow, flatten, flatMap, filter, map, groupBy } from 'lodash/fp'
import { createSelector, createStructuredSelector } from 'reselect'

import { getConflictWarnings } from 'src/service-design/sd-plan/selectors/conflicts'
import { getCongestionWarnings } from 'src/service-design/sd-plan/selectors/congestion'
import {
  getLococlassSummaries,
  getWagonSummaries,
  getLVSummaries,
} from 'src/service-design/sd-plan/selectors/resource-summaries'
import * as constants from 'src/service-design/shared/constants'
import * as CrewPool from 'src/service-design/shared/models/crew-pool'
import * as CustomTask from 'src/service-design/shared/models/custom-task'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { ResourceSummary } from 'src/service-design/shared/models/resource-summaries'
import * as Service from 'src/service-design/shared/models/service'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import { Cost } from 'src/service-design/shared/types'

export const ROOT_WARNING_MODELS = [
  TrainStart.TrainStart,
  Service.Service,
  CrewPool.CrewPool,
  ResourceSummary,
]

export const getWarnings = createSelector(
  getConflictWarnings,
  getCongestionWarnings,
  Service.values,
  TrainStart.values,
  getLococlassSummaries,
  getWagonSummaries,
  getLVSummaries,
  CrewPool.values,
  CustomTask.values,
  (conflictWarnings, congestionWarnings, ...args) => {
    const warnings = [
      ...conflictWarnings,
      ...congestionWarnings,
      ...args.flat().flatMap(i => i.warnings),
    ]

    warnings.forEach(w => {
      if (!(w instanceof LogMessage)) {
        console.warn(`"${w.type}" does not extend LogMessage`)
      }
    })
    const deduped = uniqBy(
      warnings.filter(x => x.dedupe === true),
      'id',
    )
    const noDedupeNeeded = warnings.filter(x => x.dedupe !== true)
    return [...deduped, ...noDedupeNeeded]
  },
)

export const getAggregatedWarnings = createSelector(getWarnings, warnings =>
  Object.entries(groupBy('type')(warnings)),
)

interface ICostable {
  costs: Cost[]
}

export const getCosts = createSelector(
  getLococlassSummaries,
  getWagonSummaries,
  getLVSummaries,
  StartLeg.values,
  CrewPool.values,
  (...args: ICostable[][]) =>
    flow(
      flatten,
      flatMap((item: ICostable) => item.costs),
      filter((item: Cost) => item.quantity > 0),
      map(item => ({ ...item, cost: item.quantity * item.rate })),
    )(args),
)

export const getCostBreakdown = createSelector(getCosts, csvData =>
  [
    ...csvData
      .reduce(
        (total, { costType, cost }) =>
          total.set(costType, total.get(costType) + cost),
        new Map(constants.COST_BREAKDOWN.map(type => [type, 0])),
      )
      .entries(),
  ].map(([text, cost]) => ({ text, cost })),
)

export const getTotalCost = createSelector(getCostBreakdown, breakdowns =>
  breakdowns.reduce((total, { cost }) => total + cost, 0),
)

export const getCostSummary = createStructuredSelector({
  breakdowns: getCostBreakdown,
  totalCost: getTotalCost,
})
