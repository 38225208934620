export const downloadCSV = fileData => {
  const blob = new Blob(
    [fileData.contents], // eslint-disable-line no-undef
    { type: fileData.mime },
  )
  const url = URL.createObjectURL(blob) // eslint-disable-line no-undef
  const link = document.createElement('a')
  if (typeof link.download === 'string') {
    document.body.appendChild(link)
    link.download = fileData.filename
    link.href = url
    link.click()
    document.body.removeChild(link)
  }
}
