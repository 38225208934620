import { IResource } from 'src/service-design/shared/models/resource-summaries/types'
import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'

export class DetachDelay<T extends IStartLeg> extends LegTask<T> {
  /**
   * DetachDelay captures any delay between PostArrival and Detach.
   *
   * It is sometimes useful to perform Detach with a delay after PostArrival.
   * into a Location.
   *
   * Related:
   * - AttachAdvance
   * - StartTaskLegOffset
   */
  static arrivingTask = true

  static kind = 'detach delay'

  static requiresRailOperator = false

  static timeOffsetAllowed = false

  static build(startLeg: IStartLeg) {
    return startLeg.postArriveEndLocal !== startLeg.detachStartLocal
      ? new this({
          origin: startLeg.dest,
          destination: startLeg.dest,
          startTimeLocal: startLeg.postArriveEndLocal,
          endTimeLocal: startLeg.detachStartLocal,
          startLeg,
          externals: startLeg.externals,
        })
      : null
  }

  get resourceDict() {
    return this.startLeg.resourceDict
  }

  get workingLocosDict() {
    return this.startLeg.consist.workingDict
  }

  get hauledLocosDict() {
    return this.startLeg.consist.hauledDict
  }

  get wagonDict() {
    return this.startLeg.wagonDict
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts<IResource>(
      this.duration,
      this.workingLocosDict,
      this.wagonDict,
    )
  }

  get totalHauledSecs() {
    return mergeMultiplyCounts(this.duration, this.hauledLocosDict)
  }
}
