import { createSelector } from 'reselect'

import { getSingletons as getScenarioSingletons } from 'src/service-design/scenario/selectors'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import * as Corridor from 'src/service-design/shared/models/corridor'
import * as Location from 'src/service-design/shared/models/location'

export const getRawBusinessGroups = state =>
  getCollection(state, 'scenario', 'businessgroups')
export const getRawCargoTypes = state =>
  getCollection(state, 'scenario', 'cargotypes')
export const getRawCorridors = state =>
  getCollection(state, 'scenario', 'corridors')
export const getRawLoadTables = state =>
  getCollection(state, 'scenario', 'loadtables')
export const getRawLocations = state =>
  getCollection(state, 'scenario', 'locations')
export const getRawTrainTypes = state =>
  getCollection(state, 'scenario', 'traintypes')
export const getRawTimezones = state =>
  getCollection(state, 'scenario', 'timezones')
export const getRawTransitTimes = state =>
  getCollection(state, 'scenario', 'transittimes')
export const getRawWagons = state => getCollection(state, 'scenario', 'wagons')

const locationOption = location => ({
  key: location.id,
  value: location.id,
  text: `${location.name}, ${location.code}`,
})
const sortByName = (a, b) => a.name.localeCompare(b.name)
export const getOrderedLocationOptions = createSelector(
  getRawLocations,
  locations => [...locations].sort(sortByName).map(locationOption),
)

export const getLocationCodeOptions = createSelector(
  getRawLocations,
  locations =>
    [...locations]
      .sort(sortByName)
      .map(l => ({ key: l.id, value: l.code, text: l.code })),
)

export const getTrainTypes = createSelector(
  getRawTrainTypes,
  trainTypes => new Map(trainTypes.map(s => [s.id, s])),
)

export const getYardLocations = createSelector(getRawLocations, locations =>
  locations.filter(location => location.yard),
)

export const getBusinessGroups = createSelector(
  [getRawBusinessGroups],
  businessGroups => new Map(businessGroups.map(b => [b.id, b])),
)

export const getTimezones = createSelector(
  getRawTimezones,
  timezones => new Map(timezones.map(t => [t.id, t])),
)

export const getLocations = createSelector(
  Location.selector,
  repo => new Map(Object.entries(repo.idMap)),
)

export const getCorridors = createSelector(
  Corridor.selector,
  repo => new Map(Object.entries(repo.idMap)),
)

export const getDirectedCorridors = createSelector(Corridor.values, corridors =>
  corridors
    .reduce(
      (acc, c) => [
        ...acc,
        {
          name: `${c.loc1.code} - ${c.loc2.code}`,
          start: c.loc1,
          end: c.loc2,
          corridorId: c.id,
          forward: true,
        },
        {
          name: `${c.loc2.code} - ${c.loc1.code}`,
          start: c.loc2,
          end: c.loc1,
          corridorId: c.id,
          forward: false,
        },
      ],
      [],
    )
    .sort((a, b) => a.name.localeCompare(b.name)),
)

export const getFilteredDirectedCorridors = (
  state,
  startId = null,
  endId = null,
) =>
  getDirectedCorridors(state).filter(
    c => (!startId || c.start.id === startId) && (!endId || c.end.id === endId),
  )

export const getDirectedCorridorLookup = createSelector(
  getDirectedCorridors,
  corridors => {
    const corridorLookup = new Map()
    for (const corr of corridors) {
      corridorLookup.set(corr.start.id, [
        ...(corridorLookup.get(corr.start.id) || []),
        corr,
      ])
    }
    return corridorLookup
  },
)

export const getConnectedLocations = createSelector(getCorridors, corridors => {
  const uniqueLocations = [...corridors.values()].reduce(
    (coll, corridor) => coll.add(corridor.loc1).add(corridor.loc2),
    new Set(),
  )
  return Array.from(uniqueLocations)
})

export const getAdjacentLocations = (originId, visited) =>
  createSelector(
    getLocations,
    getDirectedCorridorLookup,
    (locations, corridorLookup) => {
      const validCorridors = (n, currentNodes) =>
        corridorLookup
          .get(n)
          .filter(
            corr =>
              !currentNodes.includes(corr.end.id) &&
              !visited.includes(corr.end.id),
          )

      const pathData = corridor => ({
        end: corridor.end.name,
        endId: corridor.end.id,
        outOfNetwork: corridor.end.outOfNetwork,
        forward: corridor.forward,
        corridorId: corridor.corridorId,
      })

      const paths = []

      const addNext = path => {
        const last = path.slice(-1)[0]
        if (!last.outOfNetwork) {
          const currentNodes = path.map(x => x.endId)
          const corridors = validCorridors(
            currentNodes.slice(-1)[0],
            currentNodes,
          )

          if (corridors.length === 1) {
            const corridor = corridors[0]
            const next = [...path, pathData(corridor)]
            paths.push(next)
            addNext(next)
          }
        }
      }

      const origin = locations.get(originId)
      const initial =
        visited.length === 1 || !origin.outOfNetwork
          ? validCorridors(originId, []).map(corridor => [pathData(corridor)])
          : []

      for (const path of initial) {
        paths.push(path)
        addNext(path)
      }

      return paths
    },
  )

export const getLVs = createSelector(getScenarioSingletons, singletons => [
  singletons.lv,
])
