import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { UnqualifiedDriverForAssignment } from 'src/service-design/shared/models/shift-assignment/validator'

export class UnqualifiedDriverForDriverAssignment extends UnqualifiedDriverForAssignment {
  static message =
    'service-design::Driver is not qualified to drive Train {{entity.train.name}} from {{ entity.startLocation.code }} to {{ entity.endLocation.code }}'
}

export class InvalidDriverAssignment extends LogMessage {
  static type = 'service-design::Invalid driver assignment'

  static message =
    'service-design::Could not find task associated with assignment {{entity.id}}.'

  static check(assignment) {
    return !assignment.task
  }
}

export default {
  warnings: flags.crewing ? [UnqualifiedDriverForDriverAssignment] : [],
  errors: flags.crewing ? [InvalidDriverAssignment] : [],
}
