import { DAYS } from 'src/service-design/shared/constants'
import { CompoundShift } from 'src/service-design/shared/models/compound-shift'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { Shift } from 'src/service-design/shared/models/shift'
import {
  intersectionExclusive as intersect,
  EpochTime,
  WEEK_DURATION,
  END_OF_WEEK_EPOCH_TIME,
} from 'src/service-design/shared/utils/dates'

import { RosterLine } from './model'

// PN - RFP
const MAX_JOBS_IN_PERIOD = 12
const MIN_RDOS_IN_PERIOD = 2

function sort<
  T extends { startTimeLocalVO: EpochTime; endTimeLocalVO: EpochTime }
>(tasks: T[]): T[] {
  return tasks.sort((a, b) => {
    if (a.startTimeLocalVO.equals(b.startTimeLocalVO)) {
      return a.endTimeLocalVO.toSeconds() - b.endTimeLocalVO.toSeconds()
    }
    return a.startTimeLocalVO.toSeconds() - b.startTimeLocalVO.toSeconds()
  })
}

export class TemporalWarning extends LogMessage {
  constructor(
    context,
    public startTimeLocalVO: EpochTime,
    public endTimeLocalVO: EpochTime,
    derived = {},
  ) {
    super(context, derived)
  }

  get startTimeLocal() {
    return this.startTimeLocalVO.toSeconds()
  }

  get endTimeLocal() {
    return this.endTimeLocalVO.toSeconds()
  }
}

export class ShiftConflict extends TemporalWarning {
  static type = 'service-design::Shift Conflict'

  static message =
    'service-design::Shift {{shift2.name}} is too soon after {{shift1.name}} on {{shift1.line.crewPool.name}} roster line {{shift1.line.num}}'

  static check(line: RosterLine, addShift: Shift = null) {
    const theseShifts: {
      shift: Shift
      startTimeLocalVO: EpochTime
      endTimeLocalVO: EpochTime
    }[] = sort(
      [...(addShift ? [addShift] : []), ...line.shifts].map(shift => ({
        shift,
        startTimeLocalVO: shift.startTimeLocalVO,
        endTimeLocalVO: shift.endTimeLocalVO.makeLater(line.minHomeRestSecsVO),
      })),
    )
    const nextShifts = sort(
      line.next.shifts.map(shift => ({
        shift,
        startTimeLocalVO: shift.startTimeLocalVO.makeLater(WEEK_DURATION),
        endTimeLocalVO: shift.endTimeLocalVO
          .makeLater(WEEK_DURATION)
          .makeLater(line.minHomeRestSecsVO),
      })),
    )

    const allShifts = [...theseShifts, ...nextShifts]
    const warnings = []
    theseShifts.forEach((shift1, index) => {
      const laterShifts = allShifts.slice(index + 1)
      for (const shift2 of laterShifts) {
        if (
          !shift1.shift.endAtRemoteRest ||
          shift1.shift.endAtRemoteRest !== shift2.shift.startAtRemoteRest
        ) {
          if (
            intersect(
              [
                shift1.startTimeLocalVO.toSeconds(),
                shift1.endTimeLocalVO.toSeconds(),
              ],
              [
                shift2.startTimeLocalVO.toSeconds(),
                shift2.endTimeLocalVO.toSeconds(),
              ],
            )
          ) {
            warnings.push(
              new this(
                { shift1: shift1.shift, shift2: shift2.shift },
                shift1.startTimeLocalVO,
                shift2.endTimeLocalVO.makeEarlier(line.minHomeRestSecsVO),
              ),
            )
          } else if (
            shift2.startTimeLocalVO.isStrictlyLater(shift1.endTimeLocalVO)
          ) {
            break
          }
        }
      }
    })
    return addShift
      ? warnings.filter(
          w => [w.context.shift1, w.context.shift2].indexOf(addShift) > -1,
        )
      : warnings
  }
}

export class RDOConflict extends TemporalWarning {
  static type = 'service-design::RDO Conflict'

  static message =
    'service-design::Shift {{shift.name}} overlaps with an RDO on {{shift.line.crewPool.name}} roster line {{shift.line.num}}'

  static check(line: RosterLine, addShift: Shift | CompoundShift = null) {
    const warnings: RDOConflict[] = []
    const rdos = [
      ...line.prev.RDOs.map(r => ({
        startTimeLocalVO: r.startTimeLocalVO.makeEarlier(WEEK_DURATION),
        endTimeLocalVO: r.endTimeLocalVO.makeEarlier(WEEK_DURATION),
      })),
      ...line.RDOs,
      ...line.next.RDOs.map(r => ({
        startTimeLocalVO: r.startTimeLocalVO.makeLater(WEEK_DURATION),
        endTimeLocalVO: r.endTimeLocalVO.makeLater(WEEK_DURATION),
      })),
    ]

    const shifts = sort(addShift ? [addShift] : line.shifts)

    for (const rdo of rdos) {
      for (const shift of shifts) {
        if (
          intersect(
            [
              shift.startTimeLocalVO.toSeconds(),
              shift.endTimeLocalVO.toSeconds(),
            ],
            [rdo.startTimeLocalVO.toSeconds(), rdo.endTimeLocalVO.toSeconds()],
          )
        ) {
          warnings.push(
            new this(
              { shift, rdo },
              shift.startTimeLocalVO,
              shift.endTimeLocalVO,
            ),
          )
        } else if (shift.startTimeLocalVO.isStrictlyLater(rdo.endTimeLocalVO)) {
          break
        }
      }
    }
    return warnings
  }
}

export class ReliefLineConflict extends TemporalWarning {
  static type = 'service-design::Relief Line Conflict'

  static message =
    'service-design::Sign-on is too soon after relief on {{line.crewPool.name}} roster line {{line.num}}'

  constructor(line: RosterLine, shift: Shift | CompoundShift) {
    super({ line, shift }, shift.startTimeLocalVO, shift.endTimeLocalVO)
  }

  static check(line: RosterLine, addShift: Shift | CompoundShift = null) {
    const warnings = []
    const shifts = addShift ? [addShift] : line.shifts

    if (line.prev.relief) {
      warnings.push(
        ...shifts
          .filter(
            x =>
              x.startTimeLocalVO.toSeconds() <
              line.reliefBufferDurationVO.toSeconds(),
          )
          .map(s => new this(line.prev, s)),
      )
    }

    if (line.relief) {
      warnings.push(...shifts.map(s => new this(line, s)))
    }

    if (line.next.relief) {
      warnings.push(
        ...shifts
          .filter(x => x.endTimeLocalVO.isStrictlyLater(END_OF_WEEK_EPOCH_TIME))
          .map(s => new this(line.next, s)),
      )
    }
    return warnings
  }
}

export class RDOsInReliefLine extends LogMessage {
  static type = 'service-design::RDOs in Relief Line'

  static message =
    'service-design::{{entity.crewPool.name}} roster has RDO(s) on relief line {{entity.num}}'

  static check(line: RosterLine) {
    return Boolean(line.RDOs.length && line.relief)
  }
}

export class MultiDayRDODoesntBeginOnMidnight extends LogMessage {
  static type = "service-design::Multi-day RDO doesn't begin at midnight"

  static message =
    "service-design::{{entity.crewPool.name}} roster has a multi-day RDO that doesn't begin at midnight on line {{entity.num}}, {{day}}"

  constructor(context) {
    super(context)
    const rdos = MultiDayRDODoesntBeginOnMidnight.getMultiRDOsOutsideMidnight(
      context.entity,
    )
    if (rdos.length) {
      ;(this.derived as any).day = DAYS[rdos[0].day]
    }
  }

  static getMultiRDOsOutsideMidnight(line) {
    return line.RDOs.filter(rdo => rdo.isStartOfMultiDay && rdo.delta !== 0)
  }

  static check(line: RosterLine) {
    return Boolean(
      MultiDayRDODoesntBeginOnMidnight.getMultiRDOsOutsideMidnight(line).length,
    )
  }
}

export class InsufficientWeekendRDOs extends LogMessage {
  static type = 'service-design::Insufficient Weekend RDOs'

  static message =
    'service-design::{{entity.crewPool.name}} roster has too much time between weekend RDOs at line {{entity.num}}'

  static check(line: RosterLine) {
    return !line.weekendRDOCycle.some(x => x.startsWithWeekendRDO)
  }
}

export class ExceededJobsInPeriod extends LogMessage {
  static type = 'service-design::[RFP - Coal] Exceeded Jobs in Period'

  static message =
    'service-design::{{entity.crewPool.name}} roster has too many jobs in rolling 14 day cycle starting on line {{entity.num}}'

  static check(line: RosterLine) {
    for (const sl of line.shiftLines) {

      const othersOnLine = line.shiftLines.filter(x => x.shift.startTimeLocal >= sl.shift.startTimeLocal)
      const othersOnNextLine = line.next.shiftLines
      const othersOn2ndNextLine = line.next.next.shiftLines.filter(x => x.shift.startTimeLocal <= sl.shift.startTimeLocal)

      const numJobs = [...othersOnLine, ...othersOnNextLine, ...othersOn2ndNextLine]
      if (numJobs.length > MAX_JOBS_IN_PERIOD) {
        return true
      }
    }
    return false
  }
}
export class InsufficientRDOsInPeriod extends LogMessage {
  static type = 'service-design::[RFP - Coal] Insufficient RDOs in Period'

  static message =
    'service-design::{{entity.crewPool.name}} roster has too few RDOs in rolling 14 day cycle starting on line {{entity.num}}'

  static check(line: RosterLine) {
    for (const d of DAYS) {
      const othersOnLine = line.RDOs.filter(x => x.day >= DAYS.indexOf(d))
      const othersOnNextLine = line.next.RDOs 
      const othersOn2ndNextLine = line.next.next.RDOs.filter(x => x.day < DAYS.indexOf(d))
      const numRDOs = [...othersOnLine, ...othersOnNextLine, ...othersOn2ndNextLine]
      if (numRDOs.length < MIN_RDOS_IN_PERIOD) {
        return true
      }
    }
  return false
  }
}

export class ShiftExceedsFatigueLimit extends LogMessage {
  static type = 'service-design::Shifts on line exceed fatigue limit'

  static message =
    'service-design::Shift {{shift.name}} on line {{shift.line.num}} in roster for {{shift.line.crewPool.name}} exceeds the maximum fatigue score'

  static check() {
    return false
  }

  static build(shift) {
    if (shift.fatigue && shift.fatigue >= shift.maxFatigue) {
      return new this({ shift })
    }

    return null
  }
}

export default {
  warnings: [
    InsufficientWeekendRDOs,
    RDOsInReliefLine,
    ShiftExceedsFatigueLimit,
    ExceededJobsInPeriod,
    InsufficientRDOsInPeriod,
    MultiDayRDODoesntBeginOnMidnight,
  ],
  temporal: [ShiftConflict, RDOConflict, ReliefLineConflict],
}
