import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { ResourceAvailability } from './model'

export const resourceAvailabilityFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    // HACK: resource availabilities are currently being referred to by
    // locos and wagon which are a scenario models.
    //
    // This the only example within the application
    // where a scenario model referencing a service-design model (which is
    // pretty) backwards. Until that addressed we need to fake this data out
    // as an empty list.
    (collectionData.resourceavailabilities || []).map(data => {
      const item = new ResourceAvailability(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((resourceAvailability: ResourceAvailability, state: any) => ({
    lococlass: registry
      .provide('lococlassRepo')(state)
      .byId(resourceAvailability.lococlassId),
    wagon: registry
      .provide('wagonRepo')(state)
      .byId(resourceAvailability.wagonId),
    unavailabilities: registry
      .provide('resourceUnavailabilityRepo')(state)
      .byAvailabilityId(resourceAvailability.id),
  })),
)
