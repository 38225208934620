import { createSelector } from 'reselect'

import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import { generateStartLegs } from 'src/service-design/shared/models/start-leg/factory'

export const getRawTemplateLegs = state =>
  getCollection(state, 'service-design', 'templatelegs')

export const getRawTrainStarts = state =>
  getCollection(state, 'service-design', 'trainstarts')
export const getRawTrainTemplates = state =>
  getCollection(state, 'service-design', 'traintemplates')

export const getRawStartLegs = createSelector(
  getRawTrainStarts,
  getRawTemplateLegs,
  generateStartLegs,
)
