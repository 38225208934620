import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { CrewPool } from './model'

export const crewPoolFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.crewpools.map(data => {
      const item = new CrewPool(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((crewPool: CrewPool, state: any) => ({
    type: registry
      .provide('crewTypeRepo')(state)
      .byId(crewPool.typeId),
    shifts: registry
      .provide('shiftRepo')(state)
      .byPoolId(crewPool.id),
    remoteRests: registry
      .provide('remoteRestRepo')(state)
      .byPoolId(crewPool.id),
    lines: registry
      .provide('rosterLineRepo')(state)
      .byParentId(crewPool.id),
    rosterHead: registry
      .provide('rosterHeadRepo')(state)
      .byParentId(crewPool.id),
    compoundShifts: registry
      .provide('compoundShiftRepo')(state)
      .byPoolId(crewPool.id),
  })),
)
