import { createSelector } from 'reselect'

import * as constants from 'src/service-design/sd-plan/constants'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getTrainFilter,
  filterItems,
  oneOf,
} from 'src/service-design/sd-plan/selectors/filters'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import * as TrainTemplate from 'src/service-design/shared/models/train-template'

import {
  getRawTrainStarts,
  getRawTrainTemplates,
  getRawTemplateLegs,
  getRawStartLegs,
} from './raw'

export {
  getRawTemplateLegs,
  getRawTrainStarts,
  getRawTrainTemplates,
  getRawStartLegs,
}

export const getRawResourceAvailabilities = state =>
  getCollection(state, 'service-design', 'resourceavailabilities')
export const getRawResourceUnavailabilities = state =>
  getCollection(state, 'service-design', 'resourceunavailabilities')

export const getRawTrainBlocks = state =>
  getCollection(state, 'service-design', 'trainblocks')

export const getBatchIds = state => state.batch

export const TONNAGE_EQUALITY_BEHAVIOUR = {
  [constants.LIGHTEST_LEG_GREATER_THAN]: (train, tonnage) =>
    train.lightestLeg && train.lightestLeg.tonnage > tonnage,
  [constants.LIGHTEST_LEG_LESS_THAN]: (train, tonnage) =>
    !train.lightestLeg || train.lightestLeg.tonnage <= tonnage,
  [constants.HEAVIEST_LEG_GREATER_THAN]: (train, tonnage) =>
    train.heaviestLeg && train.heaviestLeg.tonnage > tonnage,
  [constants.HEAVIEST_LEG_LESS_THAN]: (train, tonnage) =>
    !train.heaviestLeg || train.heaviestLeg.tonnage <= tonnage,
}

export const matchesTrainTypeId = (train, { typeId }) =>
  !typeId || train.typeId === typeId
export const matchesBusinessGroupId = (train, { businessGroupId }) =>
  !businessGroupId || train.businessGroupId === businessGroupId
export const matchesOneOfWarningTypes = (train, { warningTypes }) =>
  oneOf(train.warningTypes, warningTypes)
export const matchesOneOfLockTypes = (train, { lockTypes }) =>
  oneOf(train.lockTypes, lockTypes)
export const checksTonnageEquality = (train, { tonnageEquality, tonnage }) =>
  !tonnageEquality ||
  TONNAGE_EQUALITY_BEHAVIOUR[tonnageEquality](train, tonnage)

const TRAIN_FILTERS = [
  matchesTrainTypeId,
  matchesBusinessGroupId,
  matchesOneOfWarningTypes,
  matchesOneOfLockTypes,
  checksTonnageEquality,
]

export const matchIfAnyStartMatches = matchFn => (template, filterOptions) =>
  template.starts.some(start => matchFn(start, filterOptions))

const TRAIN_TEMPLATE_FILTERS = [
  matchesTrainTypeId,
  matchesBusinessGroupId,
  matchesOneOfWarningTypes,
  matchesOneOfLockTypes,
  matchIfAnyStartMatches(checksTonnageEquality),
]

export const getTrainTotal = createSelector(
  TrainStart.values,
  scheduled => scheduled.length,
)

export const getTrainTemplateTotal = createSelector(
  TrainTemplate.values,
  templates => templates.length,
)

export const getTrainLegs = createSelector(StartLeg.values, allLegs => {
  const trainLegs = allLegs.reduce((acc, leg) => {
    if (!acc.has(leg.startId)) {
      acc.set(leg.startId, [])
    }
    acc.get(leg.startId).push(leg)
    return acc
  }, new Map<string, StartLeg.StartLeg[]>([]))
  trainLegs.forEach(legs =>
    legs.sort((leg1, leg2) => leg1.legNum - leg2.legNum),
  )
  return trainLegs
})

export const getScheduledTrainOptions = createSelector(
  TrainStart.values,
  trains =>
    [
      {
        text: constants.ALL_TRAINS,
        value: constants.ALL_TRAINS,
      },
    ].concat(
      trains.map(t => ({
        text: t.name,
        value: t.id,
      })),
    ),
)

export const getTrainStartsByID = state =>
  new Map(Object.entries(TrainStart.selector(state).idMap))

export const getFilteredTrainStarts = createSelector(
  getTrainFilter,
  TrainStart.values,
  (filterOptions, starts) => filterItems(filterOptions, starts, TRAIN_FILTERS),
)

export const getFilteredTrainTemplates = createSelector(
  getTrainFilter,
  TrainTemplate.values,
  (filterOptions, templates) =>
    filterItems(filterOptions, templates, TRAIN_TEMPLATE_FILTERS),
)

export const getAllFilteredTrains = getFilteredTrainStarts

export const getTrainTemplatesBatch = createSelector(
  TrainTemplate.values,
  getBatchIds,
  (templates, batchIds) =>
    templates.filter(template => batchIds.includes(template.id)),
)

export const getStartLegsByBatch = createSelector(
  getBatchIds,
  StartLeg.values,
  (batchIds, legs) => legs.filter(leg => batchIds.includes(leg.templateId)),
)

export const getStartLegsMap = createSelector(
  getRawStartLegs,
  startlegs =>
    new Map(
      startlegs.map(({ id, startId, templateLegId }) => [
        id,
        { startId, templateLegId },
      ]),
    ),
)
