import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { inBounds } from 'src/service-design/shared/utils/dates'

import { Changeover } from '.'

export class InsufficientTimeForChangeover extends LogMessage {
  static dedupe = true

  static type = 'service-design::Insufficient time for changeover'

  static messageShifts =
    'service-design::Train {{entity.startLeg.start.name}}, has insufficient time to perform changeover {{entity.desc}}, in shifts {{ shiftText }}'

  static messageNoShifts =
    'service-design::Train {{entity.startLeg.start.name}}, has insufficient time to perform changeover {{entity.desc}}'

  public context: { entity: Changeover }
  public derived: { shiftText: string | null }

  constructor(context: { entity: Changeover }) {
    const derived = { shiftText: null }
    const changeover = context.entity
    const changeoverId = changeover.id
    const driverAssignments = changeover.legs
      .flatMap(leg => leg.start.driverAssignments)
      .filter(
        x =>
          x.startChangeoverId === changeoverId ||
          x.endChangeoverId === changeoverId,
      )
    const shifts = [...new Set(driverAssignments.map(x => x.shift))]
    if (shifts.length > 0) {
      derived.shiftText = shifts.map(shift => shift.name).join(', ')
    }

    super(context, derived)
    // What a fun time
    this.context = context
    this.derived = derived
  }

  get id() {
    return `insufficient-time-for-changeover-${this.context.entity.id}-${this.derived.shiftText}`
  }

  get message() {
    if (this.derived.shiftText !== null) {
      return InsufficientTimeForChangeover.messageShifts
    }
    return InsufficientTimeForChangeover.messageNoShifts
  }

  static check(changeover) {
    return changeover.duration < changeover.singletons.crew.changeoverSecs
  }
}

export class ChangeoverExceedsDwell extends LogMessage {
  static dedupe = true
  static type = 'service-design::Changeover not within dwell'
  static message =
    'service-design::Changeover "{{entity.name}}" occurs outside of the available dwell'

  static check(changeover: Changeover) {
    const { startTimeLocal, endTimeLocal, dwellWindow } = changeover
    return !inBounds(
      [dwellWindow.start, dwellWindow.end],
      [startTimeLocal, endTimeLocal],
    )
  }
}

export default {
  warnings: flags.crewing
    ? [InsufficientTimeForChangeover, ChangeoverExceedsDwell]
    : [],
}
