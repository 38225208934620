import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { wagonCargoCompatibilityFactory } from './factory'
import {
  IWagonCargoCompatibilityRepo,
  WagonCargoCompatibilityRepo,
} from './repo'

const wagonCargoCompatibilityRepo = defaultMemoize(
  (input: FactoryInput) =>
    new WagonCargoCompatibilityRepo(wagonCargoCompatibilityFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  wagonCargoCompatibilityRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type WagonCargoCompatibilityRepoReg = {
  wagonCargoCompatibilityRepo: FromDocumentFactory<
    FactoryInput,
    IWagonCargoCompatibilityRepo
  >
}

export function setup<R extends WagonCargoCompatibilityRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('wagonCargoCompatibilityRepo', wagonCargoCompatibilityRepo)

  relationships.addConstraints('wagoncargocompatibilities', {
    unique: [['cargoTypeId', 'wagonId']],
    rels: [
      {
        collection: 'cargotypes',
        foreign: 'cargoTypeId',
        name: 'cargoType',
      },
      {
        collection: 'wagons',
        foreign: 'wagonId',
        name: 'wagon',
        allowCascadeDelete: true,
      },
    ],
  })
}

export { WagonCargoCompatibility } from './model'
