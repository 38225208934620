import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Delta } from 'src/service-design/shared/utils/dates'

import { TemplateLeg } from './model'

export const templateLegFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.templatelegs.map(
      ({ arrivesDelta, departsDelta, ...data }) => {
        const item = new TemplateLeg({
          arrivesDelta: Delta.fromSeconds(arrivesDelta),
          departsDelta: Delta.fromSeconds(departsDelta),
          ...data,
        })
        item.setExternals(externals)
        return item
      },
    ),
  setRelsFromSpec((l: TemplateLeg, state: any) => ({
    template: registry
      .provide('trainTemplateRepo')(state)
      .byId(l.templateId),
    corridor: registry
      .provide('corridorRepo')(state)
      .byId(l.corridorId),
    singletons: state.collectionData.singletons,
    arrivingTrainBlocks: registry
      .provide('trainBlockRepo')(state)
      .byTemplateLegId(l.id),
    departingYardBlockAssignments: registry
      .provide('yardBlockAssignmentRepo')(state)
      .byDepartingLegId(l.id),
    emptywagonevents: registry
      .provide('emptyWagonEventRepo')(state)
      .byTemplateLegId(l.id),
    locoevents: registry
      .provide('locoEventRepo')(state)
      .byTemplateLegId(l.id),
    localAssignments: registry
      .provide('localAssignmentRepo')(state)
      .byTemplateLegId(l.id),
    serviceevents: registry
      .provide('serviceEventRepo')(state)
      .byTemplateLegId(l.id),
    locationchangeovers: registry
      .provide('locationChangeoverRepo')(state)
      .byTemplateLegId(l.id),
    trainChangeoverA: registry
      .provide('trainChangeoverRepo')(state)
      .byTemplateLegAId(l.id),
    trainChangeoverB: registry
      .provide('trainChangeoverRepo')(state)
      .byTemplateLegBId(l.id),
    workinglocolocks: registry
      .provide('workingLocoLockRepo')(state)
      .byTemplateLegId(l.id),
    loadingAssignments: registry
      .provide('loadingAssignmentRepo')(state)
      .byTemplateLegId(l.id),
    loadingWorkSplits: registry
      .provide('loadingWorkSplitRepo')(state)
      .byTemplateLegId(l.id),
  })),
)
