import { Validator } from 'src/service-design/shared/models/validator'

export class Mapper extends Validator {
  /**
   * A base class for our core models. Ripe for culling as it adds no real
   * value.
   */
  static construct(obj: object) {
    const mapper = new (this as any)(obj)
    mapper.setRels(obj)
    return mapper
  }

  setRels(obj: object) {
    Object.assign(this, obj)
  }

  applyDefaults() {
    for (const key of Object.keys(this)) {
      if (this[key] === undefined) {
        this[key] = (this.constructor as any).defaults[key]
      }
    }
  }
}

export class BasicMapper extends Mapper {
  constructor(attrs: object) {
    super()
    Object.assign(this, attrs)
  }
}
