import { createSelector } from 'reselect'

import * as constants from 'src/service-design/sd-plan/constants'

export const getQueryString = state => state.router.location.search

export const getFilters = state => state.ui.filters

export const getTrainFilter = createSelector(
  getFilters,
  filters => filters[constants.TRAINS_FILTER] || constants.EMPTY_FILTER,
)

export const getServiceFilter = createSelector(
  getFilters,
  filters => filters[constants.SERVICES_FILTER] || constants.EMPTY_FILTER,
)

export const getCrewFilter = createSelector(
  getFilters,
  filters => filters[constants.CREWS_FILTER] || constants.EMPTY_FILTER,
)

export const getCustomTasksFilter = createSelector(
  getFilters,
  filters => filters[constants.CUSTOM_TASKS_FILTER] || constants.EMPTY_FILTER,
)

export const getYardBlocksFilter = createSelector(
  getFilters,
  filters => filters[constants.YARD_BLOCKS_FILTER] || constants.EMPTY_FILTER,
)

export const isAdvancedFilter = filter => {
  for (const [key, value] of Object.entries(filter)) {
    if (value && key !== 'name') {
      return true
    }
  }
  return false
}

export const filterItems = (
  { simple, advanced, advancedActivated },
  items,
  behaviours,
) => {
  if (
    !simple &&
    (!Object.keys(advanced).length || !advancedActivated || !behaviours)
  ) {
    return items
  }

  return items.filter(
    item =>
      (!simple || item.name.toLowerCase().includes(simple.toLowerCase())) &&
      (!advancedActivated ||
        !behaviours ||
        behaviours.every(behaviour => behaviour(item, advanced))),
  )
}

export const oneOf = (valueSet, value) => {
  if (!value) {
    return true
  }
  if (value === '*') {
    if (!valueSet.size) {
      return false
    }
  } else if (!valueSet.has(value)) {
    return false
  }
  return true
}
