import { ServiceDesignDocument } from './types'

const blank: ServiceDesignDocument = {
  crewpools: [],
  customtaskassignments: [],
  customtasks: [],
  driverassignments: [],
  emptywagonevents: [],
  remoterests: [],
  loadingassignments: [],
  loadingworksplits: [],
  localassignments: [],
  uncrewedlegtasks: [],
  startlegtaskoffsets: [],
  locationchangeovers: [],
  locationchangeoverlocks: [],
  locoevents: [],
  rdos: [],
  resourceavailabilities: [],
  resourceunavailabilities: [],
  rosterheads: [],
  rosterlines: [],
  serviceevents: [],
  servicelocks: [],
  services: [],
  shiftlines: [],
  shifts: [],
  singletons: {
    ceto: {
      timeLimitSecs: 300,
      mipgapPercent: 5,
      shiftPenalty: 1000,
      lvPenalty: 1,
      shortChangePenalty: 1,
      changePenalty: 1,
      mealBreakPenalty: 100,
      uncoveredTaskCost: 10000,
      lvImbalanceCost: 5000,
      minChangeoverSecs: 0,
      unitDefinitions: [],
    },
    pallas: {
      timeLimitSecs: 300,
      mipgapPercent: 0.05,
      constrainResourceFlow: true,
      preserveShifts: true,
      adjustmentPenaltyPerMin: 60,
      shiftValidityPenaltyPerMin: 1000,
      trainConflictAvoidancePenalty: 100000,
      trainValidityPenaltyPerMin: 1000,
      customerPenaltyPerMin: 1000,
    },
    swiss: {
      underPowerPenalty: 10000000.0,
      timeLimitSecs: 300,
      mipgapPercent: 0.05,
      maxConsistTypes: 30,
      lockBreakPenalty: 9000000.0,
      locoDiffCost: 0,
    },
    pluto: {
      costPerTrainHour: 720,
      fixedCostPerTrain: 5000,
      maxEngineRuntime: 3600,
      maxServicesPerTrain: 8,
      maxWaitTime: 43200,
      mipgapPercent: 2,
      penaltyPerExtraWagon: 10000000,
      uncoveredServicePenalty: 1000000,
      wagonRepositioningCost: 1000,
      defaultTrainTypeId: null,
    },
    sedna: {
      poolId: null,
      mipgapPercent: 10,
      timeLimitSecs: 3600,
      singleRDOPenalty: 100,
      penaltyPerUncoveredShift: 100000,
      dutyVariationPenalty: 36,
      minRDOsOnLine: 0,
    },
  },
  templatelegs: [],
  trainblocks: [],
  trainchangeovers: [],
  trainchangeoverlocks: [],
  trainstarts: [],
  traintemplates: [],
  wagonallocations: [],
  wagonsets: [],
  workinglocolocks: [],
  yardblocks: [],
  yardblockassignments: [],
}
export default blank
