import PropTypes from 'prop-types'
import React from 'react'
import { Label } from 'semantic-ui-react'
import './index.css'

const defaultLabelColor = 'rgba(0,0,0,.6)'
export const ColoredLabel = ({ entity, ...props }) => (
  <Label
    className="label-entity"
    style={{
      background: entity ? entity.bgColor : '',
      color: entity ? defaultLabelColor : 'red',
    }}
    {...(!entity ? { icon: 'broken chain' } : { content: entity.name })}
    {...props}
  />
)

ColoredLabel.defaultProps = {
  entity: null,
}
ColoredLabel.propTypes = {
  entity: PropTypes.shape({
    bgColor: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
}
