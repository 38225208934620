import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { trainStartFactory } from './factory'
import { ITrainStartRepo, TrainStartRepo } from './repo'

export const trainStartRepo = defaultMemoize(
  (input: FactoryInput) => new TrainStartRepo(trainStartFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  trainStartRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type TrainStartRepoReg = {
  trainStartRepo: FromDocumentFactory<FactoryInput, ITrainStartRepo>
}

export function setup<R extends TrainStartRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('trainStartRepo', trainStartRepo)

  relationships.addConstraints('trainstarts', {
    rels: [
      {
        collection: 'traintemplates',
        foreign: 'templateId',
        name: 'template',
      },
    ],
  })
}

export { TrainStart } from './model'
