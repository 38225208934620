import { createSelector } from 'reselect'

import { DEFAULT_CATEGORY } from 'src/service-design/sd-plan/constants'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getCustomTasksFilter,
  filterItems,
} from 'src/service-design/sd-plan/selectors/filters'
import * as CustomTask from 'src/service-design/shared/models/custom-task'

export const matchesCategory = (task, { category }) =>
  !category || task.category === category
export const matchesStartLocationId = (task, { startLocationId }) =>
  !startLocationId || task.startLocation.id === startLocationId
export const matchesEndLocationId = (task, { endLocationId }) =>
  !endLocationId || task.endLocation.id === endLocationId
export const matchesRequiresDriver = (task, { requiresDriver }) =>
  !requiresDriver || task.requiresDriver

const CUSTOM_TASK_FILTERS = [
  matchesCategory,
  matchesStartLocationId,
  matchesEndLocationId,
  matchesRequiresDriver,
]

export const getRawCustomTasks = state =>
  getCollection(state, 'service-design', 'customtasks')

export const getRawCustomTasksMap = createSelector(
  getRawCustomTasks,
  tasks => new Map(tasks.map(t => [t.id, t])),
)

export const getFilteredCustomTasks = createSelector(
  getCustomTasksFilter,
  CustomTask.values,
  (filter, tasks) => filterItems(filter, tasks, CUSTOM_TASK_FILTERS),
)

export const getOrderedCategoryOptions = createSelector(
  CustomTask.values,
  tasks =>
    [...new Set([DEFAULT_CATEGORY, ...tasks.map(t => t.category)])].map(c => ({
      key: c,
      value: c,
      text: c,
    })),
)
