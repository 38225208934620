import { createSelector } from 'reselect'

import { i18n } from 'src/i18n'
import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getLegConflictWarnings,
  getLocationConflictWarnings,
} from 'src/service-design/sd-plan/selectors/conflicts'
import { getCongestionWarnings } from 'src/service-design/sd-plan/selectors/congestion'
import { getLocations } from 'src/service-design/sd-plan/selectors/scenario'
import { getIsSelected } from 'src/service-design/sd-plan/selectors/selection'
import { getFilteredServices } from 'src/service-design/sd-plan/selectors/services'
import { getFilteredTrainStarts } from 'src/service-design/sd-plan/selectors/trains'
import * as LocationChangeover from 'src/service-design/shared/models/changeover/location'
import * as CrewPool from 'src/service-design/shared/models/crew-pool'
import { DriverTask } from 'src/service-design/shared/models/driver-task'
import * as RemoteRest from 'src/service-design/shared/models/remote-rest'
import * as Service from 'src/service-design/shared/models/service'
import * as Shift from 'src/service-design/shared/models/shift'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import { EpochTime } from 'src/service-design/shared/utils/dates'

export const getRawTrainGraphDefinitions = state =>
  getCollection(state, 'scenario', 'traingraphs')
export const getRawTrainGraphLocations = state =>
  getCollection(state, 'scenario', 'traingraphlocations')

const HEADER_OFFSET = 10
const PADDING = 90

export class TrainGraph {
  constructor(name, offset, yLocations) {
    this.name = name
    this.offset = offset
    this.HEADER_OFFSET = HEADER_OFFSET
    this.PADDING = PADDING

    this.yLocations = new Map(yLocations.map(l => [l.id, l]))

    this.sortedYLocations = [...yLocations].sort(
      (l1, l2) => l1.yPosition - l2.yPosition,
    )
  }

  static buildCollection(definitions, trainGraphLocations, locations) {
    let offset = 0
    return definitions.map(definition => {
      const yLocations = trainGraphLocations
        .filter(l => l.trainGraphId === definition.id)
        .map(l => ({ yPosition: l.yPosition, ...locations.get(l.locationId) }))

      const trainGraph = new TrainGraph(definition.name, offset, yLocations)
      offset += trainGraph.height
      return trainGraph
    })
  }

  get yTicks() {
    const initial = this.sortedYLocations.length
      ? this.sortedYLocations[0].yPosition
      : null
    return [
      {
        name: this.name,
        value: this.offset - this.HEADER_OFFSET,
        header: true,
      },
      ...this.sortedYLocations.map(l => ({
        name: l.code,
        id: l.id,
        value: l.yPosition - initial + this.offset,
        header: false,
      })),
    ]
  }

  get height() {
    if (this.sortedYLocations.length < 1) {
      return 0
    }

    const first = this.sortedYLocations[0]
    const [last] = this.sortedYLocations.slice(-1)
    return last.yPosition - first.yPosition + this.HEADER_OFFSET + this.PADDING
  }

  containsLocation(location) {
    return this.yLocations.has(location.id)
  }

  coversLeg(leg) {
    return this.containsLocation(leg.origin) && this.containsLocation(leg.dest)
  }

  coversLegPartially(leg) {
    return this.containsLocation(leg.origin) || this.containsLocation(leg.dest)
  }

  yPosition(locationId) {
    return (
      this.yLocations.get(locationId).yPosition -
      this.sortedYLocations[0].yPosition +
      this.offset
    )
  }
}

const pointPosition = (trainGraphs, location) => {
  const trainGraph = trainGraphs.find(g => g.containsLocation(location))
  // TODO: handle missing train graphs
  return trainGraph ? trainGraph.yPosition(location.id) : 0
}

export const pathFromLocation = (trainGraphs, conflict, leg) => {
  const candidates = trainGraphs.filter(g => g.coversLegPartially(leg))
  const trainGraph = candidates.find(g => g.coversLeg(leg)) || candidates[0]
  return {
    interconnector: false,
    points: [
      {
        yPosition: trainGraph.yPosition(conflict.location.id),
        time: conflict.startTime,
      },
      {
        yPosition: trainGraph.yPosition(conflict.location.id),
        time: conflict.endTime,
      },
    ],
  }
}

const colourFromCrewPool = (crewPoolId, crewPools) => {
  const crewPoolIds = crewPools.map(crewpool => crewpool.id).sort()
  return { [`crewpool${crewPoolIds.indexOf(crewPoolId) % 6}`]: true }
}

export const getChangePoints = (trainGraphs, changeover) => [
  {
    yPosition: pointPosition(trainGraphs, changeover.location),
    time: changeover.atDeparture
      ? changeover.endTimeLocal
      : changeover.startTimeLocal,
  },
]

export const getTrainEndPoint = (trainGraphs, train) => [
  {
    yPosition: pointPosition(trainGraphs, train.destination),
    time: train.terminationTime,
  },
]

export const getTrainStartPoint = (trainGraphs, train) => [
  {
    yPosition: pointPosition(trainGraphs, train.origin),
    time: train.originTime,
  },
]

export const pathFromRemoteRest = (trainGraphs, remoteRest) => [
  {
    interconnector: false,
    points: [
      {
        yPosition: pointPosition(trainGraphs, remoteRest.location),
        time: remoteRest.startTimeLocal,
      },
      {
        yPosition: pointPosition(trainGraphs, remoteRest.location),
        time: remoteRest.endTimeLocal,
      },
    ],
  },
]

const pathFromTask = (trainGraphs, task) => [
  {
    interconnector: false,
    points: [
      {
        yPosition: pointPosition(trainGraphs, task.startLocation),
        time: task.startTimeLocal,
      },
      {
        yPosition: pointPosition(trainGraphs, task.endLocation),
        time: task.endTimeLocal,
      },
    ],
  },
]

export const pathFromShift = (trainGraphs, shift) => {
  const assignments = shift.assignments.filter(assignment => !assignment.task)
  const tasks = shift.assignments.filter(
    assignment =>
      assignment.task &&
      (!(assignment.task instanceof DriverTask) ||
        !assignment.task.legs.length) &&
      assignment.task.duration,
  )
  const legs = shift.assignments.filter(assignment => assignment.task?.legs)

  return [
    ...assignments.flatMap(assignment => pathFromTask(trainGraphs, assignment)),
    ...tasks.flatMap(assignment => pathFromTask(trainGraphs, assignment.task)),
    ...legs.flatMap(assignment =>
      pathFromLegs(trainGraphs, assignment.task.legs),
    ),
  ]
}

export const pathFromCompoundShift = (trainGraphs, shift) => {
  const { shifts, remoteRests } = shift.compoundShift
  return [
    ...shifts.flatMap(childShift => pathFromShift(trainGraphs, childShift)),
    ...remoteRests.flatMap(remoteRest =>
      pathFromRemoteRest(trainGraphs, remoteRest),
    ),
  ]
}

export const pathFromLegs = (trainGraphs, legsAndTasks) =>
  legsAndTasks.reduce(
    (acc, legOrTask) => {
      const candidates = trainGraphs.filter(g =>
        g.coversLegPartially(legOrTask),
      )
      const trainGraph =
        candidates.find(g => g.coversLeg(legOrTask)) || candidates[0]

      if (!trainGraph) {
        return acc
      }

      const legPoints = [
        {
          yPosition: trainGraph.yPosition(legOrTask.origin.id),
          time:
            legOrTask.departsLocal instanceof EpochTime
              ? legOrTask.departsLocal.toSeconds()
              : legOrTask.departsLocal,
        },
        {
          yPosition: trainGraph.yPosition(legOrTask.dest.id),
          time:
            legOrTask.arrivesLocal instanceof EpochTime
              ? legOrTask.arrivesLocal.toSeconds()
              : legOrTask.arrivesLocal,
        },
      ]

      const [lastPath] = acc.slice(-1)
      const [lastPoint] = lastPath.points.length
        ? lastPath.points.slice(-1)
        : [legPoints[0]]

      const needsInterconnector = lastPoint.yPosition !== legPoints[0].yPosition
      if (needsInterconnector) {
        // ensure dwell is rendered to location closest to top of graph
        const needsInterconnectorBeforeDwell =
          lastPoint.yPosition > legPoints[0].yPosition
        if (needsInterconnectorBeforeDwell) {
          const dwellPoint = {
            yPosition: legPoints[0].yPosition,
            time: lastPoint.time,
          }
          legPoints.unshift(dwellPoint)
          acc.push({
            interconnector: true,
            points: [lastPoint, dwellPoint],
          })
        } else {
          const dwellPoint = {
            yPosition: lastPoint.yPosition,
            time: legPoints[0].time,
          }
          lastPath.points.push(dwellPoint)
          acc.push({
            interconnector: true,
            points: [dwellPoint, legPoints[0]],
          })
        }
        acc.push({
          interconnector: false,
          points: legPoints,
        })
      } else {
        lastPath.points.push(...legPoints)
      }

      return acc
    },
    [
      {
        interconnector: false,
        points: [],
      },
    ],
  )

export const renderService = (trainGraphs, service, selected) => {
  const origin = pointPosition(trainGraphs, service.origin)
  const dest = pointPosition(trainGraphs, service.destination)
  const cutOff = {
    yPosition: origin,
    time: service.deliveryCutOffLocal,
  }
  const due = {
    yPosition: dest,
    time: service.dueLocal,
  }
  const loaded = service.activities.length
    ? {
        time: service.activities[0].startTimeLocal,
        yPosition: pointPosition(trainGraphs, service.activities[0].origin),
      }
    : {
        time: service.dueLocal,
        yPosition: pointPosition(trainGraphs, service.destination),
      }
  const unloaded = {
    time: service.terminatingLocal,
    yPosition: pointPosition(trainGraphs, service.terminatingLocation),
  }

  return {
    points: [cutOff, ...(selected ? [due] : [])],
    paths: !selected
      ? []
      : [
          {
            interconnector: true,
            points: [cutOff, loaded],
          },
          ...pathFromLegs(
            trainGraphs,
            service.activities.reduce((acc, activity) => {
              if (activity.offsetLegs) {
                acc.push(...activity.offsetLegs)
              } else {
                acc.push(activity)
              }
              return acc
            }, []),
          ),
          {
            interconnector: true,
            points: [unloaded, due],
          },
        ],
  }
}

export const driverTaskPaths = (trainStarts, trainGraphs) => {
  const tasks = trainStarts.flatMap(train => train.driverTasks)
  return tasks.map(task => ({
    classes: {
      shifts: true,
      drivertask: true,
    },
    id: task.id,
    object: task,
    name: `Driver Task ${
      task.startChangeover
        ? task.startChangeover.location.code
        : task.start.origin.code
    } - ${
      task.endChangeover
        ? task.endChangeover.location.code
        : task.start.destination.code
    }`,
    points: [],
    paths: pathFromLegs(trainGraphs, task.legs),
  }))
}

export const changesEndPoints = (changeovers, trainStarts, trainGraphs) => {
  const changePoints = changeovers.map(changeover => ({
    classes: {
      changeover: true,
      warnings: false,
      shifts: true,
    },
    id: changeover.id,
    object: changeover,
    name: `Changeover ${changeover.location.code}`,
    points: getChangePoints(trainGraphs, changeover),
    paths: [],
  }))

  const startPoints = trainStarts.map(train => ({
    classes: {
      changeover: true,
      warnings: false,
      shifts: true,
    },
    id: `StartTrain-${train.name}`,
    name: `StartTrain ${train.name}`,
    object: train,
    points: getTrainStartPoint(trainGraphs, train),
    paths: [],
  }))

  const endPoints = trainStarts.map(train => ({
    classes: {
      changeover: true,
      warnings: false,
      shifts: true,
    },
    id: `EndTrain-${train.name}`,
    name: `EndTrain ${train.name}`,
    object: train,
    points: getTrainEndPoint(trainGraphs, train),
    paths: [],
  }))
  return [...startPoints, ...endPoints, ...changePoints]
}

export const renderTrainPaths = (trainGraphs, train) =>
  pathFromLegs(trainGraphs, train.legs)

export const renderLegWarning = (trainGraphs, warning) =>
  pathFromLegs(trainGraphs, [warning.leg])

export const getTrainGraphs = createSelector(
  getRawTrainGraphDefinitions,
  getRawTrainGraphLocations,
  getLocations,
  TrainGraph.buildCollection,
)

export const getTrainGraphData = createSelector(
  getTrainGraphs,
  TrainStart.values,
  getFilteredTrainStarts,
  Service.values,
  getFilteredServices,
  getIsSelected, // note: consumes the "selected" selector param
  getCongestionWarnings,
  getLegConflictWarnings,
  getLocationConflictWarnings,
  LocationChangeover.values,
  Shift.values,
  RemoteRest.values,
  CrewPool.values,
  (
    trainGraphs,
    trainStarts,
    filteredTrainStarts,
    services,
    filteredServices,
    isSelected,
    congestionWarnings,
    legConflictWarnings,
    locationConflictWarnings,
    changeovers,
    shifts,
    remoteRests,
    crewPools,
  ) => ({
    yTicks: trainGraphs.reduce(
      (acc, trainGraph) => [...acc, ...trainGraph.yTicks],
      [],
    ),
    entities: [
      ...locationConflictWarnings.map(conflict => ({
        id: conflict.location.id,
        name: i18n.t(...conflict.i18nMessage),
        classes: {
          conflict: true,
          warnings: true,
        },
        points: [],
        paths: [pathFromLocation(trainGraphs, conflict, conflict.leg)],
      })),
      ...legConflictWarnings.map(conflict => ({
        id: conflict.leg.start.id,
        name: i18n.t(...conflict.i18nMessage),
        classes: {
          conflict: true,
          dimmed: filteredTrainStarts.indexOf(conflict.leg.start) === -1,
          warnings: true,
        },
        points: [],
        paths: renderLegWarning(trainGraphs, conflict),
      })),
      ...congestionWarnings.map(congestion => ({
        id: congestion.leg.start.id,
        name: i18n.t(...congestion.i18nMessage),
        classes: {
          congestion: true,
          dimmed: filteredTrainStarts.indexOf(congestion.leg.start) === -1,
          warnings: true,
        },
        points: [],
        paths: renderLegWarning(trainGraphs, congestion),
      })),
      ...shifts.map(shift => ({
        id: shift.id,
        name: `${shift.pool.name} shift ${shift.name}`,
        classes: {
          shift: true,
          shifts: true,
          ...colourFromCrewPool(shift.pool.id, crewPools),
        },
        points: [],
        paths: pathFromShift(trainGraphs, shift),
      })),
      ...remoteRests.map(remoteRest => ({
        id: remoteRest.id,
        name: `${remoteRest.pool.name} rest between ${remoteRest.startShift.name} and ${remoteRest.nextShift.name}`,
        classes: {
          shifts: true,
          pool: remoteRest.pool.id,
          ...colourFromCrewPool(remoteRest.pool.id, crewPools),
        },
        points: [],
        paths: pathFromRemoteRest(trainGraphs, remoteRest),
      })),
      ...shifts
        .filter(shift => isSelected(shift))
        .map(shift => ({
          id: shift.id,
          name: `Shift ${shift.name}`,
          classes: {
            shift: true,
            shifts: true,
            selected: isSelected(shift),
          },
          points: [],
          paths: pathFromCompoundShift(trainGraphs, shift),
        })),
      ...services.map(service => ({
        id: service.id,
        name: service.name,
        classes: {
          service: true,
          selected: isSelected(service),
          dimmed: filteredServices.indexOf(service) === -1,
          warnings: service.warnings.length > 0,
        },
        service,
        ...renderService(trainGraphs, service, isSelected(service)),
      })),
      ...trainStarts.map(train => ({
        classes: {
          train: true,
          selected: isSelected(train),
          dimmed: filteredTrainStarts.indexOf(train) === -1,
          warnings: train.warnings.length > 0,
        },
        id: train.id,
        name: train.name,
        train,
        points: [],
        paths: renderTrainPaths(trainGraphs, train),
      })),
      ...driverTaskPaths(trainStarts, trainGraphs),
      ...changesEndPoints(changeovers, trainStarts, trainGraphs),
    ],
  }),
)
