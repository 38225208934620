import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { EmptyWagonEvent } from './model'

export const emptyWagonEventFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.emptywagonevents.map(data => {
      const item = new EmptyWagonEvent(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((emptyWagonEvent: EmptyWagonEvent, state: any) => ({
    wagon: registry
      .provide('wagonRepo')(state)
      .byId(emptyWagonEvent.wagonId),
    start: registry
      .provide('trainStartRepo')(state)
      .byId(emptyWagonEvent.startId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(emptyWagonEvent.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${emptyWagonEvent.startId}:${emptyWagonEvent.templateLegId}`),
  })),
)
