import { CargoType } from 'src/service-design/shared/models/cargo-type'
import { LoadingWorkTask } from 'src/service-design/shared/models/loading-work-task'
import { Location } from 'src/service-design/shared/models/location'
import { IResource } from 'src/service-design/shared/models/resource-summaries/types'
import { Service } from 'src/service-design/shared/models/service'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { Task } from 'src/service-design/shared/models/task/base'
import { sumCounts } from 'src/service-design/shared/utils/math'

export abstract class LoadTask extends Task {
  /**
   * An ABC for LoadingTask / UnloadingTask.
   *
   * Milky Way Challenge: Find a better name for this class.
   */

  startLeg: StartLeg
  services: Service[]
  cargoType: CargoType
  requiresDriver: boolean
  requiresRO: boolean
  _work: LoadingWorkTask[]
  abstract get preResourceDict(): Map<IResource, number>
  abstract get startResourceDict(): Map<IResource, number>
  abstract get endResourceDict(): Map<IResource, number>

  constructor({
    startLeg,
    cargoType,
    services = [],
    requiresDriver,
    requiresRO,
    ...rest
  }: {
    startLeg: StartLeg
    origin: Location
    destination: Location
    startTimeLocal: number
    endTimeLocal: number
    externals: any
    cargoType: CargoType
    services: Service[]
    requiresDriver: boolean
    requiresRO: boolean
  }) {
    super(rest)
    this.startLeg = startLeg
    this.services = services
    this.cargoType = cargoType
    this.requiresDriver = requiresDriver
    this.requiresRO = requiresRO
  }

  abstract getSplitWorkTasks(forDriver: boolean): LoadingWorkTask[]

  get resourceDict() {
    return this.wagonDict
  }

  get wagonDict() {
    return this.services
      .map(x => x.wagonset.wagonDict)
      .reduce(sumCounts, new Map())
  }

  get split() {
    return this.startLeg.loadingWorkSplits.find(
      s => s.kind === (this.constructor as any).kind,
    )
  }

  get work() {
    if (!this._work) {
      this._work = [true, false].flatMap(forDriver =>
        this.getSplitWorkTasks(forDriver),
      )
    }

    return this._work
  }
}
