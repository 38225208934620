import { LogMessage } from 'src/service-design/shared/models/log-message'

export class InsufficientResources extends LogMessage {
  static type = 'service-design::Insufficient resources'

  static message =
    "service-design::The assignments suggest an additional {{spareMagnitude}} {{resourceSummary.name}}'s are required"

  static check(resourceSummary) {
    return !resourceSummary.isElasticResource && resourceSummary.spare < 0
  }

  constructor(context) {
    const { resourceSummary } = context
    super(context, {
      spareMagnitude: resourceSummary.spareMagnitude.toFixed(2),
    })
  }
}

export class ResourceImbalance extends LogMessage {
  static type = 'service-design::Resource Imbalance'

  static message =
    'service-design::{{resourceSummary.name}} has a {{imbalance}} imbalance at {{location.code}}'
}

export const validators = {
  warnings: [ResourceImbalance, InsufficientResources],
}
