// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import { RemoteRestAttrs } from 'src/service-design/sd-plan/document/types'
import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { CompoundShift } from './model'

export const generateCompoundShifts = (
  shifts: { id: string; poolId: string }[],
  remoteRests: RemoteRestAttrs[],
) => {
  const remoteRestShiftIds = new Set(
    remoteRests.flatMap(l => [l.startShiftId, l.nextShiftId]),
  )
  const result = remoteRests.map(remoteRest => ({
    poolId: remoteRest.poolId,
    shiftIds: [remoteRest.startShiftId, remoteRest.nextShiftId],
    remoteRestIds: [remoteRest.id],
  }))
  const singletons = shifts
    .filter(shift => !remoteRestShiftIds.has(shift.id))
    .map(shift => ({
      poolId: shift.poolId,
      shiftIds: [shift.id],
      remoteRestIds: [],
    }))
  return [...result, ...singletons]
}

export const compoundShiftFactory = createFactoryFromDocument(
  ({ collectionData }: FactoryInput) =>
    generateCompoundShifts(
      collectionData.shifts,
      collectionData.remoterests,
    ).map(args => new CompoundShift(args)),
  setRelsFromSpec((s: CompoundShift, state: any) => ({
    pool: registry
      .provide('crewPoolRepo')(state)
      .byId(s.poolId),
    shifts: s.shiftIds.map(shiftId =>
      registry
        .provide('shiftRepo')(state)
        .byId(shiftId),
    ),
    remoteRests: s.remoteRestIds.map(remoteRestId =>
      registry
        .provide('remoteRestRepo')(state)
        .byId(remoteRestId),
    ),
  })),
)
