import { SECONDS_PER_MINUTE } from 'src/service-design/shared/constants'

export const FATIGUE_INTERVAL = 2000

export const YARD_GRAPH_ARRIVAL_DEPARTURE_DURATION = 20 * SECONDS_PER_MINUTE

export const ENGINE_LOG_RECEIVE = 'ENGINE_LOG_RECEIVE'
export const ENGINE_CONTEXT_RECEIVE = 'ENGINE_CONTEXT_RECEIVE'
export const ENGINE_CONTEXT_UPDATED = 'ENGINE_CONTEXT_UPDATED'
export const ENGINE_SOLUTION_RECEIVE = 'ENGINE_SOLUTION_RECEIVE'
export const ENGINE_RUNNING_STATES = [
  'created',
  'uploaded',
  'queued',
  'running',
]
export const ENGINE_META = new Map([
  [
    'pluto',
    {
      flag: 'enginePluto',
      engineText: 'Service Plan',
    },
  ],
  [
    'swiss',
    {
      flag: 'engineSwiss',
      engineText: 'Locomotive Plan',
    },
  ],
  [
    'pallas',
    {
      flag: 'enginePallas',
      engineText: 'Train Pathing',
    },
  ],
  [
    'ceto',
    {
      flag: 'engineCeto',
      engineText: 'Shift Plan',
    },
  ],
  [
    'sedna',
    {
      flag: 'engineSedna',
      engineText: 'Roster',
    },
  ],
])

export const EMPTY_FILTER = {
  simple: '',
  advanced: {},
  advancedActivated: true,
}

export const FILTER_SIMPLE = 'FILTER_SIMPLE'
export const FILTER_ADVANCED = 'FILTER_ADVANCED'
export const TOGGLE_FILTER_ADVANCED = 'TOGGLE_FILTER_ADVANCED'

export const BATCH_SELECT_ALL = 'BATCH_SELECT_ALL'
export const BATCH_DESELECT_ALL = 'BATCH_DESELECT_ALL'
export const BATCH_TOGGLE = 'BATCH_TOGGLE'
export const BATCH_CLEAR = 'BATCH_CLEAR'

export const FATIGUE_OUT_OF_DATE = 'FATIGUE_OUT_OF_DATE'
export const FATIGUE_UPDATED = 'FATIGUE_UPDATED'
export const FATIGUE_UPDATING = 'FATIGUE_UPDATING'
export const FATIGUE_DISABLED = 'FATIGUE_DISABLED'
export const FATIGUE_FAILED = 'FATIGUE_FAILED'

export const CREWS_FILTER = 'crews'
export const SERVICES_FILTER = 'services'
export const TRAINS_FILTER = 'trains'
export const CUSTOM_TASKS_FILTER = 'customTasks'
export const YARD_BLOCKS_FILTER = 'yardBlocks'

export const LOCK_TRAIN = 'Train Lock'
export const LOCK_WORKING_LOCO = 'Working Loco Lock'

export const LIGHTEST_LEG_GREATER_THAN = 'Lightest tonnage exceeds'
export const LIGHTEST_LEG_LESS_THAN = 'Lightest tonnage does not exceed'
export const HEAVIEST_LEG_GREATER_THAN = 'Heaviest tonnage exceeds'
export const HEAVIEST_LEG_LESS_THAN = 'Heaviest tonnage does not exceed'

export const ALL_TRAINS = 'All Trains'

export const ALL_TASKS = 'All Tasks'
export const TASKS_WITHIN_SHIFT = 'Tasks within shift'
export const TASKS_WITHIN_X_HOURS_OF_SHIFT = 'Tasks close to shift'
export const TASKS_WITHIN_REACH_OF_LV_IN_SHIFT = 'Tasks reachable by LV'

export const SHIFT_SELECT = 'SHIFT_SELECT'

export const SET_FOCUS = 'SET_FOCUS'

// Our modal names
export const MODAL_ASSIGNMENT = 'MODAL_ASSIGNMENT'
export const MODAL_ASSIGNMENT_DELETE = 'MODAL_ASSIGNMENT_DELETE'
export const MODAL_BATCH_LOCOS_DELETE = 'MODAL_BATCH_LOCOS_DELETE'
export const MODAL_BATCH_LOCOS_EDIT = 'MODAL_BATCH_LOCOS_EDIT'
export const MODAL_BATCH_TRAIN_DELETE = 'MODAL_BATCH_TRAIN_DELETE'
export const MODAL_BATCH_TRAIN_EDIT = 'MODAL_BATCH_TRAIN_EDIT'
export const MODAL_CHANGEOVER = 'MODAL_CHANGEOVER'
export const MODAL_CHANGEOVER_DELETE = 'MODAL_CHANGEOVER_DELETE'
export const MODAL_CREW_FILTER = 'MODAL_CREW_FILTER'
export const MODAL_CREW_POOL = 'MODAL_CREW_POOL'
export const MODAL_CREW_POOL_DELETE = 'MODAL_CREW_POOL_DELETE'
export const MODAL_CUSTOM_TASKS = 'MODAL_CUSTOM_TASKS'
export const MODAL_CUSTOM_TASK_DELETE = 'MODAL_CUSTOM_TASK_DELETE'
export const MODAL_CUSTOM_TASK_FILTER = 'MODAL_CUSTOM_TASK_FILTER'
export const MODAL_ENGINE_PARAMETERS = 'MODAL_ENGINE_PARAMETERS'
export const MODAL_ENGINE_PROGRESS = 'MODAL_ENGINE_PROGRESS'
export const MODAL_ENGINE_RESULT = 'MODAL_ENGINE_RESULT'
export const MODAL_ENGINE_REVIEW = 'MODAL_ENGINE_REVIEW'
export const MODAL_ENGINE_STOP = 'MODAL_ENGINE_STOP'
export const MODAL_ERROR = 'MODAL_ERROR'
export const MODAL_KCS_IMPORTER = 'MODAL_KCS_IMPORTER'
export const MODAL_REMOTE_REST = 'MODAL_REMOTE_REST'
export const MODAL_REMOTE_REST_DELETE = 'MODAL_REMOTE_REST_DELETE'
export const MODAL_RDO = 'MODAL_RDO'
export const MODAL_RESOURCE_AVAILABILITY = 'MODAL_RESOURCE_AVAILABILITY'
export const MODAL_RESOURCE_UNAVAILABILITY = 'MODAL_RESOURCE_UNAVAILABILITY'
export const MODAL_RESOURCE_UNAVAILABILITY_DELETE =
  'MODAL_RESOURCE_UNAVAILABILITY_DELETE'
export const MODAL_ROSTER_BATCH_DEALLOCATE = 'MODAL_ROSTER_BATCH_DEALLOCATE'
export const MODAL_ROUTE_DELETE = 'MODAL_ROUTE_DELETE'
export const MODAL_SCHEDULED_LEG = 'MODAL_SCHEDULED_LEG'
export const MODAL_SCHEDULED_LEG_DELETE = 'MODAL_SCHEDULED_LEG_DELETE'
export const MODAL_SCHEDULED_LEGS_DELETE = 'MODAL_SCHEDULED_LEGS_DELETE'
export const MODAL_LOCO_ALLOCATION = 'MODAL_LOCO_ALLOCATION'
export const MODAL_TEMPLATE_LEG_EXTRAS = 'MODAL_TEMPLATE_LEG_EXTRAS'
export const MODAL_TRAIN = 'MODAL_TRAIN'
export const MODAL_TRAIN_BLOCK = 'MODAL_TRAIN_BLOCK'
export const MODAL_TRAIN_BLOCK_DELETE = 'MODAL_TRAIN_BLOCK_DELETE'
export const MODAL_TRAIN_DELETE = 'MODAL_TRAIN_DELETE'
export const MODAL_TRAIN_TEMPLATE = 'MODAL_TRAIN_TEMPLATE'
export const MODAL_TRAIN_TEMPLATE_DELETE = 'MODAL_TRAIN_TEMPLATE_DELETE'
export const MODAL_TRAIN_START_DELETE = 'MODAL_TRAIN_START_DELETE'
export const MODAL_TRAIN_TEMPLATE_FILTER = 'MODAL_TRAIN_TEMPLATE_FILTER'
export const MODAL_SERVICE = 'MODAL_SERVICE'
export const MODAL_SERVICE_ROUTE = 'MODAL_SERVICE_ROUTE'
export const MODAL_SERVICE_DELETE = 'MODAL_SERVICE_DELETE'
export const MODAL_SERVICE_FILTER = 'MODAL_SERVICE_FILTER'
export const MODAL_SHIFT = 'MODAL_SHIFT'
export const MODAL_SHIFT_ASSIGNMENT = 'MODAL_SHIFT_ASSIGNMENT'
export const MODAL_SHIFT_DELETE = 'MODAL_SHIFT_DELETE'
export const MODAL_TRAIN_FILTER = 'MODAL_TRAIN_FILTER'
export const MODAL_WARNING_PREFERENCES = 'MODAL_WARNING_PREFERENCES'
export const MODAL_WAGON_SET = 'MODAL_WAGON_SET'
export const MODAL_YARD_BLOCK = 'MODAL_YARD_BLOCK'
export const MODAL_YARD_BLOCK_DELETE = 'MODAL_YARD_BLOCK_DELETE'
export const MODAL_YARD_BLOCK_FILTER = 'MODAL_YARD_BLOCK_FILTER'

export const RESOURCE_DEFINITION = {
  Wagon: { typeId: 'wagonId', header: 'Wagon Fleet' },
  Lococlass: { typeId: 'lococlassId', header: 'Loco Fleet' },
}

export const UI_FOCUS_LIST = 'UI_FOCUS_LIST'

export const DEFAULT_CATEGORY = '(Uncategorized)'
