import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'

import { YardBlock } from '.'

export class UnroutedYardBlock extends LogMessage {
  static type = 'service-design::Unrouted yard block'

  static message = 'service-design::{{entity.name}} is not routed.'

  static check(block: YardBlock) {
    return !block.assignment
  }
}

export default {
  warnings: flags.blocks ? [UnroutedYardBlock] : [],
}
