import { Location } from 'src/service-design/shared/models/location'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { TemporalMapper } from 'src/service-design/shared/models/mapper/temporal'
import { IResourceProfiler } from 'src/service-design/shared/models/resource-summaries/types'
import { CustomTaskAssignment } from 'src/service-design/shared/models/shift-assignment'
import {
  penaltyMultiplier,
  CyclicTime,
  EpochTime,
  WEEK_DURATION,
} from 'src/service-design/shared/utils/dates'

import validators from './validators'

class CustomTaskResourceProfile {
  target: CustomTask
  resources: any[]
  deltas: any[]
  activities: CustomTask[]

  constructor(task: CustomTask) {
    this.target = task
    this.resources = []
    this.deltas = []

    this.activities = [this.target]
  }

  get startTimeLocalNormalized() {
    return this.target.startTimeLocalNormalized
  }

  get endTimeLocalNormalized() {
    return this.target.endTimeLocalNormalized
  }
}

class CustomTask extends TemporalMapper implements IResourceProfiler {
  entityName = 'CustomTask'

  id: string
  name: string
  category: string
  startLocationId: string
  endLocationId: string
  _startTimeLocal: CyclicTime
  _endTimeLocal: CyclicTime
  requiresDriver: boolean
  comment: string
  startLocation: Location
  endLocation: Location
  assignment: CustomTaskAssignment

  static validators = validators

  /**
   * A CustomTask can represent an arbitrary piece of work that needs to
   * be crewed.
   *
   * In theory all the work on the network could be modelled using
   * CustomTasks. The issue here however that this loses the relationship
   * between the timing of TrainStarts and the timing of the task. That is,
   * a chunk of the work on the rail network exists to make the train run, when
   * the train change their timings the work should also shift.
   *
   * To address (in the future) this we may want to find some way to align
   * CustomTasks relative to the timing of TrainStarts.
   *
   * Related models:
   * - `CustomTaskAssignment`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} name - The name of the task.
   * @param {string} category - The a category for classifying tasks.
   * @param {string} startLocationId - The id of the location where the task
   *  starts.
   * @param {string} endLocationId - The id of the location where the task
   *  ends.
   * @param {number} startTimeLocal - The time (s) when the task begins.
   * @param {number} endTimeLocal - The time (s) when the task ends.
   * @param {boolean} requiresDriver - If true a Driver must perform this task.
   * @param {string} comment -
   **/
  constructor({
    id,
    name,
    category,
    startLocationId,
    endLocationId = startLocationId,
    startTimeLocal,
    endTimeLocal,
    requiresDriver,
    comment,
  }: {
    id: string
    name: string
    category: string
    startLocationId: string
    endLocationId: string
    startTimeLocal: CyclicTime
    endTimeLocal: CyclicTime
    requiresDriver: boolean
    comment: string
  }) {
    super()
    this.id = id
    this.name = name
    this.category = category
    this.startLocationId = startLocationId
    this.endLocationId = endLocationId
    this._startTimeLocal = startTimeLocal
    this._endTimeLocal = endTimeLocal
    this.requiresDriver = requiresDriver
    this.comment = comment
  }

  setRels({
    startLocation,
    endLocation,
    assignment,
  }: {
    startLocation: Location
    endLocation: Location
    assignment: CustomTaskAssignment
  }) {
    this.startLocation = startLocation
    this.endLocation = endLocation
    this.assignment = assignment
  }

  static kind = 'custom-task'

  get displayName(): string {
    return `${this.kind}: ${this.name}`
  }

  /**
   * @deprecated
   */

  get startTimeLocal(): number {
    return this.startTimeLocalVO.toSeconds()
  }

  get startTimeLocalVO(): EpochTime {
    return new EpochTime(this._startTimeLocal.toSeconds())
  }

  /**
   * @deprecated
   */

  get startTimeLocalNormalized(): number {
    return this.startTimeLocalNormalizedVO.toSeconds()
  }

  get startTimeLocalNormalizedVO(): EpochTime {
    return this.startTimeLocalVO
  }

  get kind(): string {
    return (this.constructor as any).kind
  }

  /**
   * @deprecated
   */
  get endTimeLocal(): number {
    return this.endTimeLocalVO.toSeconds()
  }

  get endTimeLocalVO(): EpochTime {
    return this.endTimeLocalNormalizedVO
  }

  /**
   * @deprecated
   */
  get endTimeLocalNormalized(): number {
    return this.endTimeLocalNormalizedVO.toSeconds()
  }

  /**
   * NOTE: This isn't your typical normalized time.
   * For tasks the normalized end time is actually
   * some time which is normalized to occur after the start time.
   * Effectively this returns a EpochTime
   */
  get endTimeLocalNormalizedVO(): EpochTime {
    const endTimeLocalVO = new EpochTime(this._endTimeLocal.toSeconds())
    return this.startTimeLocalVO.isStrictlyLater(endTimeLocalVO)
      ? endTimeLocalVO.makeLater(WEEK_DURATION)
      : endTimeLocalVO
  }

  get isAssigned(): boolean {
    return Boolean(this.assignment !== undefined)
  }

  get origin(): Location {
    return this.startLocation
  }

  get destination(): Location {
    return this.endLocation
  }

  getResourceProfiles(location) {
    if ([this.startLocation, this.endLocation].includes(location)) {
      return [new CustomTaskResourceProfile(this)]
    }
    return []
  }

  get penaltyMultiplier(): number {
    return penaltyMultiplier(
      this.startTimeLocalNormalized,
      this.endTimeLocalNormalized,
    )
  }

  get requiresTravel(): boolean {
    return this.startLocation !== this.endLocation
  }

  get warnings(): LogMessage[] {
    return [
      ...super.warnings,
      ...(this.assignment ? this.assignment.warnings : []),
    ]
  }
}

export { CustomTask }
