import { createSelector } from 'reselect'

import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getYardBlocksFilter,
  filterItems,
} from 'src/service-design/sd-plan/selectors/filters'
import * as YardBlock from 'src/service-design/shared/models/yard-block'

export const getRawYardBlocks = state =>
  getCollection(state, 'service-design', 'yardblocks')

export const getRawYardBlocksMap = createSelector(
  getRawYardBlocks,
  yardBlocks => new Map(yardBlocks.map(x => [x.id, x])),
)

export const matchesOriginId = (yardBlock, { originId }) =>
  !originId || yardBlock.origin.id === originId
export const matchesDestinationId = (yardBlock, { destinationId }) =>
  !destinationId || yardBlock.destination.id === destinationId

const YARD_BLOCK_FILTERS = [matchesOriginId, matchesDestinationId]

export const getFilteredYardBlocks = createSelector(
  getYardBlocksFilter,
  YardBlock.values,
  (filter, tasks) => filterItems(filter, tasks, YARD_BLOCK_FILTERS),
)
