import { Location } from 'src/service-design/shared/models/location'
import { TemporalMapper } from 'src/service-design/shared/models/mapper/temporal'
import { OffsetLeg } from 'src/service-design/shared/models/offset-leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'
import { Cost } from 'src/service-design/shared/types'
import { penaltyMultiplier } from 'src/service-design/shared/utils/dates'

export abstract class Task extends TemporalMapper {
  origin: Location
  destination: Location
  startTimeLocal: number
  endTimeLocal: number
  offsetLegs?: OffsetLeg[]
  _costs: Cost[]
  abstract get resourceDict(): Map<unknown, number>

  /**
   * A Task utilizes a set of resources (eg Lococlasses, Wagons) for a period
   * of time within the rail operators network.
   *
   * @constructor
   * @param {Location} origin - The entity id.
   * @param {Location} destination - The id of the AccessGroup
   * @param {number} startTimeLocal - The id of the Wagon
   * @param {number} endTimeLocal - The id of the Wagon
   * @param {OffsetLeg[]} offsetLegs - The id of the Wagon
   */
  constructor({
    origin,
    destination,
    startTimeLocal,
    endTimeLocal,
    externals,
    offsetLegs = null,
  }: {
    origin: Location
    destination: Location
    startTimeLocal: number
    endTimeLocal: number
    offsetLegs?: OffsetLeg[]
    externals: any
  }) {
    super()
    this.origin = origin
    this.destination = destination
    this.startTimeLocal = startTimeLocal
    this.endTimeLocal = endTimeLocal
    this.offsetLegs = offsetLegs
    this._costs = []
    this.setExternals(externals)
  }

  get kind() {
    return (this.constructor as any).kind // TODO DJH
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts(this.duration, this.resourceDict)
  }

  // eslint-disable-next-line class-methods-use-this
  get totalHauledSecs() {
    return new Map()
  }

  // TODO: standardize on our naming so we don't need this nonsense
  get dest() {
    return this.destination
  }

  // more alias nonsense :)
  get startLocation() {
    return this.origin
  }

  get endLocation() {
    return this.destination
  }

  get departsLocal() {
    return this.startTimeLocal
  }

  get arrivesLocal() {
    return this.endTimeLocal
  }

  get costs() {
    return this._costs
  }

  get penaltyMultiplier() {
    return penaltyMultiplier(
      this.startTimeLocalNormalized,
      this.endTimeLocalNormalized,
    )
  }

  get timeOffsetAllowed() {
    return (this.constructor as any).timeOffsetAllowed
  }
}
