import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { YardBlock } from './model'

export const yardBlockFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.yardblocks.map(data => {
      const item = new YardBlock(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((t: YardBlock, state: any) => ({
    origin: registry
      .provide('locationRepo')(state)
      .byId(t.originId),
    destination: registry
      .provide('locationRepo')(state)
      .byId(t.destinationId),
    assignment: registry
      .provide('yardBlockAssignmentRepo')(state)
      .byYardBlockId(t.id),
  })),
)
