import { push } from 'connected-react-router'

export const locationTo = (
  { pathname, search },
  { pathname: newPathname = undefined, params: newParams = {} },
  mergeParams,
) => {
  let updatedParams
  if (mergeParams) {
    const params = new URLSearchParams(search)
    const oldParams = [...params.entries()].reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {})
    updatedParams = { ...oldParams, ...newParams }
  } else {
    updatedParams = newParams
  }

  const updated = Object.keys(updatedParams).reduce((acc, key) => {
    acc.set(key, updatedParams[key])
    return acc
  }, new URLSearchParams())

  const updatedSearch = updated.toString()
  const updatedLocation = {
    pathname: newPathname === undefined ? pathname : newPathname,
    ...(updatedSearch.length ? { search: `?${updatedSearch}` } : {}),
  }
  return updatedLocation
}

export const locationSet = (to, mergeParams = true) => (dispatch, getState) => {
  dispatch(push(locationTo(getState().router.location, to, mergeParams)))
}
