import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { workingLocoLockFactory } from './factory'
import { IWorkingLocoLockRepo, WorkingLocoLockRepo } from './repo'

const workingLocoLockRepo = defaultMemoize(
  (input: FactoryInput) =>
    new WorkingLocoLockRepo(workingLocoLockFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  workingLocoLockRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export function setup<R extends WorkingLocoLockRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('workingLocoLockRepo', workingLocoLockRepo)

  relationships.addConstraints('workinglocolocks', {
    unique: [['templateLegId', 'startId']],
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'startId',
        name: 'start',
      },
      {
        collection: 'templatelegs',
        foreign: 'templateLegId',
        name: 'templateLeg',
      },
    ],
  })
}

export type WorkingLocoLockRepoReg = {
  workingLocoLockRepo: FromDocumentFactory<FactoryInput, IWorkingLocoLockRepo>
}
export { WorkingLocoLock } from './model'
