import { WorkbookDefinition } from 'src/service-design/shared/exporter/worksheet'
import * as AccessGroup from 'src/service-design/shared/models/access-group'
import * as BusinessGroup from 'src/service-design/shared/models/business-group'
import * as CargoType from 'src/service-design/shared/models/cargo-type'
import * as Corridor from 'src/service-design/shared/models/corridor'
import * as CrewType from 'src/service-design/shared/models/crew-type'
import * as ForeignRailroad from 'src/service-design/shared/models/foreign-railroad'
import * as LoadCategory from 'src/service-design/shared/models/load-category'
import * as LoadTable from 'src/service-design/shared/models/load-table'
import * as Location from 'src/service-design/shared/models/location'
import * as LocationCargoCompatibility from 'src/service-design/shared/models/location-cargo-compatibility'
import * as Lococlass from 'src/service-design/shared/models/lococlass'
import * as LococlassAccessRight from 'src/service-design/shared/models/lococlass-access-right'
import * as LVTrip from 'src/service-design/shared/models/lv-trip'
import * as RouteKnowledge from 'src/service-design/shared/models/route-knowledge'
import * as Timezone from 'src/service-design/shared/models/timezone'
import * as TrainGraph from 'src/service-design/shared/models/train-graph'
import * as TrainGraphLocation from 'src/service-design/shared/models/train-graph-location'
import * as TrainType from 'src/service-design/shared/models/train-type'
import * as TransitTimes from 'src/service-design/shared/models/transit-times'
import * as Wagon from 'src/service-design/shared/models/wagon'
import * as WagonAccessRight from 'src/service-design/shared/models/wagon-access-right'
import * as WagonCargoCompatibility from 'src/service-design/shared/models/wagon-cargo-compatibility'

import * as schemas from './schemas'

export const workSheets = new WorkbookDefinition([
  {
    sheet: 'Access Groups',
    type: 'collection',
    collection: 'accessgroups',
    selector: AccessGroup.values,
    schema: schemas.AccessGroup,
  },
  {
    sheet: 'Business Groups',
    type: 'collection',
    collection: 'businessgroups',
    selector: BusinessGroup.values,
    schema: schemas.BusinessGroup,
  },
  {
    sheet: 'Cargo Types',
    type: 'collection',
    collection: 'cargotypes',
    selector: CargoType.values,
    schema: schemas.CargoType,
  },
  {
    sheet: 'Corridors',
    type: 'collection',
    collection: 'corridors',
    selector: Corridor.values,
    schema: schemas.Corridor,
  },
  {
    sheet: 'Crew Types',
    type: 'collection',
    collection: 'crewtypes',
    selector: CrewType.values,
    schema: schemas.CrewType,
  },
  {
    sheet: 'Foreign Railroads',
    type: 'collection',
    collection: 'foreignrailroads',
    selector: ForeignRailroad.values,
    schema: schemas.ForeignRailroad,
  },
  {
    sheet: 'Load Categories',
    type: 'collection',
    collection: 'loadcategories',
    selector: LoadCategory.values,
    schema: schemas.LoadCategory,
  },
  {
    sheet: 'Load Tables',
    type: 'collection',
    collection: 'loadtables',
    selector: LoadTable.values,
    schema: schemas.LoadTable,
  },
  {
    sheet: 'Location Cargo Compatibilities',
    type: 'collection',
    collection: 'locationcargocompatibilities',
    selector: LocationCargoCompatibility.values,
    schema: schemas.LocationCargoCompatibility,
  },
  {
    sheet: 'Locations',
    type: 'collection',
    collection: 'locations',
    selector: Location.values,
    schema: schemas.Location,
  },
  {
    sheet: 'Loco Classes',
    type: 'collection',
    collection: 'lococlasses',
    selector: Lococlass.values,
    schema: schemas.Lococlass,
  },
  {
    sheet: 'Lococlass Access Rights',
    type: 'collection',
    collection: 'lococlassaccessrights',
    selector: LococlassAccessRight.values,
    schema: schemas.LococlassAccessRight,
  },
  {
    sheet: 'LV Trips',
    type: 'collection',
    collection: 'lvtrips',
    selector: LVTrip.values,
    schema: schemas.LVTrip,
  },
  {
    sheet: 'Timezones',
    type: 'collection',
    collection: 'timezones',
    selector: Timezone.values,
    schema: schemas.Timezone,
  },
  {
    sheet: 'Train Graph Locations',
    type: 'collection',
    collection: 'traingraphlocations',
    selector: TrainGraphLocation.values,
    schema: schemas.TrainGraphLocation,
  },
  {
    sheet: 'Train Graphs',
    type: 'collection',
    collection: 'traingraphs',
    selector: TrainGraph.values,
    schema: schemas.TrainGraph,
  },
  {
    sheet: 'Train Types',
    type: 'collection',
    collection: 'traintypes',
    selector: TrainType.values,
    schema: schemas.TrainType,
  },
  {
    sheet: 'Transit Times',
    type: 'collection',
    collection: 'transittimes',
    selector: TransitTimes.values,
    schema: schemas.TransitTime,
  },
  {
    sheet: 'Wagon Cargo Compatibilities',
    type: 'collection',
    collection: 'wagoncargocompatibilities',
    selector: WagonCargoCompatibility.values,
    schema: schemas.WagonCargoCompatibility,
  },
  {
    sheet: 'Wagon Access Rights',
    type: 'collection',
    collection: 'wagonaccessrights',
    selector: WagonAccessRight.values,
    schema: schemas.WagonAccessRight,
  },
  {
    sheet: 'Wagons',
    type: 'collection',
    collection: 'wagons',
    selector: Wagon.values,
    schema: schemas.Wagon,
  },
  {
    sheet: 'Route Knowledge',
    type: 'collection',
    collection: 'routeknowledge',
    selector: RouteKnowledge.values,
    schema: schemas.RouteKnowledge,
  },
  {
    sheet: 'Costs',
    type: 'singleton',
    singleton: 'costs',
    schema: schemas.Costs,
  },
  { sheet: 'Crew', type: 'singleton', singleton: 'crew', schema: schemas.Crew },
  {
    sheet: 'Light Vehicles',
    type: 'singleton',
    singleton: 'lv',
    schema: schemas.LV,
  },
])
