import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { Duration } from 'src/service-design/shared/utils/dates'

import { CrewType } from './model'

export const crewTypeFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.crewtypes.map(
      ({
        signOnSecs,
        signOffSecs,
        minimumShiftSecs,
        maximumShiftSecs,
        mealBreakSecs,
        mealBreakWindowSecs,
        mealBreakShiftSecs,
        dutyPerWeekSecs,
        reliefBufferSecs,
        minHomeRestSecs,
        annualLeaveSecs,
        remoteRestMinDurationSecs,
        remoteRestMealAllowancePeriodSecs,
        remoteRestMinimumShiftDurationSecs,
        remoteRestCostingMinDurationSecs,
        ...data
      }) => {
        const item = new CrewType({
          signOnSecs: Duration.fromSeconds(signOnSecs),
          signOffSecs: Duration.fromSeconds(signOffSecs),
          minimumShiftSecs: Duration.fromSeconds(minimumShiftSecs),
          maximumShiftSecs: Duration.fromSeconds(maximumShiftSecs),
          mealBreakSecs: Duration.fromSeconds(mealBreakSecs),
          mealBreakWindowSecs: Duration.fromSeconds(mealBreakWindowSecs),
          mealBreakShiftSecs: Duration.fromSeconds(mealBreakShiftSecs),
          dutyPerWeekSecs: Duration.fromSeconds(dutyPerWeekSecs),
          reliefBufferSecs: Duration.fromSeconds(reliefBufferSecs),
          minHomeRestSecs: Duration.fromSeconds(minHomeRestSecs),
          annualLeaveSecs: Duration.fromSeconds(annualLeaveSecs),
          remoteRestMinDurationSecs: Duration.fromSeconds(
            remoteRestMinDurationSecs,
          ),
          remoteRestMealAllowancePeriodSecs: Duration.fromSeconds(
            remoteRestMealAllowancePeriodSecs,
          ),
          remoteRestMinimumShiftDurationSecs: Duration.fromSeconds(
            remoteRestMinimumShiftDurationSecs,
          ),
          remoteRestCostingMinDurationSecs: Duration.fromSeconds(
            remoteRestCostingMinDurationSecs,
          ),
          ...data,
        })
        item.setExternals(externals)
        return item
      },
    ),
  setRelsFromSpec((crewType: CrewType, state: any) => ({
    loc: registry
      .provide('locationRepo')(state)
      .byId(crewType.locId),
    routeKnowledges: registry
      .provide('routeKnowledgeRepo')(state)
      .byCrewTypeId(crewType.id),
  })),
)
