import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { trainTemplateFactory } from './factory'
import { getTrainTemplateRepairs } from './repairs'
import { ITrainTemplateRepo, TrainTemplateRepo } from './repo'

export const trainTemplateRepo = defaultMemoize(
  (input: FactoryInput) => new TrainTemplateRepo(trainTemplateFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  trainTemplateRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type TrainTemplateRepoReg = {
  trainTemplateRepo: FromDocumentFactory<FactoryInput, ITrainTemplateRepo>
}

export function setup<R extends TrainTemplateRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('trainTemplateRepo', trainTemplateRepo)

  relationships.addConstraints('traintemplates', {
    rels: [
      {
        collection: 'traintypes',
        foreign: 'typeId',
        name: 'type',
      },
      {
        collection: 'businessgroups',
        foreign: 'businessGroupId',
        name: 'businessGroup',
      },
    ],
  })

  repairs.register(createSelector(values, getTrainTemplateRepairs))
}

export { TrainTemplate } from './model'
