import { Mapper } from 'src/service-design/shared/models/mapper'
import { Wagon } from 'src/service-design/shared/models/wagon'
import { WagonSet } from 'src/service-design/shared/models/wagon-set'

import validators from './validators'

interface Attrs {
  id: string
  wagonsetId?: string
  wagonId: string
  quantity: number
}

interface Rels {
  wagonset?: WagonSet
  wagon: Wagon
}

class WagonAllocation extends Mapper {
  static validators = validators

  /**
   * A WagonAllocation is an allocation of a number of wagons of a
   * certain type. See WagonSet.
   *
   * N.B. As Wagon should be called WagonType, WagonAllocation should
   * be thought of as instances of Wagon.
   *
   * Related models:
   * - Wagon
   * - WagonSet
   *
   * @param {string} id
   * @param {string} [wagonsetId] - the assignment of the wagons to a demand
   * @param {string} wagonId - the id of the wagon type of this allocation
   * @param {number} quantity - the number of wagons in this allocation
   */
  constructor({ id, wagonsetId, wagonId, quantity }: Attrs) {
    super()
    this.id = id
    this.wagonsetId = wagonsetId
    this.wagonId = wagonId
    this.quantity = quantity
  }

  setRels({ wagonset, wagon }: Rels) {
    this.wagonset = wagonset
    this.wagon = wagon
  }

  get tonnage() {
    return this.wagon.tareWeight * this.quantity
  }

  get length() {
    return this.wagon.length * this.quantity
  }

  get wagonWorkingKmCost() {
    return this.wagon.workingKmCost
  }

  static fromEvents(positive, negative = []): WagonAllocation[] {
    const quantities = [
      [1, positive],
      [-1, negative],
    ].reduce((acc, [sign, array]) => {
      array.forEach(({ wagon, quantity }) => {
        acc.set(wagon, (acc.get(wagon) || 0) + sign * quantity)
      })
      return acc
    }, new Map())
    return [...quantities.entries()]
      .filter(entry => entry[1] > 0)
      .map(([wagon, quantity]) =>
        WagonAllocation.construct({
          id: wagon.id,
          wagonId: wagon.id,
          wagon,
          quantity,
        }),
      )
  }
}

interface WagonAllocation extends Attrs, Rels {}
export { WagonAllocation }
