import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'
import { CyclicTime } from 'src/service-design/shared/utils/dates'

import { CustomTask } from './model'

export const customTaskFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.customtasks.map(
      ({ startTimeLocal, endTimeLocal, ...data }) => {
        const item = new CustomTask({
          startTimeLocal: CyclicTime.fromSeconds(startTimeLocal),
          endTimeLocal: CyclicTime.fromSeconds(endTimeLocal),
          // HACK: the comment is optional in the document but really shouldn't be
          comment: null,
          ...data,
        })
        item.setExternals(externals)
        return item
      },
    ),
  setRelsFromSpec((customTask: CustomTask, state: any) => ({
    startLocation: registry
      .provide('locationRepo')(state)
      .byId(customTask.startLocationId),
    endLocation: registry
      .provide('locationRepo')(state)
      .byId(customTask.endLocationId),
    assignment: registry
      .provide('customTaskAssignmentRepo')(state)
      .byCustomTaskId(customTask.id),
  })),
)
