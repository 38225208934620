import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { lvTripFactory } from './factory'
import { ILVTripRepo, LVTripRepo } from './repo'

const lvTripRepo = defaultMemoize(
  (input: FactoryInput) => new LVTripRepo(lvTripFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  lvTripRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type LVTripRepoReg = {
  lvTripRepo: FromDocumentFactory<FactoryInput, ILVTripRepo>
}

export function setup<R extends LVTripRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('lvTripRepo', lvTripRepo)

  relationships.addConstraints('lvtrips', {
    unique: [['loc1Id', 'loc2Id']],
    rels: [
      {
        collection: 'locations',
        foreign: 'loc1Id',
        name: 'loc1',
      },
      {
        collection: 'locations',
        foreign: 'loc2Id',
        name: 'loc2',
      },
    ],
  })
}

export { LVTrip } from './model'
