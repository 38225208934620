import {
  SECONDS_PER_WEEK,
  SECONDS_PER_HOUR,
} from 'src/service-design/shared/constants'
import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { DriverAssignment, LoadingAssignment } from 'src/service-design/shared/models/shift-assignment'
import { LVTask } from 'src/service-design/shared/models/task/lv'
import {
  intersectionExclusive,
  intersectionInclusive,
  inBounds,
  offsetString,
  EpochTime,
  Duration,
} from 'src/service-design/shared/utils/dates'

import { Shift } from './model'

// PN - RFP constants
const MAX_DOO_DURATION = 8

export class ShiftTooLong extends LogMessage {
  static type = 'service-design::Shift Too Long'

  static message =
    "service-design::Shift {{entity.name}}'s ({{entity.pool.name}}) duration is {{entity.duration, durationString}} which exceeds the maximum shift duration of {{entity.maximumShiftDuration, durationString}}"

  static check(shift: Shift) {
    return shift.durationVO.isStrictlyLonger(shift.maximumShiftDurationVO)
  }
}

const findOverlapping = assignments => {
  const overlapping = []
  for (let i = 0; i < assignments.length - 1; i++) {
    const curr = assignments[i]
    const next = assignments[i + 1]
    if (
      intersectionExclusive(
        [curr.startTimeLocal, curr.endTimeLocal],
        [next.startTimeLocal, next.endTimeLocal],
      )
    ) {
      overlapping.push([curr, next])
    }
  }
  return overlapping
}

export class OverlappingDrivingAssignments extends LogMessage {
  static type =
    'service-design::Driving assignments overlap with other assignments in shift'

  static message =
    'service-design::Driving assignments [{{assignment1}}, {{assignment2}}] overlap in shift {{entity.name}} ({{poolName}})'

  static check(shift: Shift) {
    const overlapping = findOverlapping(shift.assignments)
    return Boolean(this.getInvalidAssignments(overlapping).length)
  }

  static getInvalidAssignments(overlapping) {
    return overlapping.filter(
      ([t1, t2]) =>
        t1.requiresTravel ||
        t2.requiresTravel ||
        t1.actingAsDriver !== t2.actingAsDriver,
    )
  }

  constructor(context) {
    const shift = context.entity
    const overlapping = findOverlapping(shift.assignments)
    const [
      assignment1,
      assignment2,
    ] = OverlappingDrivingAssignments.getInvalidAssignments(overlapping)[0]
    super(context, {
      poolName: shift.pool.name,
      assignment1: assignment1.name,
      assignment2: assignment2.name,
    })
  }
}

export class DriveTrainPortionTooLong extends LogMessage {
  static type = '[RFP - IMDL] Too much DOO driving work in shift'
  static message =
    'Driving work in DOO shift {{entity.name}} longer than {{maxDooDuration}}h'

  static check(shift: Shift) {
    const { assignments } = shift
    const driverAssignments = assignments.filter(
      (a): a is DriverAssignment => a instanceof DriverAssignment,
    )
    const durations = driverAssignments
      .filter(assignment => assignment.task.assignments.length === 1)
      .reduce(
        (sum: Duration, assignment) => sum.add(assignment.durationVO),
        Duration.fromSeconds(0),
      )
    return durations.isStrictlyLonger(
      Duration.fromSeconds(MAX_DOO_DURATION * SECONDS_PER_HOUR),
    )
  }

  constructor(context) {
    super(context, {
      maxDooDuration: MAX_DOO_DURATION,
    })
  }
}

export class AllTasks2UP extends LogMessage {
  static type = '[RFP - Coal] DOO Task present'
  static message =
    'Task in shift {{entity.name}} is DOO, please assign another driver'

  static check(shift: Shift) {
    const { assignments } = shift
    const driverAssignments = assignments.filter(
      (a): a is DriverAssignment => a instanceof DriverAssignment,
    )
    const loadAssignments = assignments.filter(
      (a): a is LoadingAssignment => a instanceof LoadingAssignment,
    )
    return [...driverAssignments, ...loadAssignments]
      .filter(assignment => assignment.task.assignments.length === 1)
      .length > 0
  }
}

export class NonDrivingAssignmentsOverlapAtDifferentLocations extends LogMessage {
  static type =
    'service-design::Cannot assign non driving tasks from different locations'

  static message =
    'service-design::[{{assignment1}}; {{assignment2}}] occur on different locations [{{location1}}; {{location2}}] in shift {{entity.name}} ({{poolName}})'

  static findOverlappingOnDifferentLocations(assignments) {
    const nonDrivingAssignments = assignments.filter(
      a => !(a instanceof DriverAssignment || a instanceof LVTask),
    )
    const overlapping = findOverlapping(nonDrivingAssignments)
    return overlapping.filter(
      ([curr, next]) =>
        curr.startLocation === curr.endLocation &&
        next.startLocation === next.endLocation &&
        curr.startLocation !== next.startLocation,
    )
  }

  static check(shift: Shift) {
    const overlappingOnDifferentLocations = this.findOverlappingOnDifferentLocations(
      shift.assignments,
    )
    return overlappingOnDifferentLocations.length > 0
  }

  constructor(context) {
    const shift = context.entity
    const [
      assignment1,
      assignment2,
    ] = NonDrivingAssignmentsOverlapAtDifferentLocations.findOverlappingOnDifferentLocations(
      shift.assignments,
    )[0]

    super(context, {
      poolName: shift.pool.name,
      assignment1: assignment1.name,
      assignment2: assignment2.name,
      location1: assignment1.startLocation.name,
      location2: assignment2.endLocation.name,
    })
  }
}

export class AssignedTaskOutsideShift extends LogMessage {
  static type = 'service-design::Assigned work outside shift'

  static message =
    'service-design::Crew pool: {{poolName}}, Shift starting at: {{shiftStart}}, has work assigned that is outside the shift'

  static check(shift: Shift) {
    const {
      assignments,
      signOnEndLocalVO: signOnEndLocal,
      signOffStartLocalVO: signOffStartLocal,
      signOnLocalVO,
      signOffLocalVO,
    } = shift

    const assignmentsOutsideShift = assignments.filter(
      x =>
        !inBounds(
          [signOnEndLocal.toSeconds(), signOffStartLocal.toSeconds()],
          [x.startTimeLocal, x.endTimeLocal],
        ),
    )

    const signOffBounds: [number, number][] = [
      [signOnLocalVO.toSeconds(), signOnEndLocal.toSeconds()],
      [signOffStartLocal.toSeconds(), signOffLocalVO.toSeconds()],
    ]
    const signOnOffOutsideShift = signOffBounds.filter(
      pair =>
        !inBounds(
          [signOnLocalVO.toSeconds(), signOffLocalVO.toSeconds()],
          pair,
        ),
    )

    return [assignmentsOutsideShift, signOnOffOutsideShift].some(
      x => x.length > 0,
    )
  }

  constructor(context: { entity: Shift }) {
    super(context, {
      poolName: context.entity.pool.name,
      shiftStart: offsetString(context.entity.signOnStartLocalVO.toSeconds()),
    })
  }
}

export class UnrosteredShift extends LogMessage {
  static type = 'service-design::Unrostered shift'

  static message =
    'service-design::Shift {{entity.name}} ({{entity.pool.name}}) is unrostered'

  static check(shift: Shift) {
    return !shift.isAssigned
  }
}

export class NoMealBreakOpportunity extends LogMessage {
  static type = 'service-design::No meal break opportunity in shift'

  static message =
    'service-design::There is no valid meal break opportunity in shift {{entity.name}} ({{entity.pool.name}})'

  static tasksHasMealBreakOpportunity(
    tasks,
    mealBreakWindowStartLocal: number,
    mealBreakWindowEndLocal: number,
    mealBreakSecs: number,
  ) {
    return tasks.some(task => {
      const lowerBound = Math.max(
        task.startTimeLocal,
        mealBreakWindowStartLocal,
      )
      const upperBound = Math.min(task.endTimeLocal, mealBreakWindowEndLocal)
      const intersectionDuration = upperBound - lowerBound

      const taskHasOpportunityForMealBreak =
        task.slackDuration >= mealBreakSecs &&
        intersectionDuration >= mealBreakSecs

      return taskHasOpportunityForMealBreak
    })
  }

  static idleTimeHasMealBreakOpportunity(
    assignmentsAcrossMealBreak: {
      startTimeLocal: number
      endTimeLocal: number
    }[],
    mealBreakWindowStartLocal: number,
    mealBreakWindowEndLocal: number,
    mealBreakSecs: number,
  ) {
    const mealBreakWindowUsage: {
      endTimeLocal?: number
      startTimeLocal?: number
    }[] = [
        {
          endTimeLocal: mealBreakWindowStartLocal,
        },
        ...assignmentsAcrossMealBreak,
        {
          startTimeLocal: mealBreakWindowEndLocal,
        },
      ]

    for (let i = 0; i < mealBreakWindowUsage.length - 1; i++) {
      const curr = mealBreakWindowUsage[i]
      const next = mealBreakWindowUsage[i + 1]

      const upperBound = Math.min(next.startTimeLocal, mealBreakWindowEndLocal)
      const lowerBound = Math.max(curr.endTimeLocal, mealBreakWindowStartLocal)

      if (upperBound - lowerBound >= mealBreakSecs) {
        return true
      }
    }
    return false
  }

  static mapDwellToShift(
    dwellTask,
    signOnLocal: EpochTime,
  ): { startTimeLocal: number; endTimeLocal: number; slackDuration: number } {
    let startNorm = dwellTask.startTimeLocalNormalized
    let endNorm = dwellTask.endTimeLocalNormalized

    if (
      Math.abs(startNorm - signOnLocal.toSeconds()) >
      Math.abs(startNorm - signOnLocal.toSeconds() + SECONDS_PER_WEEK)
    ) {
      startNorm += SECONDS_PER_WEEK
      endNorm += SECONDS_PER_WEEK
    }

    return {
      startTimeLocal: startNorm,
      endTimeLocal: endNorm,
      slackDuration: endNorm - startNorm,
    }
  }

  static check(shift: Shift) {
    if (!shift.requiresMealBreak) {
      return false
    }
    const {
      assignments,
      signOnLocalVO,
      mealBreakWindowStartLocalVO: mealBreakWindowStartLocal,
      mealBreakWindowEndLocalVO: mealBreakWindowEndLocal,
      mealBreakSecsVO: mealBreakSecs,
    } = shift

    const assignmentsAcrossMealBreak = assignments.filter(a =>
      intersectionInclusive(
        [
          mealBreakWindowStartLocal.toSeconds(),
          mealBreakWindowEndLocal.toSeconds(),
        ],
        [a.startTimeLocal, a.endTimeLocal],
      ),
    )

    const driverAssignments = assignmentsAcrossMealBreak.filter(
      (a): a is DriverAssignment => a instanceof DriverAssignment,
    )

    const dwellTasks = driverAssignments
      .flatMap(x => x.potentialMealBreaks)
      .map(mealBreak => this.mapDwellToShift(mealBreak, signOnLocalVO))

    if (
      this.tasksHasMealBreakOpportunity(
        dwellTasks,
        mealBreakWindowStartLocal.toSeconds(),
        mealBreakWindowEndLocal.toSeconds(),
        mealBreakSecs.toSeconds(),
      )
    ) {
      return false
    }

    const squashLVTask = lvTask => ({
      startTimeLocal: lvTask.startTimeLocal + lvTask.slackDuration,
      endTimeLocal: lvTask.endTimeLocal - lvTask.slackDuration,
    })

    return !this.idleTimeHasMealBreakOpportunity(
      assignmentsAcrossMealBreak
        .filter(a => a instanceof DriverAssignment || a instanceof LVTask)
        .map(a => (a instanceof LVTask ? squashLVTask(a) : a)),
      mealBreakWindowStartLocal.toSeconds(),
      mealBreakWindowEndLocal.toSeconds(),
      mealBreakSecs.toSeconds(),
    )
  }
}

export default {
  warnings: flags.crewing
    ? [
      ShiftTooLong,
      OverlappingDrivingAssignments,
      NonDrivingAssignmentsOverlapAtDifferentLocations,
      DriveTrainPortionTooLong,
      AllTasks2UP,
      AssignedTaskOutsideShift,
      NoMealBreakOpportunity,
      UnrosteredShift,
    ]
    : [],
}
