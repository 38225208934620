import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { LoadCategory } from './model'

export const loadCategoryFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.loadcategories.map(data => {
      const item = new LoadCategory(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((loadCategory: LoadCategory, state: any) => ({
    lococlasses: registry
      .provide('lococlassRepo')(state)
      .byLoadCategory(loadCategory.id),
  })),
)
