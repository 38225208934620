import { createSelector } from 'reselect'

import { getLocations } from 'src/service-design/sd-plan/selectors/scenario'
import { generateLocationWork } from 'src/service-design/sd-plan/selectors/yards'
import { LocationSummary } from 'src/service-design/shared/models/location-summaries'
import * as Shift from 'src/service-design/shared/models/shift'
import * as TrainStart from 'src/service-design/shared/models/train-start'

export const getLocationSummaries = createSelector(
  getLocations,
  TrainStart.values,
  Shift.values,
  (locations, trains, shifts) =>
    LocationSummary.buildCollection(
      locations,
      generateLocationWork([...locations.values()], trains, shifts),
    ),
)

export const getResourceEvents = createSelector(
  getLocationSummaries,
  summaries =>
    summaries.reduce((events, summ) => {
      events.push(...summ.resourceEvents)
      return events
    }, []),
)
