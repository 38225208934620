import get from 'get-or-else'

export const getCurrentRevision = state => get([state, 'save.currentRevision'])
export const documentSaving = state => get([state, 'save.saving'], false)
export const documentSaveStopped = state =>
  get([state, 'save.saveStopped'], false)

export const documentModified = (state, documentRoot) => {
  const currentRevision = getCurrentRevision(state)
  const document = get([state, documentRoot])
  return Boolean(document && document.data !== currentRevision)
}
