import { FactoryInput } from 'src/service-design/shared/document-factory'
import { createFactoryFromDocument } from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { ForeignRailroad } from './model'

export const foreignRailroadFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.foreignrailroads.map(data => {
      const item = new ForeignRailroad(data)
      item.setExternals(externals)
      return item
    }),
)
