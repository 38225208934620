import { createSelector } from 'reselect'

import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getServiceFilter,
  filterItems,
} from 'src/service-design/sd-plan/selectors/filters'
import { getTrainLegs } from 'src/service-design/sd-plan/selectors/trains'
import { Location } from 'src/service-design/shared/models/location'
import * as Service from 'src/service-design/shared/models/service'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import {
  offsetString,
  EpochTime,
  WEEK_DURATION,
} from 'src/service-design/shared/utils/dates'

export const matchesCustomerName = (service, { customer }) =>
  !customer || service.customer === customer
export const matchesCargoType = (service, { cargoTypeId }) =>
  !cargoTypeId || service.cargoTypeId === cargoTypeId
export const matchesOrigin = (service, { originId }) =>
  !originId || service.originId === originId
export const matchesDestination = (service, { destinationId }) =>
  !destinationId || service.destinationId === destinationId

const SERVICE_FILTERS = [
  matchesCustomerName,
  matchesCargoType,
  matchesOrigin,
  matchesDestination,
]

export const getRawServices = state =>
  getCollection(state, 'service-design', 'services')

export const getServiceTotal = createSelector(
  getRawServices,
  services => services.length,
)

export const originLegParameters = (
  services: Service.Service[],
  serviceId: string,
  assignmentNum: number,
): [Location, number] => {
  const service = services.find(x => x.id === serviceId)
  const { assignments } = service

  let startLocation = service.origin
  let startTimeLocal = service.deliveryCutOffLocal
  if (assignmentNum > 1 && assignments.length) {
    const prevAssignment = assignments.find(
      asgmt => asgmt.assignmentNum === assignmentNum - 1,
    )
    startLocation = prevAssignment.destination
    startTimeLocal = prevAssignment.arrivesLocal.toSeconds()
  }
  return [startLocation, startTimeLocal]
}

export const originLegOption = (
  origin: Location,
  train: TrainStart.TrainStart,
): {
  leg: StartLeg.StartLeg
  option: { id: string; displayName: string }
} | null => {
  const option = train.legs.find(leg => leg.origin === origin)
  return option
    ? {
        leg: option,
        option: {
          id: option.id,
          displayName:
            `${train.name} departing ${origin.code} ` +
            `${offsetString(option.departsLocal.toSeconds())} ` +
            `(to ${train.destination.code})`,
        },
      }
    : null
}

export const originLegOptions = (
  trains: TrainStart.TrainStart[],
  startLocation: Location,
  startTimeLocal: number,
): {
  id: string
  displayName: string
}[] => {
  const startTimeLocalVO = EpochTime.fromSeconds(startTimeLocal)
  return trains
    .map(train => originLegOption(startLocation, train))
    .filter(legOption => Boolean(legOption))
    .sort(({ leg: leg1 }, { leg: leg2 }) => {
      const leg1TimeOfWeek = leg1.departsLocal.isLater(startTimeLocalVO)
        ? leg1.departsLocal
        : leg1.departsLocal.makeLater(WEEK_DURATION)
      const leg2TimeOfWeek = leg2.departsLocal.isLater(startTimeLocalVO)
        ? leg2.departsLocal
        : leg2.departsLocal.makeLater(WEEK_DURATION)
      return leg1TimeOfWeek.toSeconds() - leg2TimeOfWeek.toSeconds()
    })
    .map(data => data.option)
}

export const getOriginLegOptions = (serviceId: string, assignmentNum: number) =>
  createSelector(
    Service.values,
    TrainStart.values,
    (services, trains: TrainStart.TrainStart[]) => {
      const [startLocation, startTimeLocal] = originLegParameters(
        services,
        serviceId,
        assignmentNum,
      )
      return originLegOptions(trains, startLocation, startTimeLocal)
    },
  )

export const getDestLegOptions = originLegId =>
  createSelector(StartLeg.selector, getTrainLegs, (legs, trainLegs) => {
    const originLeg = legs.byId(originLegId)
    return originLeg
      ? trainLegs
          .get(originLeg.startId)
          .filter(leg => leg.legNum >= originLeg.legNum && leg.dest.yard)
          .map(leg => ({
            ...leg,
            id: leg.id,
            displayName: `to ${leg.dest.code}`,
          }))
      : []
  })

export const getAssignmentData = (serviceId, assignmentNum) =>
  createSelector(Service.selector, services => {
    const asgmt = services
      .byId(serviceId)
      .assignments.find(a => a.assignmentNum === assignmentNum)
    return asgmt
      ? {
          serviceId,
          originLegId: asgmt.originLeg.id,
          destLegId: asgmt.destLeg.id,
          eventIds: [asgmt.attach.id, asgmt.detach.id],
        }
      : {
          serviceId,
          assignmentNum,
        }
  })

export const getServiceRouteName = serviceId =>
  createSelector(getRawServices, services => {
    const { name } = services.find(x => x.id === serviceId)
    return `all route assignments for ${name}`
  })

export const getAssignment = (state, serviceId, assignmentNum) =>
  Service.selector(state)
    .byId(serviceId)
    .assignments.find(a => a.assignmentNum === assignmentNum)

export const getFilteredServices = createSelector(
  getServiceFilter,
  Service.values,
  (filter, services) => filterItems(filter, services, SERVICE_FILTERS),
)
