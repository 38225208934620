import { IResource } from 'src/service-design/shared/models/resource-summaries/types'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'
import { count } from 'src/service-design/shared/utils/math'

export class Dwell<T extends IStartLeg> extends LegTask<T> {
  /**
   * Dwell represents the time between the arrival and departure
   * of a TrainStart at a location between any shunting activities. That is, the
   * idle time between PostArrival / Detach and Attach / PreDeparture.
   *
   * This task largely exists for accounting purposes as a TrainStart does
   * nothing during this period.
   */

  static arrivingTask = true

  static kind = 'dwell'

  static requiresRailOperator = false

  static timeOffsetAllowed = false

  static build(startLeg: StartLeg) {
    return startLeg.next
      ? new this({
          origin: startLeg.dest,
          destination: startLeg.dest,
          startTimeLocal: startLeg.detachEndLocal,
          endTimeLocal: startLeg.next.attachStartLocal,
          startLeg,
          externals: startLeg.externals,
        })
      : null
  }

  get departingLeg(): StartLeg {
    if (this.startLeg instanceof StartLeg) {
      return this.startLeg.next
    }
    throw new Error("can't departingLeg() an OffsetLeg")
  }

  get workingLocosDict() {
    return count(
      this.departingLeg.throughLocoAllocations
        .filter(a => a.working)
        .map(({ lococlass }) => lococlass),
    )
  }

  get hauledLocosDict() {
    return count(
      this.departingLeg.throughLocoAllocations
        .filter(a => !a.working)
        .map(({ lococlass }) => lococlass),
    )
  }

  get wagonDict() {
    return new Map(
      this.departingLeg.throughWagonAllocations.map(({ wagon, quantity }) => [
        wagon,
        quantity,
      ]),
    )
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts<IResource>(
      this.duration,
      this.workingLocosDict,
      this.wagonDict,
    )
  }

  get totalHauledSecs() {
    return mergeMultiplyCounts(this.duration, this.hauledLocosDict)
  }

  get resourceDict() {
    return new Map([
      ...this.departingLeg.throughLocosDict,
      ...this.departingLeg.throughWagonDict,
    ])
  }
}
