import { DAYS_IN_WEEK, DAYS } from 'src/service-design/shared/constants'
import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'

// PN - RFP
const RDOS_PER_LINE = 1

export class CrewPoolSize extends LogMessage {
  static type = 'service-design::Crew Pool too small'

  static message =
    'service-design::{{entity.name}} requires {{entity.requiredQuantity}} members to cover the duty hours.'

  static check(pool) {
    return pool.requiredQuantity > pool.quantity
  }
}

export class InsufficientRDOs extends LogMessage {
  static type = 'service-design::Insufficient RDOs'

  static message =
    'service-design::{{entity.name}} roster needs {{entity.requiredRDOs}} RDOs but only has {{entity.totalRDOs}}.'

  static check(pool) {
    return pool.totalRDOs < pool.requiredRDOs
  }
}

export class InsufficientAbsoluteRDOs extends LogMessage {
  static type = 'service-design::[RFP - IMDL & COAL] - Insufficient RDOs per line'

  static message =
    `service-design::{{entity.name}} roster needs ${RDOS_PER_LINE} RDO${RDOS_PER_LINE > 1 ? 's': ''} per line.`

  static check(pool) {
    for (const l of pool.lines) {
      if (l.RDOs.length < RDOS_PER_LINE) {
        return true
      }
    }
    return false
  }
}

export class TooManyDaysWithoutRDO extends LogMessage {
  static type = 'service-design::Too many days without an RDO'

  static message =
    'service-design::{{pool.name}} roster has too many days without an RDO on line {{lineNum}}'

  static findViolations(pool) {
    const violations = []
    const RDOs = pool.RDOs.map(rdo => ({
      ...rdo,
      startDay: rdo.dayInRoster,
      endDay: rdo.dayInRoster + 1,
      lineNum: rdo.line.num,
    }))
    const relief = pool.reliefLines.map(line => {
      const startDay = (line.num - 1) * DAYS_IN_WEEK
      return {
        ...line,
        startDay,
        endDay: startDay + DAYS_IN_WEEK,
        lineNum: line.num,
      }
    })
    const sorted = [...RDOs, ...relief].sort((a, b) => {
      if (a.startDay === b.startDay) {
        return a.endDay - b.endDay
      }
      return a.startDay - b.startDay
    })

    if (sorted.length) {
      sorted.push({
        startDay: sorted[0].startDay + pool.quantity * DAYS_IN_WEEK,
      })
    }

    const { maxDaysBetweenRDOs } = pool.type
    for (let i = 0; i < sorted.length - 1; i++) {
      if (sorted[i + 1].startDay - sorted[i].endDay > maxDaysBetweenRDOs) {
        violations.push(sorted[i])
      }
    }
    return violations
  }

  static check() {
    return false
  }

  constructor(context) {
    super(context, {
      lineNum: `${context.rdo.lineNum}, ${
        DAYS[context.rdo.startDay % DAYS_IN_WEEK]
      }`,
    })
  }

  static build(pool) {
    if (pool.type.maxDaysBetweenRDOs !== 0) {
      return this.findViolations(pool).map(rdo => new this({ rdo, pool }))
    }
    return []
  }
}

export default {
  warnings: flags.crewing
    ? [CrewPoolSize, InsufficientRDOs, InsufficientAbsoluteRDOs, TooManyDaysWithoutRDO]
    : [],
}
