import { LogMessage } from 'src/service-design/shared/models/log-message'

export class InvalidCoupling extends LogMessage {
  static type = 'service-design::Infeasible attach/detach of empty wagons'

  static message =
    'service-design::{{displayName}} of {{entity.quantity}} {{entity.wagon.name}} wagons is not a multiple of the coupled set size ({{entity.wagon.coupledSetSize}})'

  static check(event) {
    return event.quantity % event.wagon.coupledSetSize !== 0
  }

  static entityTypeMap = {
    attach: 'Attachment',
    detach: 'Detachment',
  }

  constructor(context) {
    super(context)
    // @ts-ignore issue here is that there's no information around the type of derived
    this.derived.displayName = this.constructor.entityTypeMap[
      context.entity.type
    ]
  }
}

export default {
  warnings: [InvalidCoupling],
}
