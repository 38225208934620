import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { locationTo } from 'src/service-design/shared/routing'

export const ConnectedLink = ({
  currentLocation,
  to,
  mergeParams,
  ...props
}) => <NavLink to={locationTo(currentLocation, to, mergeParams)} {...props} />

ConnectedLink.propTypes = {
  currentLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  mergeParams: PropTypes.bool,
}

ConnectedLink.defaultProps = {
  mergeParams: true,
}
