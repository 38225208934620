import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { wagonFactory } from './factory'
import { IWagonRepo, WagonRepo } from './repo'

const wagonRepo = defaultMemoize(
  (input: FactoryInput) => new WagonRepo(wagonFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  wagonRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type WagonRepoReg = {
  wagonRepo: FromDocumentFactory<FactoryInput, IWagonRepo>
}

export function setup<R extends WagonRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('wagonRepo', wagonRepo)
}

export { Wagon } from './model'
