import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  FromDocumentFactory,
  Registry,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { locationChangeoverLockFactory } from './factory'
import { ILocationChangeoverLockRepo, LocationChangeoverLockRepo } from './repo'

export const locationChangeoverLockRepo = defaultMemoize(
  (input: FactoryInput) =>
    new LocationChangeoverLockRepo(locationChangeoverLockFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  locationChangeoverLockRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type LocationChangeoverLockRepoReg = {
  locationChangeoverLockRepo: FromDocumentFactory<
    FactoryInput,
    ILocationChangeoverLockRepo
  >
}

export function setup<R extends LocationChangeoverLockRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('locationChangeoverLockRepo', locationChangeoverLockRepo)

  relationships.addConstraints('locationchangeoverlocks', {
    unique: [['changeoverId']],
    rels: [
      {
        collection: 'locationchangeovers',
        foreign: 'changeoverId',
        name: 'changeover',
      },
    ],
  })
}

export { LocationChangeoverLock } from './model'
