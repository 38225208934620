import { Mapper } from 'src/service-design/shared/models/mapper'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { Wagon } from 'src/service-design/shared/models/wagon'

import validators from './validators'

interface Attrs {
  id: string
  startId: string
  templateLegId: string
  wagonId: string
  quantity: number
  type: 'attach' | 'detach'
}

interface Rels {
  wagon: Wagon
  start: TrainStart
  templateLeg: TemplateLeg
  startLeg: StartLeg
}

class EmptyWagonEvent extends Mapper {
  static validators = validators

  /**
   * An EmptyWagonEvent attaches wagon to or detaches them from a TrainStart.
   * See StartLeg.
   *
   * Attach operations occur prior to the departure of the StartLeg. Detach
   * operations occur after StartLeg arrival.
   *
   * Related models:
   * - `StartLeg`;
   * - `TemplateLeg`;
   * - `TrainStart`;
   * - `Wagon`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} startId - The id of the TrainStart the empty wagons are
   *  being associated with.
   * @param {string} templateLegId - The id of the TemplateLeg where the empties
   *  are being attached / detached.
   * @param {string} wagonId - The id of the Wagon.
   * @param {number} quantity - The number of wagons associated with the event.
   * @param {string} type - Should be 'attach' or 'detach'.
   *
   **/
  constructor({ id, startId, templateLegId, wagonId, quantity, type }: Attrs) {
    super()
    this.id = id
    this.startId = startId
    this.templateLegId = templateLegId
    this.wagonId = wagonId
    this.quantity = quantity
    this.type = type
  }

  setRels({ wagon, start, templateLeg, startLeg }: Rels) {
    this.wagon = wagon
    this.start = start
    this.startLeg = startLeg
    this.templateLeg = templateLeg
  }

  get location() {
    return this.type === 'attach' ? this.startLeg.origin : this.startLeg.dest
  }
}

interface EmptyWagonEvent extends Attrs, Rels {}
export { EmptyWagonEvent }
