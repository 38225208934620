import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { offsetString } from 'src/service-design/shared/utils/dates'

export class IncompleteRoute extends LogMessage {
  static type = 'service-design::Incomplete Demand Route'

  static message = 'service-design::{{entity.name}} is incomplete'

  static check(service) {
    return !service.isRouted
  }
}

export class FreightAvailabilityBreached extends LogMessage {
  static type = 'service-design::Route breaches freight availability'

  static message =
    "service-design::{{entity.name}}'s route terminates at {{arrivesLocal}}. Freight availability time is {{dueLocal}}"

  static check(service) {
    return service.isRouted && service.arrivesLocal > service.dueLocal
  }

  constructor(context) {
    super(context, {
      arrivesLocal: offsetString(context.entity.arrivesLocal),
      dueLocal: offsetString(context.entity.dueLocal),
    })
  }
}

export class DeliveryCutOffBreached extends LogMessage {
  static type = 'service-design::Route breaches delivery cut off'

  static message =
    "service-design::{{entity.name}}'s route departs at {{departsLocal}}. Delivery cut off time is {{deliveryCutOffLocal}}"

  static check(service) {
    return (
      service.isRouted && service.departsLocal < service.deliveryCutOffLocal
    )
  }

  constructor(context) {
    super(context, {
      departsLocal: offsetString(context.entity.departsLocal),
      deliveryCutOffLocal: offsetString(context.entity.deliveryCutOffLocal),
    })
  }
}

export class IncompatibleOriginCargo extends LogMessage {
  static type = 'service-design::Incompatible cargo at origin'

  static message =
    "service-design::{{entity.name}}'s cargo type {{entity.cargoType.name}} cannot be loaded at {{entity.origin.code}}"

  static check(service) {
    return !service.cargoType.compatibilities.find(
      c => c.locationId === service.origin.id,
    )
  }
}

export class IncompatibleDestinationCargo extends LogMessage {
  static type = 'service-design::Incompatible cargo at destination'

  static message =
    "service-design::{{entity.name}}'s cargo type {{entity.cargoType.name}} cannot be unloaded at {{entity.destination.code}}"

  static check(service) {
    return !service.cargoType.compatibilities.find(
      c => c.locationId === service.destination.id,
    )
  }
}

export class InsufficientWagons extends LogMessage {
  static type = 'service-design::Not enough wagons assigned to demand'

  static message =
    'service-design::{{entity.name}} requires {{entity.volume}}{{entity.volumeUnit}}. Wagons have {{entity.wagonset.capacity}}{{entity.volumeUnit}} capacity'

  static check(service) {
    return service.volume > service.wagonset.capacity
  }
}

export class NoCompatibilities extends LogMessage {
  static type = 'service-design::No compatible wagons available for demand'

  static message =
    'service-design::{{entity.name}} has no compatible wagons defined.'

  static check(service) {
    return !service.wagoncompatibilities.length
  }
}

export class IncompatibleWagonCargo extends LogMessage {
  static type = 'service-design::Incompatible wagons'

  static message =
    'service-design::[{{invalidAllocationsMessage}}] is incompatible with {{entity.cargoType.name}}'

  static check(service) {
    return Boolean(service.wagonset.invalidAllocations.length)
  }

  constructor(context) {
    super(context, {
      invalidAllocationsMessage: context.entity.wagonset.invalidAllocations
        .map(l => l.wagon.code)
        .join('; '),
    })
  }
}

export default {
  warnings: [
    ...(flags.freightServices
      ? [
          IncompleteRoute,
          IncompatibleOriginCargo,
          IncompatibleDestinationCargo,
          InsufficientWagons,
          IncompatibleWagonCargo,
          NoCompatibilities,
        ]
      : []),
  ],
}
