/* Can we use some sort of Counter abstraction?
   This counts the number of items of each distinct type in array arr,
   returning a map of item to count */
export const count = <T>(arr: T[], multiplier: number = 1) =>
  new Map(
    [...new Set(arr)].map(x => [
      x,
      arr.filter(y => y === x).length * multiplier,
    ]),
  )

/* This sums the count values for each shared key from two maps
   (as returned from counts above) */
export const sumCounts = <T>(
  c1: Map<T, number>,
  c2: Map<T, number>,
): Map<T, number> => {
  const sums = new Map(c1)
  for (const [key, c] of c2) {
    sums.set(key, (c1.get(key) || 0) + (c || 0))
  }
  return sums
}

export const minCounts = <T>(c1: Map<T, number>, c2: Map<T, number>) => {
  const mins = new Map(c1)
  for (const [key, x2] of c2) {
    const x1 = mins.get(key)
    mins.set(key, Math.min(x1 !== undefined ? x1 : Infinity, x2))
  }
  return mins
}

export const round = (number: number, decimals: number) =>
  parseFloat(number.toFixed(decimals))

export const trim = (numStr: string) => {
  let ret = numStr
  while (ret.includes('.') && ret[ret.length - 1] === '0') {
    ret = parseFloat(ret).toFixed(ret.split('.')[1].length - 1)
  }

  return ret
}

export const compareMaps = (map1, map2) => {
  if (map1.size !== map2.size) {
    return false
  }
  for (const [key, value] of map1) {
    const value2 = map2.get(key)
    if (value2 !== value || (value2 === undefined && !map2.has(key))) {
      return false
    }
  }
  return true
}
