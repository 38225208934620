import * as constants from 'src/service-design/shared/constants'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'

export class Detach<T extends IStartLeg> extends LegTask<T> {
  /**
   * Detach is a LegTask that occurs immediately after a TrainStart
   * arrives at a location and comes to a complete stop (ie PostArrival has
   * completed). During this task shunting work is done to decouple Services
   * and Wagons from the TrainStart.
   */

  static arrivingTask = true

  static kind = 'detach'

  static requiresRailOperator = true

  static timeOffsetAllowed = true

  static build(startLeg: StartLeg) {
    return new this({
      origin: startLeg.dest,
      destination: startLeg.dest,
      startTimeLocal: startLeg.detachStartLocal,
      endTimeLocal: startLeg.detachEndLocal,
      startLeg,
      externals: startLeg.externals,
    })
  }

  get resourceDict() {
    return this.startLeg.resourceDict
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts(
      this.duration,
      this.startLeg.consist.workingDict,
      this.startLeg.wagonDict,
    )
  }

  get totalHauledSecs() {
    return mergeMultiplyCounts(this.duration, this.startLeg.consist.hauledDict)
  }

  get costs() {
    return this.startLeg.consist.workingLocos.reduce(
      (acc, l) =>
        acc.concat([
          {
            costType: constants.COST_FUEL_LOCAL,
            entity: `${l.name} detachments on ${this.startLeg.name}`,
            rate: this.startLeg.singletons.costs.fuel,
            quantity:
              (this.duration / constants.SECONDS_PER_HOUR) *
              l.workingHourFuelBurn,
          },
          {
            costType: constants.COST_WORKING_HOUR,
            entity: `${l.name} detachments on ${this.startLeg.name}`,
            rate: l.workingHourCost,
            quantity: this.duration / constants.SECONDS_PER_HOUR,
          },
        ]),
      [],
    )
  }
}
