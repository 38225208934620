import { createSelector } from 'reselect'

import { flags } from 'src/service-design/sd-plan/flags'
import { getUserPreferences } from 'src/service-design/sd-plan/selectors/document-graph'
import {
  getCorridors,
  getLocations,
} from 'src/service-design/sd-plan/selectors/scenario'
import { SECONDS_PER_WEEK } from 'src/service-design/shared/constants'
import { Location } from 'src/service-design/shared/models/location'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { OffsetLeg } from 'src/service-design/shared/models/offset-leg'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import { EpochTime } from 'src/service-design/shared/utils/dates'

const isSameDirection = (leadingLeg: OffsetLeg, followingLeg: OffsetLeg) =>
  leadingLeg.startLeg.forward === followingLeg.startLeg.forward

const offsetForCycle0 = (startLeg: StartLeg.StartLeg): number =>
  /**
   * TODO: This is the sort of thing that our ValueObjects ought to help us
   * with. Can we better represent this function using our value objects
   * or are there other ways of implementing the conflict detection
   * algorithm which take advantage of our existing API?
   */
  startLeg.departsLocal.toSeconds() -
  startLeg.departsLocal.asCyclicTime().toSeconds()

const followingDepartureEarlierThanLeadingDeparture = (
  leadingLeg,
  followingLeg,
) =>
  leadingLeg.departsLocal.toSeconds() >
  followingLeg.departsLocal.toSeconds() - followingLeg.startLeg.headwaySecs
const followingArrivalEarlierThanLeadingArrival = (
  leadingLeg: OffsetLeg,
  followingLeg: OffsetLeg,
) =>
  leadingLeg.arrivesLocal.toSeconds() >
  followingLeg.arrivesLocal.toSeconds() - followingLeg.startLeg.headwaySecs
const followingArrivalEarlierThanLeadingDeparture = (
  leadingLeg: OffsetLeg,
  followingLeg: OffsetLeg,
) => {
  const trainLengthExceeds =
    leadingLeg.startLeg.length > leadingLeg.startLeg.dest.maxTrainLength
  const leadingDetachEndOrDeparture = leadingLeg.startLeg.terminating
    ? leadingLeg.detachEndLocal
    : leadingLeg.nextDepartsLocal
  return (
    trainLengthExceeds &&
    leadingDetachEndOrDeparture >
      followingLeg.arrivesLocal.toSeconds() - followingLeg.startLeg.headwaySecs
  )
}

export class Headway extends LogMessage {
  static type = 'service-design::Insufficient Headway Between Trains'

  static message = 'service-design::{{train}} is too close to train {{others}}'

  static check(leadingLeg: OffsetLeg, followingLeg: OffsetLeg) {
    return (
      isSameDirection(leadingLeg, followingLeg) &&
      (followingDepartureEarlierThanLeadingDeparture(
        leadingLeg,
        followingLeg,
      ) ||
        followingArrivalEarlierThanLeadingArrival(leadingLeg, followingLeg) ||
        followingArrivalEarlierThanLeadingDeparture(leadingLeg, followingLeg))
    )
  }

  others: any[] = []
  constructor(public leg) {
    super({ leg })
    this.leg = leg
  }

  push(leg) {
    if ((this.context as any).leg !== leg && !this.others.includes(leg)) {
      this.others.push(leg)
    }
    return this
  }

  get i18nMessage(): [string, object] {
    const start = this.leg.start.name
    const others = this.others.map(o => o.start.name).join(', ')
    return [
      this.message,
      {
        train: start,
        others,
        count: others.length,
      },
    ]
  }
}

export class OpposingTrainsOnCorridor extends LogMessage {
  static type = 'service-design::Opposing Trains on Corridor'

  static message =
    'service-design::Train {{trainA}} opposes {{trainB}} on {{corridor}}'

  static check(leadingLeg, followingLeg) {
    if (isSameDirection(leadingLeg, followingLeg)) {
      return false
    }

    let leadingClear = leadingLeg.arrivesLocal.toSeconds()
    if (leadingLeg.startLeg.length > leadingLeg.startLeg.dest.maxTrainLength) {
      leadingClear = leadingLeg.startLeg.terminating
        ? leadingLeg.detachEndLocal
        : leadingLeg.nextDepartsLocal
    }
    return (
      followingLeg.departsLocal.toSeconds() <
      leadingClear + leadingLeg.startLeg.clearanceSecs
    )
  }

  leg: any

  constructor(legA, legB) {
    super(
      { legA, legB },
      {
        trainA: legA.start.name,
        trainB: legB.start.name,
        corridor: legA.corridor.name,
      },
    )
    this.leg = legA
  }
}

export class TrainCapacityExceededOnLocation extends LogMessage {
  static type = 'service-design::Train Capacity Exceeded on Location'

  static message =
    'service-design::Location: {{location.code}} has too many trains: [{{others}}]'

  location: Location
  locationEvents: any
  leg: any

  constructor(location, locationEvents) {
    const others = [
      ...new Set(locationEvents.map(event => event.leg.start.name)),
    ].join(', ')

    const { leg } = locationEvents[0]
    super({ location, leg }, { others })
    this.location = location
    this.locationEvents = locationEvents
    this.leg = leg
  }

  get startTime() {
    return this.locationEvents[0].timestamp
  }

  get endTime() {
    return this.locationEvents[this.locationEvents.length - 1].timestamp
  }
}

export const getLegConflictWarnings = createSelector(
  StartLeg.values,
  getCorridors,
  getUserPreferences,
  (startLegs, corridors, preferences) => {
    if (!flags.conflicts) {
      return []
    }

    const warningClasses = [OpposingTrainsOnCorridor, Headway].filter(
      cls => !preferences.warningsBlacklist.includes(cls.type),
    )
    const warnings = new Map(warningClasses.map(cls => [cls, {}]))

    const createWarnings = (Cls, leadingLeg, followingLeg) => {
      const typeWarnings = warnings.get(Cls)
      if (typeWarnings) {
        if (Cls === OpposingTrainsOnCorridor) {
          typeWarnings[followingLeg.id] = new Cls(
            followingLeg.startLeg,
            leadingLeg.startLeg,
          )
          typeWarnings[leadingLeg.id] = new Cls(
            leadingLeg.startLeg,
            followingLeg.startLeg,
          )
        } else if (Cls === Headway) {
          typeWarnings[followingLeg.id] = (
            typeWarnings[followingLeg.id] || new Cls(followingLeg.startLeg)
          ).push(leadingLeg.startLeg)
        }
      }
    }

    corridors.forEach(corridor => {
      /**
       * Warnings are determined by doing pair-wise checks of the form
       * (leadingLeg, followingLeg) where leadingLeg has always departed earlier
       * than following leg.
       *
       * Note that due to week wrapping a leg that departs later in the week can
       * conflict with one that departs very early in the week. To deal with this
       * We take a copy of each leg and shift it so that it departs within
       * 'week 0' and then repeat everything again in 'week 1'.
       */
      const offsetLegs = startLegs
        .filter(leg => leg.corridor === corridor)
        .map(
          startLeg =>
            new OffsetLeg({ startLeg, offset: -offsetForCycle0(startLeg) }),
        )
        .sort((l1, l2) =>
          EpochTime.sortComparator(l1.departsLocal, l2.departsLocal),
        )

      const repeated = [
        ...offsetLegs,
        ...offsetLegs.map(offsetLeg => offsetLeg.getNextWeek()),
      ]

      /**
       * Since we've just repeated the same legs twice every legs is guaranteed
       * to have been in the first half of the list.
       */
      for (let i = 0; i < repeated.length / 2; i += 1) {
        const leadingLeg = repeated[i]

        // Along consider followingLegs that depart after this leadingLeg.
        for (let j = i + 1; j < repeated.length; j += 1) {
          const followingLeg = repeated[j]
          if (followingLeg.id !== leadingLeg.id) {
            ;[...warnings.keys()]
              .filter(c => c.check(leadingLeg, followingLeg))
              .forEach(c => createWarnings(c, leadingLeg, followingLeg))
          }
        }
      }
    })

    return [...warnings.values()].flatMap(c => Object.values(c))
  },
)

const type2sign = {
  originating: +1,
  arrival: +1,
  departure: -1,
  terminating: -1,
}

export class LocationEvent {
  constructor(public timestamp, public type, public leg) {}

  get sign() {
    return type2sign[this.type]
  }

  static buildEvents(startLegs, locId) {
    const departingLegs = startLegs.filter(leg => leg.origin.id === locId)
    const arrivingLegs = startLegs.filter(leg => leg.dest.id === locId)

    /**
     * TODO: this code looks very suspect. It appears to assume that every
     * startLeg.departsLocal is going to be in 'week 0' which is not true.
     * departsLocal is completely unbounded and this is likely to have a pretty
     * big impact on the way these calculations play out.
     */
    const departingOffsetLegs = [
      ...departingLegs.map(startLeg => new OffsetLeg({ startLeg, offset: 0 })),
      ...departingLegs.map(
        startLeg => new OffsetLeg({ startLeg, offset: SECONDS_PER_WEEK }),
      ),
    ]

    const arrivingOffsetLegs = [
      ...arrivingLegs.map(startLeg => new OffsetLeg({ startLeg, offset: 0 })),
      ...arrivingLegs.map(
        startLeg => new OffsetLeg({ startLeg, offset: SECONDS_PER_WEEK }),
      ),
    ]

    const departingEvents = departingOffsetLegs.reduce((coll, offsetLeg) => {
      coll.push(
        new LocationEvent(
          offsetLeg.departsLocal.toSeconds() + offsetLeg.startLeg.clearanceSecs,
          'departure',
          offsetLeg.startLeg,
        ),
      )
      if (offsetLeg.startLeg.originating) {
        coll.push(
          new LocationEvent(
            offsetLeg.attachStartLocal,
            'originating',
            offsetLeg.startLeg,
          ),
        )
      }
      return coll
    }, [])

    const arrivingEvents = arrivingOffsetLegs.reduce((coll, offsetLeg) => {
      coll.push(
        new LocationEvent(
          offsetLeg.arrivesLocal.toSeconds(),
          'arrival',
          offsetLeg.startLeg,
        ),
      )
      if (offsetLeg.startLeg.terminating) {
        coll.push(
          new LocationEvent(
            offsetLeg.detachEndLocal,
            'terminating',
            offsetLeg.startLeg,
          ),
        )
      }
      return coll
    }, [])

    return this.sort([...departingEvents, ...arrivingEvents])
  }

  static sort(locationEvents) {
    return locationEvents.sort((a, b) => {
      if (a.timestamp === b.timestamp) {
        if (a.sign === b.sign) {
          return a.leg.id.localeCompare(b.leg.id)
        }
        // When events are happening at the same time
        // we want arrivals to happen prior to departures
        // (see BOSS-1975) otherwise the location balance
        // can get confused the think the trains are dwelling
        // at the location all week.
        return b.sign - a.sign
      }
      return a.timestamp - b.timestamp
    })
  }
}

export const getLocationConflictWarnings = createSelector(
  getLocations,
  StartLeg.values,
  getUserPreferences,
  (locations, startlegs, preferences) => {
    if (!flags.conflicts) {
      return []
    }
    const locTypeWarnings = []

    if (
      preferences.warningsBlacklist.includes(
        TrainCapacityExceededOnLocation.type,
      )
    ) {
      return locTypeWarnings
    }

    for (const [locId, loc] of locations) {
      const locationEvents = LocationEvent.buildEvents(startlegs, locId)

      let minOccupancy = 0
      let occupancyCount = 0

      locationEvents.forEach(event => {
        occupancyCount += event.sign
        minOccupancy = Math.min(occupancyCount, minOccupancy)
      })
      occupancyCount = minOccupancy * -1

      let accumEvents = []

      locationEvents.forEach(event => {
        occupancyCount += event.sign

        if (occupancyCount > loc.maxOccupyingTrains) {
          accumEvents.push(event)
        } else if (
          accumEvents.length &&
          occupancyCount <= loc.maxOccupyingTrains
        ) {
          accumEvents.push(event)

          const isDuplicate = locTypeWarnings.find(
            warning =>
              warning.location.id === locId &&
              warning.locationEvents.every(locationEvent =>
                accumEvents
                  .map(accumEvent => accumEvent.leg.id)
                  .includes(locationEvent.leg.id),
              ),
          )

          if (!isDuplicate) {
            locTypeWarnings.push(
              new TrainCapacityExceededOnLocation(loc, accumEvents),
            )
          }

          accumEvents = []
        }
      })
    }
    return locTypeWarnings
  },
)

export const getConflictWarnings = createSelector(
  getLegConflictWarnings,
  getLocationConflictWarnings,
  (legConflicts, locationConflicts) => [...legConflicts, ...locationConflicts],
)
