import { createSelector, createStructuredSelector } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import {
  ScenarioDocument,
  ScenarioCollections,
  ScenarioSingletons,
} from 'src/service-design/scenario/document/types'
// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import {
  ServiceDesignDocument,
  ServiceDesignCollections,
  ServiceDesignSingletons,
} from 'src/service-design/sd-plan/document/types'

// TODO: These two types are pretty concerning as it implies that
// every repo / factory must depend on precisely the same state.
// This means that if you want a model that depends on fatigue scores (for
// example) then all the models will rely on it.
export type CollectionData = ServiceDesignCollections & ScenarioCollections
export type DocumentData = CollectionData & {
  singletons: ServiceDesignSingletons & ScenarioSingletons
}

export type FactoryInput = {
  collectionData: DocumentData
  externals: any
}

type RootState = {
  userPreferences: any
  fatigue: any
  documents: {
    scenario: {
      data: ScenarioDocument
    }
    'service-design': {
      data: ServiceDesignDocument
    }
  }
}

const EMPTY = { singletons: null }
export const getUserPreferences = (state: RootState) => state.userPreferences
export const getFatigueScores = (state: RootState) =>
  state.fatigue ? state.fatigue.shiftlines : EMPTY

export const getExternals = createStructuredSelector({
  preferences: getUserPreferences,
  fatigue: getFatigueScores,
})

export const getScenarioData = (
  state: RootState,
): ScenarioDocument | { singletons: null } =>
  state.documents.hasOwnProperty('scenario')
    ? state.documents.scenario.data
    : EMPTY

const getServiceDesignData = (
  state: RootState,
): ServiceDesignDocument | { singletons: null } =>
  state.documents.hasOwnProperty('service-design')
    ? state.documents['service-design'].data
    : EMPTY

export const getCollectionData = createSelector(
  getScenarioData,
  getServiceDesignData,
  (scenario, plan) => ({
    ...scenario,
    ...plan,
    singletons: { ...scenario.singletons, ...plan.singletons },
  }),
)

// TODO this currently gets all the things and is used in scenario and service-design
// TODO externals needs to be dealt with at some point
export const getFactoryInput = createStructuredSelector<
  RootState,
  {
    collectionData: ReturnType<typeof getCollectionData>
    externals: ReturnType<typeof getExternals>
  }
>({
  collectionData: getCollectionData,
  externals: getExternals,
})

export type FactoryInputParameters = Parameters<typeof getFactoryInput>[0]
