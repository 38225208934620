import { flags } from './flags'

export const BASE_DATE = '2017-01-01T00:00Z'
export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const DAY_LETTERS = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

export const LONG_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const PENALTY_NORMAL_WEEKDAY = 1
export const PENALTY_OUTSIDE_WEEKDAY = 1.18
export const PENALTY_SATURDAY = 1.5
export const PENALTY_SUNDAY = 2

export const DAYS_IN_WEEK = DAYS.length
export const SECONDS_PER_MINUTE = 60
export const MINUTES_PER_HOUR = 60
export const SECONDS_PER_HOUR = MINUTES_PER_HOUR * SECONDS_PER_MINUTE
export const HOURS_PER_DAY = 24
export const WEEKS_PER_YEAR = 52
export const HOURS_PER_WEEK = HOURS_PER_DAY * DAYS_IN_WEEK
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * HOURS_PER_DAY
export const SECONDS_PER_DAY = HOURS_PER_DAY * SECONDS_PER_HOUR
export const SECONDS_PER_WEEK = DAYS_IN_WEEK * SECONDS_PER_DAY
export const SECONDS_PER_ROSTER = SECONDS_PER_WEEK

export const ACCORDION_PANES_SAVE = 'ACCORDION_PANES_SAVE'

export const CONFIG_RECEIVE = 'CONFIG_RECEIVE'
export const CURRENT_USER_RECEIVE = 'CURRENT_USER_RECEIVE'

export const DOCUMENT_ERRORS_UPDATE = 'DOCUMENT_ERRORS_UPDATE'

export const INSTANCE_ADD = 'INSTANCE_ADD'
export const INSTANCE_EDIT = 'INSTANCE_EDIT'
export const INSTANCE_PATCH = 'INSTANCE_PATCH'
export const BATCH_PATCH = 'BATCH_PATCH'
export const SINGLETON_EDIT = 'SINGLETON_EDIT'
export const INSTANCE_DELETE = 'INSTANCE_DELETE'
export const USER_PREFERENCES_EDIT = 'USER_PREFERENCES_EDIT'
export const LOAD_PREFERENCES = 'LOAD_PREFERENCES'

export const LINKED_INSTANCE_INSERT = 'LINKED_INSTANCE_INSERT'
export const LINKED_INSTANCE_DELETE = 'LINKED_INSTANCE_DELETE'

export const COLLECTION_REPLACE = 'COLLECTION_REPLACE'

export const LOCK_TRAIN = 'Train Lock'
export const LOCK_WORKING_LOCO = 'Working Loco Lock'

export const NOTIFICATION_POST = 'NOTIFICATION_POST'
export const NOTIFICATION_DISMISS = 'NOTIFICATION_DISMISS'

export const MODAL_SHOW = 'MODAL_SHOW'
export const MODAL_DISMISS = 'MODAL_DISMISS'
// modals
export const MODAL_BLOCKING_ERROR = 'MODAL_BLOCKING_ERROR'
export const MODAL_ACKABLE_ERROR = 'MODAL_ACKABLE_ERROR'
export const MODAL_DOCUMENT_ARCHIVE = 'MODAL_DOCUMENT_ARCHIVE'
export const MODAL_DOCUMENT_COPY = 'MODAL_DOCUMENT_COPY'
export const MODAL_DOCUMENT_RENAME = 'MODAL_DOCUMENT_RENAME'
export const MODAL_DOCUMENT_UNARCHIVE = 'MODAL_DOCUMENT_UNARCHIVE'
export const MODAL_ERROR = 'MODAL_ERROR'

export const DOCUMENT_RECEIVE = 'DOCUMENT_RECEIVE'
export const DOCUMENT_UPDATED = 'DOCUMENT_UPDATED'
export const DOCUMENT_LOADED = 'DOCUMENT_LOADED'
export const DOCUMENT_HAS_ERRORS = 'DOCUMENT_HAS_ERRORS'
export const DOCUMENT_CLEAR_ERRORS = 'DOCUMENT_CLEAR_ERRORS'
export const DOCUMENT_REMOVE = 'DOCUMENT_REMOVE'
export const DOCUMENT_RENAME = 'DOCUMENT_RENAME'
export const DOCUMENT_UNARCHIVE = 'DOCUMENT_UNARCHIVE'

export const DOCUMENTS_LOADING = 'DOCUMENTS_LOADING'
export const DOCUMENTS_RECEIVE = 'DOCUMENTS_RECEIVE'
export const DOCUMENTS_LOADING_FAILED = 'DOCUMENTS_LOADING_FAILED'

export const REVISIONS_RECEIVE = 'REVISIONS_RECEIVE'
export const REVISION_SAVING = 'REVISION_SAVING'
export const REVISION_SAVED = 'REVISION_SAVED'
export const REVISION_SAVE_FAILED = 'REVISION_SAVE_FAILED'
export const REVISION_SAVE_STARTED = 'REVISION_SAVE_STARTED'
export const REVISION_SAVE_STOPPED = 'REVISION_SAVE_STOPPED'

// inline editing action types
export const ACTIVATE_INLINE_FIELD = 'ACTIVATE_INLINE_FIELD'
export const DEACTIVATE_INLINE_FIELD = 'DEACTIVATE_INLINE_FIELD'

export const SAVE_INTERVAL = 5000
export const SAVE_RETRY_ATTEMPTS = 5
export const SAVE_DEFAULT_ERROR = '[ERR:DEFAULT_SAVE]'
export const SAVE_CONNECTION_ERROR = '[ERR:CONNECTION_ERROR]'
export const SAVE_CONNECTION_ERROR_STATUS = 0

export const FAID_CONNECTION_ERROR = '[ERR:CONNECTION_ERROR_FAID]'

export const COST_ACCESS = 'Access - Base'
export const COST_TONNAGE = 'Access - Train Weight-distance'
export const COST_LOCO_FLEET = 'Locomotives - Fleet'
export const COST_WAGON_FLEET = 'Wagons - Fleet'
export const COST_LV_FLEET = 'LVs - Fleet'
export const COST_LV_KM = 'LVs - Distance'
export const COST_LV_FUEL = 'LVs - Fuel'
export const COST_WORKING_KM = 'Locomotives - Mainline Working Distance'
export const COST_WORKING_HOUR = 'Locomotives - Yard Working Time'
export const COST_FUEL_MAINLINE = 'Locomotives - Mainline Working Fuel'
export const COST_FUEL_LOCAL = 'Locomotives - Yard Working Fuel'
export const COST_WAGON_KM = 'Wagons - Distance'
export const COST_CREW_PRODUCTIVE = 'Crew - Productive'
export const COST_CREW_NONPRODUCTIVE = 'Crew - Non-productive'
export const COST_CREW_UNDERTIME = 'Crew - Undertime'
export const COST_CREW_RELIEF = 'Crew - Relief'

export const COST_FLEET = {
  Lococlass: COST_LOCO_FLEET,
  Wagon: COST_WAGON_FLEET,
  ...(flags.crewing ? { LV: COST_LV_FLEET } : {}),
}

export const COST_BREAKDOWN = [
  COST_ACCESS,
  COST_TONNAGE,
  COST_LOCO_FLEET,
  COST_WAGON_FLEET,
  ...(flags.crewing ? [COST_LV_FLEET] : []),
  COST_WORKING_KM,
  COST_WORKING_HOUR,
  COST_FUEL_MAINLINE,
  COST_FUEL_LOCAL,
  COST_WAGON_KM,
  ...(flags.crewing ? [COST_LV_KM, COST_LV_FUEL] : []),
  ...(flags.crewing
    ? [
        COST_CREW_PRODUCTIVE,
        COST_CREW_NONPRODUCTIVE,
        COST_CREW_UNDERTIME,
        COST_CREW_RELIEF,
      ]
    : []),
]

export const KEYCODE_ENTER = 13
