import { getCollection } from 'src/service-design/sd-plan/selectors/base'

export const getRawWagonSets = state =>
  getCollection(state, 'service-design', 'wagonsets')

export const getRawEmptyWagonEvents = state =>
  getCollection(state, 'service-design', 'emptywagonevents')

export const getRawWagonAllocations = state =>
  getCollection(state, 'service-design', 'wagonallocations')
