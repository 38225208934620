import { CustomTask } from 'src/service-design/shared/models/custom-task'
import {
  ShiftAssignment,
  Attrs as BaseAttrs,
  Rels as BaseRels,
} from 'src/service-design/shared/models/shift-assignment/base'
import validators from 'src/service-design/shared/models/shift-assignment/validator'

interface Attrs {
  customTaskId: string
}

interface Rels {
  customTask: CustomTask
}

class CustomTaskAssignment extends ShiftAssignment {
  static validators = validators
  static collection = 'customtaskassignments'

  /**
   * A CustomTaskAssignment associates a CustomTask to a Shift.
   *
   * Related models:
   * - `CustomTaskAssignment`;
   * - `ShiftAssignment`;
   * - `CustomTask`;
   * - `Shift`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} customTaskId - The id of the CustomTask.
   * @param {string} shiftId - The id of the Shift.
   **/
  constructor({ customTaskId, ...rest }: Attrs & BaseAttrs) {
    super(rest)
    this.customTaskId = customTaskId
  }

  setRels({ customTask, ...rest }: Rels & BaseRels) {
    this.customTask = customTask
    super.setRels(rest)
  }

  get name() {
    return this.task.name
  }

  get task() {
    return this.customTask
  }

  get startLocation() {
    return this.task.startLocation
  }

  get endLocation() {
    return this.task.endLocation
  }
}

interface CustomTaskAssignment extends Attrs, Rels {}
export { CustomTaskAssignment }
