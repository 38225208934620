import { FactoryInput } from 'src/service-design/shared/document-factory'
import { createFactoryFromDocument } from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { TrainGraph } from './model'

export const trainGraphFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.traingraphs.map(data => {
      const item = new TrainGraph(data)
      item.setExternals(externals)
      return item
    }),
)
