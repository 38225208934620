import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import repairs from 'src/service-design/sd-plan/document/repairs'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { serviceFactory } from './factory'
import { getServiceRepairs } from './repairs'
import { IServiceRepo, ServiceRepo } from './repo'

const serviceRepo = defaultMemoize(
  (input: FactoryInput) => new ServiceRepo(serviceFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  serviceRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type ServiceRepoReg = {
  serviceRepo: FromDocumentFactory<FactoryInput, IServiceRepo>
}

export function setup<R extends ServiceRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('serviceRepo', serviceRepo)

  relationships.addConstraints('services', {
    rels: [
      {
        collection: 'locations',
        foreign: 'originId',
        name: 'origin',
      },
      {
        collection: 'locations',
        foreign: 'destinationId',
        name: 'destination',
      },
      {
        collection: 'cargotypes',
        foreign: 'cargoTypeId',
        name: 'cargoType',
      },
    ],
  })

  repairs.register(createSelector(values, getServiceRepairs))
}

export { Service } from './model'
