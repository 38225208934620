import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { durationString } from 'src/service-design/shared/utils/dates'

export class NoMatchingLVTrip extends LogMessage {
  static type = 'service-design::No matching LV trip defined'

  static message =
    'service-design::There is no LV trip for {{ entity.origin.code }} to {{ entity.destination.code }}'

  static check(lvTask) {
    return !lvTask.lvTrip
  }
}

export class AllocatedTimeNotEnough extends LogMessage {
  static type = 'service-design::Insufficient time for LV trip'

  static message =
    'service-design::{{ entity.name }} requires {{ lvTripDuration }}, only {{ availableDuration }} is available'

  static check(lvTask) {
    if (lvTask.lvTrip) {
      return lvTask.duration - lvTask.lvTrip.durationSecs < 0
    }
    return false
  }

  constructor(context) {
    super(context, {
      lvTripDuration: durationString(context.entity.lvTrip.durationSecs),
      availableDuration: durationString(context.entity.duration),
    })
  }
}

export default {
  warnings: flags.crewing ? [NoMatchingLVTrip, AllocatedTimeNotEnough] : [],
}
