import { workSheets as worksheets } from 'src/service-design/scenario/worksheets'
import { DocumentSpec } from 'src/service-design/shared/document/types'

import blankDocument from './blank'
import relationships from './relationships'
import repairs from './repairs'
import schema from './schema.json'
import { ScenarioDocument } from './types'

export const blank: ScenarioDocument = blankDocument

export const documentSpec: DocumentSpec<'scenario'> = {
  kind: 'scenario',
  currentVersion: '1.57',
  displayName: 'Scenario',
  schema,
  relationships,
  repairs,
  worksheets,
  blank,
}
