import { CrewPool } from 'src/service-design/shared/models/crew-pool'
import { RemoteRest } from 'src/service-design/shared/models/remote-rest'
import { Shift } from 'src/service-design/shared/models/shift'
import {
  durationString,
  normalize,
  Duration,
  EpochTime,
  Interval,
  Delta,
  WEEK_DELTA,
} from 'src/service-design/shared/utils/dates'

type Attrs = {
  poolId: string
  shiftIds: string[]
  remoteRestIds: string[]
}

type Rels = {
  pool: CrewPool
  shifts: Shift[]
  remoteRests: RemoteRest[]
}

export class CompoundShift {
  id: string
  poolId: string
  shiftIds: string[]
  remoteRestIds: string[]
  pool: CrewPool
  shifts: Shift[]
  remoteRests: RemoteRest[]

  /**
   * A CompoundShift is a view over Shifts and RemoteRests which comprise
   * a unit of Shift work for a crew.
   *
   * There may be a single Shift in the compound, or there may be multiple
   * Shifts joined by RemoteRests.
   *
   * Related models:
   * - `Shift`;
   * - `RemoteRest`;
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} shiftIds - The ids of the Shifts.
   * @param {string} remoteRestIds - The ids of the RemoteRests.
   **/
  constructor({ poolId, shiftIds = [], remoteRestIds = [] }: Attrs) {
    this.id = shiftIds.join(':')
    this.poolId = poolId
    this.shiftIds = shiftIds
    this.remoteRestIds = remoteRestIds
  }

  setRels({ pool, shifts, remoteRests }: Rels) {
    this.pool = pool
    this.shifts = shifts
    this.remoteRests = remoteRests
  }

  get isAssigned(): boolean {
    return this.shifts[0].isAssigned
  }

  /**
   * @deprecated
   */
  get minHomeRestSecs(): number {
    return this.minHomeRestSecsVO.toSeconds()
  }

  get minHomeRestSecsVO(): Duration {
    return this.pool.type.minHomeRestSecsVO
  }

  /**
   * @deprecated
   */
  get signOnLocal(): number {
    return this.signOnLocalVO.toSeconds()
  }

  get signOnLocalVO(): EpochTime {
    return this.shifts[0].signOnLocalVO
  }

  /**
   * @deprecated
   */
  get signOffLocal(): number {
    return this.signOffLocalVO.toSeconds()
  }

  get signOffLocalVO(): EpochTime {
    const last = this.shifts[this.shifts.length - 1]
    return last.signOffLocalVO.makeLater(this.offsetForVO(last))
  }

  /**
   * @deprecated
   */
  get startTimeLocal(): number {
    return this.startTimeLocalVO.toSeconds()
  }

  get startTimeLocalVO(): EpochTime {
    return this.signOnLocalVO
  }

  /**
   * @deprecated
   */
  get endTimeLocal(): number {
    return this.endTimeLocalVO.toSeconds()
  }

  get endTimeLocalVO(): EpochTime {
    return this.signOffLocalVO
  }

  /**
   * @deprecated
   */
  get duration(): number {
    return this.durationVO.toSeconds()
  }

  get durationVO(): Duration {
    return Interval.fromEpochTimes(
      this.signOnLocalVO,
      this.signOffLocalVO,
    ).duration()
  }

  get durationString(): string {
    return durationString(this.durationNormalized.toSeconds())
  }

  /**
   * @deprecated
   * TODO: This should not exist, instead offsetFor should be using snapTo
   */
  get durationNormalized(): Duration {
    return Duration.fromSeconds(normalize(this.duration))
  }

  /**
   *
   * @deprecated
   */
  offsetFor(shift: Shift): number {
    return this.offsetForVO(shift).toSeconds()
  }

  offsetForVO(shift: Shift): Delta {
    // given a shift, determine its time offset to account for week boundaries crossed
    let signOn = EpochTime.epoch
    let week = 0
    for (const cur of this.shifts) {
      if (shift.signOnLocalVO.isStrictlyEarlier(signOn)) {
        week += 1
      }
      if (cur === shift) {
        return WEEK_DELTA.multiply(week)
      }
      signOn = cur.signOnLocalVO
    }
    return Delta.nil
  }
}
