import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { Lococlass } from './model'

export const lococlassFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.lococlasses.map(data => {
      const item = new Lococlass(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((lococlass: Lococlass, state: any) => ({
    availability: registry
      .provide('resourceAvailabilityRepo')(state)
      .byLococlassId(lococlass.id),
    loadCategoryObj: registry
      .provide('loadCategoryRepo')(state)
      .byId(lococlass.loadCategory),
    accessrights: registry
      .provide('lococlassAccessRightRepo')(state)
      .byLococlassId(lococlass.id),
  })),
)
