import get from 'get-or-else'
import React from 'react'
import { connect } from 'react-redux'
import { Dropdown, Icon } from 'semantic-ui-react'

import styled from 'styled-components'

import { logout as logoutAction } from 'src/core/auth'
import i18n from 'src/i18n'
import * as constants from 'src/service-design/shared/constants'
import { documentUpdate } from 'src/service-design/shared/document/actions'
import { getFinderUrl } from 'src/service-design/shared/document/selectors'
import { documentModified } from 'src/service-design/shared/document/selectors/save'
import { modalShow } from 'src/service-design/shared/forms/actions/modals'
import { BuildInfoBackend } from 'src/service-design/shared/ui/components/BuildInfo'
import DocumentActions from 'src/service-design/shared/ui/components/DocumentActions'

import { NavBar as NavBarComp } from './component'

const FlagsMenu = React.lazy(
  () =>
    process.env.NODE_ENV === 'development' &&
    import('src/service-design/shared/ui/components/Flags'),
)

const UserDiv = styled.div`
  display: inline-block;
  min-width: 100px;
`

interface UserMenuProps {
  userName?: string
  logout: () => void
}

const UserMenu: React.FC<UserMenuProps> = ({ userName = null, logout }) => (
  <Dropdown
    trigger={
      <UserDiv>
        <Icon name="user circle outline" size="large" />
        {userName}
      </UserDiv>
    }
    inline
    icon={null}
  >
    <Dropdown.Menu>
      <Dropdown.Item icon="log out" text={i18n.t('Logout')} onClick={logout} />
    </Dropdown.Menu>
  </Dropdown>
)

const DocumentDiv = styled.div`
  display: inline-block;
  cursor: pointer;
`

interface NavBarProps {
  documentRoot?: string
  currentLocation?: {
    pathname: string
    search: string
  }
  userName?: string
  buildInfoBackends?: BuildInfoBackend[]
}

const mapStateToProps = (state, { documentRoot }: NavBarProps) => {
  const document = documentRoot && get([state, documentRoot])
  const modified = documentRoot && documentModified(state, documentRoot)
  return {
    document,
    modified,
    finderUrl: getFinderUrl(document, state.router.location),
  }
}

const mapDispatchToProps = (dispatch, { documentRoot }: NavBarProps) => ({
  rename: updated => documentUpdate({ name: updated.name }, documentRoot),
  copy: (initialValues, currentLocation) =>
    dispatch(
      modalShow(constants.MODAL_DOCUMENT_COPY, {
        initialValues,
        currentLocation,
      }),
    ),
  archive: () =>
    dispatch(modalShow(constants.MODAL_DOCUMENT_ARCHIVE, { documentRoot })),
  unarchive: () =>
    dispatch(modalShow(constants.MODAL_DOCUMENT_UNARCHIVE, { documentRoot })),
  logout: () => logoutAction(),
})

const mergeProps = (
  { document, finderUrl, modified },
  { logout, ...dispatchProps },
  ownProps: NavBarProps,
) => ({
  ...ownProps,
  user: <UserMenu {...{ userName: ownProps.userName, logout }} />,
  finderUrl,
  buildInfoBackends: ownProps.buildInfoBackends || [],
  document: document && (
    <DocumentActions
      document={document}
      modified={modified}
      trigger={
        <DocumentDiv>
          {document.meta.archived && <Icon name="archive" size="large" />}
          {document.meta.name}
        </DocumentDiv>
      }
      showClose={false}
      renameDocument={dispatchProps.rename}
      copyDocumentAndOpen={dispatchProps.copy}
      archiveDocument={dispatchProps.archive}
      unarchiveDocument={dispatchProps.unarchive}
    />
  ),
})

export const NavBar = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(NavBarComp)

interface NavBarInstanceProps {
  currentLocation?: any
  userName?: string
  buildInfoBackends?: BuildInfoBackend[]
}

export const navBarFactory = (
  { documentRoot, flags } = {
    documentRoot: null,
    flags: null,
  },
) => {
  const NavBarInstance: React.FC<NavBarInstanceProps> = ({
    currentLocation = {},
    userName = null,
    buildInfoBackends = [],
  }) => (
    <NavBar
      {...{ documentRoot, currentLocation, userName, buildInfoBackends }}
      rhsContent={
        process.env.NODE_ENV === 'development' &&
        flags && (
          <React.Suspense fallback="loading...">
            <FlagsMenu flags={flags} />
          </React.Suspense>
        )
      }
    />
  )

  return NavBarInstance
}
