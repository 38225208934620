import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'semantic-ui-react'

import { FormLabel } from 'src/core/ui/forms'
import i18n from 'src/i18n'

import './index.css'

export const FormFileField = ({
  input,
  type,
  label,
  placeholder,
  meta: { touched, error, warning },
  ...props
}) => {
  function handleChange(e) {
    return input.onChange(e.target.files[0])
  }

  function handleFocus(...args) {
    return input.onFocus(...args)
  }

  function handleBlur(...args) {
    return input.onBlur(...args)
  }

  return (
    <Form.Field className="file-field">
      <FormLabel
        htmlFor={input.name}
        className="upload ui icon button"
        label={i18n.t('Choose File...')}
        touched={touched}
        error={error}
        warning={warning}
      />
      <input
        {...props}
        id={input.name}
        type="file"
        accept=".xls,.xlsx"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ display: 'none' }}
      />
      <span>{input.value ? input.value.name : i18n.t('No file chosen')}</span>
    </Form.Field>
  )
}

FormFileField.defaultProps = {
  input: null,
  label: null,
  type: null,
  placeholder: null,
  meta: null,
  as: null,
}

FormFileField.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object,
  as: PropTypes.func,
}
