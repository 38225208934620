import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { LoadingAssignment } from './model'

export const loadingAssignmentFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.loadingassignments.map(data => {
      const item = new LoadingAssignment(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((loadingAssignment: LoadingAssignment, state: any) => ({
    shift: registry
      .provide('shiftRepo')(state)
      .byId(loadingAssignment.shiftId),
    start: registry
      .provide('trainStartRepo')(state)
      .byId(loadingAssignment.startId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(loadingAssignment.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${loadingAssignment.startId}:${loadingAssignment.templateLegId}`),
    cargoType: registry
      .provide('cargoTypeRepo')(state)
      .byId(loadingAssignment.cargoTypeId),
  })),
)
