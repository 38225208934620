import { Mapper } from 'src/service-design/shared/models/mapper'
import { normalize } from 'src/service-design/shared/utils/dates'

abstract class TemporalMapper extends Mapper {
  /**
   * A mixin that understand how to normalize times.
   */
  abstract get startTimeLocal(): number
  abstract get endTimeLocal(): number

  get startTimeLocalNormalized() {
    return normalize(this.startTimeLocal)
  }

  get endTimeLocalNormalized() {
    return (
      this.endTimeLocal - this.startTimeLocal + this.startTimeLocalNormalized
    )
  }

  get duration() {
    return this.endTimeLocal - this.startTimeLocal
  }
}

export { TemporalMapper }
