import { formValueSelector } from 'redux-form'
import { createSelector } from 'reselect'

import i18n from 'src/i18n'
import { MODAL_CHANGEOVER } from 'src/service-design/sd-plan/constants'
import * as StartLeg from 'src/service-design/shared/models/start-leg'

const formSelector = formValueSelector(MODAL_CHANGEOVER)
const getChangeoverFormValue = state => formSelector(state, 'changeover')

export const getLocationOptionsFromLegs = legs =>
  legs.reduce((coll, leg) => {
    if (!leg.originChangeover) {
      coll.push({
        key: `departing - ${leg.origin.name}`,
        value: { atDeparture: true, leg },
        text: `${i18n.t('service-design::departing')} - ${leg.origin.name}`,
      })
    }
    if (!leg.destChangeover) {
      coll.push({
        key: `arriving - ${leg.dest.name}`,
        value: { atDeparture: false, leg },
        text: `${i18n.t('service-design::arriving')} - ${leg.dest.name}`,
      })
    }
    return coll
  }, [])

export const getLegOptionsByLocation = createSelector(
  getChangeoverFormValue,
  StartLeg.values,
  (formValues, legs) => {
    if (!formValues) {
      return []
    }

    const { leg, atDeparture } = formValues
    const locationType = atDeparture ? 'origin' : 'dest'

    let filteredLegs = []
    if (
      (!leg.originating || !atDeparture) &&
      (!leg.terminating || atDeparture)
    ) {
      filteredLegs = legs.filter(l => {
        const sameLocation = leg[locationType].id === l[locationType].id
        const differentTrains = l.startId !== leg.startId
        const availableForChangeover = !(atDeparture
          ? l.originChangeover
          : l.destChangeover)
        return sameLocation && differentTrains && availableForChangeover
      })
    }
    const blankOption = [
      {
        key: '',
        value: '',
        text: '-',
      },
    ]

    return blankOption.concat(
      filteredLegs.map(l => ({
        key: l.id,
        text: l.name,
        value: l,
      })),
    )
  },
)
