import { capitalize, findLastIndex } from 'lodash'

import i18n from 'src/i18n'
import { Changeover } from 'src/service-design/shared/models/changeover'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { DriverAssignment } from 'src/service-design/shared/models/shift-assignment'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { PreDeparture, LegTask } from 'src/service-design/shared/models/task'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import {
  normalize,
  snapTo,
  penaltyMultiplier,
} from 'src/service-design/shared/utils/dates'

import validators from './validators'

export class DriverTask extends Mapper {
  start: TrainStart
  startChangeover: Changeover
  endChangeover: Changeover
  assignments: DriverAssignment[]
  requiresDriver: boolean
  requiresTravel: boolean

  static validators = validators

  static generateId(
    startId: string,
    startChangeoverId: string,
    endChangeoverId: string,
  ) {
    return `${startId}-${startChangeoverId}-${endChangeoverId}`
  }

  static kind = 'driver-task'

  /**
   * A DriverTask represents a TrainStart's requirement for a driver for a
   * portion of its journey between Changeovers.
   *
   * A DriverTask commences at the beginning of a TrainStart or with a
   * changeover. The task ends with a changeover or at the termination of the
   * TrainStart.
   *
   * Note that DriverTasks are derived from the changeover associated with the
   * TrainStart and are not stored within the document. A DriverTask's identity
   * is uniquely determined by the TrainStart it crews and its changeovers.
   *
   * Related models:
   * - `DriverAssignment`;
   * - `LocationChangeover`;
   * - `Shift`;
   * - `TrainChangeover`';
   * - `TrainStart`.
   *
   * @constructor
   * @param {TrainStart} start - The id of the CustomTask.
   * @param {Changeover|null} startChangeover - The changeover that initiates
   *  the DriverTask.
   * @param {Changeover|null} endChangeover - The changeover that terminates the
   *  DriverTask.
   * @param {DriverAssignment[]} assignment - The shift assignments for this
   *  task.
   * @param {object} externals - The `externals` object used to compute
   *  warnings.
   **/
  constructor({
    start,
    startChangeover,
    endChangeover,
    assignments = [],
    externals,
  }: {
    start: TrainStart
    startChangeover: Changeover
    endChangeover: Changeover
    assignments: DriverAssignment[]
    externals: any
  }) {
    super()
    this.start = start
    this.startChangeover = startChangeover
    this.endChangeover = endChangeover
    this.assignments = assignments
    this.requiresDriver = true
    this.requiresTravel = true
    this.setExternals(externals)
  }

  get kind() {
    return (this.constructor as any).kind
  }

  get id() {
    return DriverTask.generateId(
      this.start.id,
      this.startChangeoverId,
      this.endChangeoverId,
    )
  }

  get name() {
    const baseName = `${capitalize(i18n.t(`service-design::${this.kind}`))} - ${
      this.start.name
    }`

    if (this.startChangeover || this.endChangeover) {
      return `${baseName} - ${this.startLocation.name} to ${this.endLocation.name}`
    }

    return baseName
  }

  get startId() {
    return this.start.id
  }

  get startChangeoverId() {
    if (!this.startChangeover) {
      return null
    }
    return this.startChangeover.id
  }

  get endChangeoverId() {
    if (!this.endChangeover) {
      return null
    }
    return this.endChangeover.id
  }

  get startTimeLocal() {
    if (!this.startChangeover) {
      return this.start.legs[0].getTask('attach').startTimeLocal
    }
    const normalized = this.startChangeover.endTimeLocal
    const near = this.startChangeover
      .legFor(this.start)
      .getTask('pre-departure').startTimeLocal
    return snapTo(normalized, near)
  }

  get endTimeLocal() {
    if (!this.endChangeover) {
      return this.start.lastLeg.getTask('detach').endTimeLocal
    }
    const normalized = this.endChangeover.startTimeLocal
    const near = this.endChangeover.legFor(this.start).getTask('post-arrival')
      .endTimeLocal
    return snapTo(normalized, near)
  }

  get startTimeLocalNormalized() {
    return normalize(this.startTimeLocal)
  }

  get endTimeLocalNormalized() {
    return (
      this.endTimeLocal - this.startTimeLocal + this.startTimeLocalNormalized
    )
  }

  get startLocation() {
    if (!this.startChangeover) {
      return this.start.legs[0].origin
    }
    return this.startChangeover.location
  }

  get endLocation() {
    if (!this.endChangeover) {
      return this.start.lastLeg.dest
    }
    return this.endChangeover.location
  }

  get duration() {
    return this.endTimeLocal - this.startTimeLocal
  }

  get desc() {
    const start = this.startChangeover
      ? this.startChangeover.desc
      : `orig ${this.startLocation.code}`
    const end = this.endChangeover
      ? this.endChangeover.desc
      : `term ${this.endLocation.code}`
    return `${start} – ${end}`
  }

  get isAssigned() {
    return Boolean(this.assignments.length)
  }

  get legs(): StartLeg[] {
    let startIndex: number = 0
    if (this.startChangeover) {
      // If the changeover happens at the start of a leg (at departure), we want to include that leg
      // otherwise it occurs at the end of the leg and we should skip it
      const changeoverLeg = this.startChangeover.legFor(this.start)
      const index = this.start.legs.indexOf(changeoverLeg)
      if (this.startChangeover.atDeparture) {
        startIndex = index
      } else {
        startIndex = index + 1
      }
    }

    let endIndex: number = this.start.legs.length
    if (this.endChangeover) {
      const changeoverLeg = this.endChangeover.legFor(this.start)
      const index = this.start.legs.indexOf(changeoverLeg)
      if (this.endChangeover.atDeparture) {
        endIndex = index
      } else {
        endIndex = index + 1
      }
    }

    return this.start.legs.slice(startIndex, endIndex)
  }

  get corridors() {
    return this.legs.map(l => l.corridor)
  }

  get penaltyMultiplier() {
    return penaltyMultiplier(
      this.startTimeLocalNormalized,
      this.endTimeLocalNormalized,
    )
  }

  get subTasks(): (StartLeg | LegTask<StartLeg>)[] {
    const { localTasks } = this.start
    const startIndex = this.startChangeover
      ? localTasks.findIndex(
          t =>
            t.origin === this.startChangeover.location &&
            t.endTimeLocal >= this.startTimeLocal,
        )
      : 0

    const endIndex = this.endChangeover
      ? findLastIndex(
          localTasks,
          t =>
            t.origin === this.endChangeover.location &&
            t.startTimeLocal <= this.endTimeLocal,
        ) + 1
      : undefined

    return localTasks.slice(startIndex, endIndex).reduce((acc, t) => {
      if (t.duration > 0) {
        acc.push(t)
      }
      if (t.kind === PreDeparture.kind && this.legs.indexOf(t.startLeg) > -1) {
        acc.push(t.startLeg)
      }
      return acc
    }, [] as (StartLeg | LegTask<StartLeg>)[])
  }

  get warnings() {
    return [
      ...super.warnings,
      ...this.assignments.flatMap(assn => assn.warnings),
    ]
  }
}
