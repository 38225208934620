import { LogMessage } from 'src/service-design/shared/models/log-message'
import { WagonAccess } from 'src/service-design/shared/models/wagon/validator'

export class AxleLoadExceeded extends LogMessage {
  static type = 'service-design::Axle load exceeded'

  static message =
    'service-design::The axle load of the consist is {{entity.consist.axleLoad}} but should be at most {{entity.corridor.axleLoadLimit}}.'

  static check(leg) {
    return leg.consist.axleLoad > leg.corridor.axleLoadLimit
  }
}

export class TooManyLocos extends LogMessage {
  static type = 'service-design::Too many locos'

  static message =
    'service-design::The maximum number of locos on this corridor is {{entity.corridor.maxLocosPerTrain}}.'

  static check(leg) {
    return leg.consist.numLocos > leg.corridor.maxLocosPerTrain
  }
}

export class MinLeadLocos extends LogMessage {
  static type = 'service-design::Minimum number of lead locos not satisfied'

  static message =
    'service-design::The consist requires {{entity.start.minLeadLocos}} lead locos but only has {{entity.consist.numLeadLocos}}'

  static check(leg) {
    return (
      leg.consist.numLocos > 0 &&
      leg.consist.numLeadLocos < leg.start.minLeadLocos
    )
  }
}

export class MissingLoadTables extends LogMessage {
  static type = 'service-design::Missing Load Tables'

  static message =
    'service-design::The following locos do not have load tables for this leg: {{missingLoadTables}}.'

  static check(leg) {
    return leg.missingLoadTables.length !== 0
  }

  constructor({ entity: leg }) {
    super(
      { leg },
      {
        missingLoadTables: leg.missingLoadTables.map(l => l.name).join('; '),
      },
    )
  }
}

export class ExceededTonnage extends LogMessage {
  static type = 'service-design::Exceeded tonnage'

  static message =
    'service-design::Leg pulls {{tonnage}}t but train type has a maximum of {{maxTonnage}}t.'

  static check(leg) {
    return leg.tonnage > leg.trainType.maxTonnage
  }

  constructor({ entity: leg }) {
    super(
      { leg },
      {
        maxTonnage: leg.trainType.maxTonnage.toFixed(2),
        tonnage: leg.tonnage.toFixed(2),
      },
    )
  }
}

export class InsufficientHaulageCapacity extends LogMessage {
  static type = 'service-design::Insufficient Haulage Capacity'

  static message =
    'service-design::Consist can haul {{haulageCapacity}}t but needs to haul {{tonnage}}t'

  static check(leg) {
    return leg.powerRating < 100
  }

  constructor({ entity: leg }) {
    super(
      { leg },
      {
        haulageCapacity: leg.haulageCapacity.toFixed(2),
        tonnage: leg.tonnage.toFixed(2),
      },
    )
  }
}

export class LocoAccess extends LogMessage {
  static type = 'service-design::Invalid Loco Access'

  static message =
    'service-design::[{{invalidLocos}}] locos on train {{leg.start.name}} are not permitted from {{leg.origin.code}} to {{leg.dest.code}}.'

  static check(leg) {
    return leg.consist.invalidLocos.length !== 0
  }

  constructor({ entity: leg }) {
    super(
      { leg },
      {
        invalidLocos: leg.consist.invalidLocos.map(l => l.name).join('; '),
      },
    )
  }
}

export class ShortDwell extends LogMessage {
  static type = 'service-design::Invalid dwell activity timing'

  static message =
    'service-design::Train {{entity.start.name}} activities at location {{entity.origin.code}} do not fit in dwell.'

  static check(leg) {
    if (leg.originating) {
      return false
    }
    return leg.attachStartLocal < leg.prev.detachEndLocal
  }
}

export class ExceededCorridorLength extends LogMessage {
  static type = 'service-design::Exceeded corridor length'

  static message =
    "service-design::Train's current length is {{length}}m which exceeds the corridors maximum of {{entity.corridor.maxLength}}m."

  static check(leg) {
    return leg.length > leg.corridor.maxLength
  }

  constructor(context) {
    super(context, {
      length: context.entity.length.toFixed(1),
    })
  }
}

export class HaulLimit extends LogMessage {
  static type = 'service-design::Too many hauled locos'

  static message =
    'service-design::This train allows hauling of up to {{entity.train.maxHauledLocos}} locos but the consist hauls {{entity.consist.numHauledLocos}}.'

  static check(leg) {
    return leg.consist.numHauledLocos > leg.start.maxHauledLocos
  }
}

export class ECPBrakingViolation extends LogMessage {
  static type = 'service-design::ECP Braking violated'

  static message = 'service-design::Locos with ECP braking capability required.'

  static check(leg) {
    return leg.start.ecpBraking && !leg.consist.ecpBraking
  }
}

export class InvalidWagonDetach extends LogMessage {
  static type = 'service-design::Invalid Wagon Detachment'

  static message =
    'service-design::Leg {{entity.name}} has invalid detachments.'

  static check(leg) {
    const { detachWagonEvents, detachWagonEventPreferences } = leg
    for (const { wagonId, quantity } of detachWagonEventPreferences) {
      const implied = detachWagonEvents.find(x => x.wagonId === wagonId)
      const impliedQuantity = implied ? implied.quantity : 0
      if (quantity !== impliedQuantity) {
        return true
      }
    }
    return false
  }
}

export class EmptyWagonAccess extends WagonAccess {
  static message =
    'service-design::[{{invalidWagons}}] wagons on train {{leg.start.name}} are not permitted from {{leg.origin.code}} to {{leg.dest.code}}.'

  static check(leg) {
    return leg.invalidEmptyWagons.length !== 0
  }

  constructor({ entity: leg }) {
    super(
      { leg },
      {
        invalidWagons: leg.invalidEmptyWagons.map(l => l.name).join('; '),
      },
    )
  }
}

export class InvalidLocoDetach extends LogMessage {
  static type = 'service-design::Invalid Loco Detachment'

  static message =
    'service-design::Leg {{entity.name}} has invalid detachments.'

  static check(leg) {
    const { detachLocoEvents, detachLocoEventPreferences } = leg
    return (
      !leg.terminating &&
      detachLocoEvents.length !== detachLocoEventPreferences.length
    )
  }
}

export default {
  warnings: [
    AxleLoadExceeded,
    InsufficientHaulageCapacity,
    ExceededTonnage,
    LocoAccess,
    MinLeadLocos,
    MissingLoadTables,
    TooManyLocos,
    ShortDwell,
    ExceededCorridorLength,
    HaulLimit,
    ECPBrakingViolation,
    EmptyWagonAccess,
    InvalidWagonDetach,
    InvalidLocoDetach,
  ],
}
