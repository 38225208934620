import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import i18n from 'src/i18n'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import { documentSpec as scenarioSpec } from 'src/service-design/scenario/document'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import { documentSpec as planSpec } from 'src/service-design/sd-plan/document'
import * as constants from 'src/service-design/shared/constants'
import { exportDocument } from 'src/service-design/shared/exporter'
import { modalShow } from 'src/service-design/shared/forms/actions/modals'
import { DOCUMENT } from 'src/service-design/shared/type-defs'

import { DocumentActions as DocumentActionsComp } from './component'

const documentSpecs = {
  scenario: scenarioSpec,
  'service-design': planSpec,
}

const getNormData = (item, document, modified) =>
  item || {
    name: document.meta.name,
    currentRevisionId: document.meta.currentRevisionId,
    id: document.meta.id,
    parentId: document.meta.parentId,
    archived: document.meta.archived,
    type: document.meta.type,
    modified,
  }

const mapStateToProps = (state, { item, document, modified }) => {
  const data = getNormData(item, document, modified)
  return {
    data,
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    item,
    document,
    modified,
    location,
    renameDocument,
    copyDocument,
    copyDocumentTo,
    copyDocumentAndOpen,
    editDocument,
    archiveDocument,
    unarchiveDocument,
    openAuditLog,
  },
) => {
  const data = getNormData(item, document, modified)
  return {
    editDocument: () => editDocument(data),
    renameDocument: () =>
      dispatch(
        modalShow(constants.MODAL_DOCUMENT_RENAME, {
          item: data,
          updateFn: renameDocument,
        }),
      ),
    copyDocument: () => copyDocument(data),
    copyDocumentAndOpen: () =>
      copyDocumentAndOpen(
        {
          name: `${data.name} [${i18n.t('Copy')}]`,
          copyRevision: data.currentRevisionId,
          documentId: data.id,
          parentId: data.parentId,
        },
        location,
      ),
    copyDocumentTo: () => copyDocumentTo(data),
    archiveDocument: () => archiveDocument(data),
    unarchiveDocument: () => unarchiveDocument(data),
    openAuditLog: () => openAuditLog(data),
    exportDocument: documentSpecs.hasOwnProperty(data.type)
      ? () => dispatch(exportDocument(data.id))
      : null,
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { trigger, showClose } = ownProps
  return {
    ...stateProps,
    trigger,
    showClose,
    exportDocument: dispatchProps.exportDocument,
    renameDocument: ownProps.renameDocument
      ? dispatchProps.renameDocument
      : null,
    copyDocument: ownProps.copyDocument ? dispatchProps.copyDocument : null,
    copyDocumentAndOpen: ownProps.copyDocumentAndOpen
      ? dispatchProps.copyDocumentAndOpen
      : null,
    copyDocumentTo: ownProps.copyDocumentTo
      ? dispatchProps.copyDocumentTo
      : null,
    editDocument: ownProps.editDocument ? dispatchProps.editDocument : null,
    archiveDocument: ownProps.archiveDocument
      ? dispatchProps.archiveDocument
      : null,
    unarchiveDocument: ownProps.unarchiveDocument
      ? dispatchProps.unarchiveDocument
      : null,
    openAuditLog: ownProps.openAuditLog ? dispatchProps.openAuditLog : null,
  }
}

export const DocumentActions = withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(DocumentActionsComp),
)

DocumentActions.defaultProps = {
  disabledActions: [],
  renameDocument: null,
  modified: false,
}

DocumentActions.propTypes = {
  disabledActions: PropTypes.arrayOf(PropTypes.string),
  renameDocument: PropTypes.func,
  item: DOCUMENT,
  document: DOCUMENT,
  modified: PropTypes.bool,
  archiveDocument: PropTypes.func,
  copyDocument: PropTypes.func,
  copyDocumentTo: PropTypes.func,
  copyDocumentAndOpen: PropTypes.func,
  editDocument: PropTypes.func,
  exportDocuments: PropTypes.func,
  openAuditLog: PropTypes.func,
  trigger: PropTypes.node,
  showClose: PropTypes.bool,
  unarchiveDocument: PropTypes.func,
}
