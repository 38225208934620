import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { transitTimesFactory } from './factory'
import { ITransitTimesRepo, TransitTimesRepo } from './repo'

const transitTimesRepo = defaultMemoize(
  (input: FactoryInput) => new TransitTimesRepo(transitTimesFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  transitTimesRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type TransitTimesRepoReg = {
  transitTimesRepo: FromDocumentFactory<FactoryInput, ITransitTimesRepo>
}

export function setup<R extends TransitTimesRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('transitTimesRepo', transitTimesRepo)

  relationships.addConstraints('transittimes', {
    unique: [['corridorId', 'trainTypeId']],
    rels: [
      {
        collection: 'traintypes',
        foreign: 'trainTypeId',
        name: 'trainType',
      },
      {
        collection: 'corridors',
        foreign: 'corridorId',
        name: 'corridor',
      },
    ],
  })
}

export { TransitTimes } from './model'
