import { readAsArrayBuffer } from 'promise-file-reader'

import xlsx from 'xlsx'

import i18n from 'src/i18n'
import {
  revisionsReceive,
  documentPost,
} from 'src/service-design/shared/document/actions/index'
import { postDocumentLoadCheckOnly } from 'src/service-design/shared/document/actions/post-document-load'

export const MAX_NUM_SPREADSHEET_COLUMNS = 50

export class ImportError extends Error {
  constructor(public errors) {
    super('The file could not be imported') // TODO: i18n. Revisit
  }
}

interface ErrorSource {
  sourceMissingName: string
  sourceMissingId: string
  collections: { [collection: string]: string[] }
  message: string
}

interface ErrorMessage {
  [errorSource: string]: ErrorSource
}

export class FixableError extends Error {
  constructor(public errors: ErrorMessage) {
    super()
  }
}

export class TooManySheetColumnsWarning {
  constructor(public sheet) {}

  get stack() {
    return i18n.t(
      'common::In the file uploaded, the sheet {{ sheet }} has a large number of columns. All columns after column {{ max_num }} were ignored. Note that these may be blank and they should be deleted.',
      {
        sheets: this.sheet,
        max_num: MAX_NUM_SPREADSHEET_COLUMNS,
      },
    )
  }
}

const headersFromXlsx = xlsxSheet => {
  const range = xlsx.utils.decode_range(xlsxSheet['!ref'])
  const endColIdx = range.e.c + 1

  return [...Array(endColIdx).keys()].map(colIdx => {
    const cell = xlsxSheet[xlsx.utils.encode_cell({ r: 0, c: colIdx })]
    return cell ? cell.v : ''
  })
}

export const checkSpreadsheetFields = (xlsxSheet, schema, sheetName) => {
  const cols = headersFromXlsx(xlsxSheet)
  const errors = schema.validateHeaders(cols)
  if (errors.length > 0) {
    throw new ImportError(
      errors.map(error => ({
        id: `${sheetName}-${error.fieldDef.header}`,
        type: 'Missing column',
        message: `Column ${error.fieldDef.header} is missing from the sheet ${sheetName}`, // TODO: i18n
      })),
    )
  }
}

export const parseWorkbook = (workbook, workSheets) =>
  workSheets.sheets.reduce(
    (acc, { sheet: sheetName, collection, singleton, schema, exclude }) => {
      const sheet = workbook.Sheets[sheetName]

      if (!exclude && !sheet) {
        throw new ImportError([`Missing '${sheetName}' sheet`])
      }

      if ((collection || singleton) && sheet) {
        const sheetRangeRef = sheet['!ref']
        const sheetRange = xlsx.utils.decode_range(sheetRangeRef)

        if (sheetRange.e.c > MAX_NUM_SPREADSHEET_COLUMNS) {
          acc.warnings.push(new TooManySheetColumnsWarning(sheetName))
        }

        const limitedSheetRange = {
          ...sheetRange,
          e: {
            ...sheetRange.e,
            c: Math.min(sheetRange.e.c, MAX_NUM_SPREADSHEET_COLUMNS),
          },
        }

        const rows = xlsx.utils.sheet_to_json(sheet, {
          raw: true,
          range: limitedSheetRange,
        })

        if (rows.length > 0) {
          checkSpreadsheetFields(sheet, schema, sheetName)
        }
        const processed = rows.map(row => schema.load(row))

        if (collection) {
          acc.data[collection] = processed
        } else {
          // eslint-disable-next-line prefer-destructuring
          acc.data.singletons[singleton] = processed[0]
        }
        return acc
      }

      if (collection) {
        acc.data[collection] = []
      } else if (singleton) {
        acc.data.singletons[singleton] = {}
      }

      return acc
    },
    { data: { singletons: {} }, warnings: [] },
  )

const validateDocuments = async ({ documentSpec, dispatch }) => {
  const { unfixable, fixed } = await dispatch(
    postDocumentLoadCheckOnly({ documentSpec }),
  )

  if (unfixable.length > 0) {
    throw new ImportError(unfixable)
  }

  if (Object.keys(fixed).length > 0) {
    throw new FixableError(fixed)
  }
}

export const importDocumentFromFile = ({
  file,
  documentSpec,
  type,
}) => async dispatch => {
  try {
    const array = await readAsArrayBuffer(file)
    const workbook = xlsx.read(array, { type: 'array', cellDates: true })
    const { data, warnings } = parseWorkbook(workbook, documentSpec.worksheets)
    await dispatch(revisionsReceive([{ data, meta: { type } }]))

    await validateDocuments({ documentSpec, dispatch })
    return { warnings }
  } catch (e) {
    if (e instanceof ImportError || e instanceof FixableError) {
      throw e
    } else {
      console.error(e)
      throw new ImportError([
        {
          id: null,
          type: 'File read error',
          message: e.message,
        },
      ])
    }
  }
}

export const saveImportedDocument = ({
  fileName,
  type,
  parentId,
  currentVersion,
}) => async (dispatch, getState) => {
  const { documents } = getState()
  let resp
  try {
    resp = await documentPost(
      fileName,
      type,
      documents[type].data,
      parentId,
      currentVersion,
    )
  } catch (e) {
    throw new ImportError([
      {
        id: null,
        type: e.data.code,
        message: 'There was an unexpected error while saving the document',
      },
    ])
  }

  const { id } = resp.data
  return id
}
