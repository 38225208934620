import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'

export class MissingShiftAssignment extends LogMessage {
  static type = 'service-design::Work is not assigned to a shift'

  static message = 'service-design::{{entity.name}} is not assigned to a shift'

  static check(task) {
    return !task.isAssigned
  }
}
export class UnqualifiedDriverForAssignment extends LogMessage {
  static dedupe = true

  static type = 'service-design::Unqualified Driver'

  static message =
    'service-design::Driver is not qualified to perform task {{entity.name}}'

  get id() {
    // @ts-ignore issue here is that there no information about the type of context
    return this.context.entity.id
  }

  static check(assignment) {
    return !assignment.isQualified()
  }
}

export default {
  warnings: flags.crewing ? [UnqualifiedDriverForAssignment] : [],
}
