import classNames from 'classnames'
import React from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledWrapper = styled(Segment)`
  &,
  &.segment {
    height: 100%;
    border: none;
  }

  &.fixed {
    position: static;
  }
`

type LoadingSpinnerProps = {
  fixed?: boolean
  className?: string
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = React.memo(
  ({ fixed = false, className }) => (
    <StyledWrapper className={classNames({ fixed }, className)}>
      <Dimmer active inverted>
        <Loader inverted content="Loading" />
      </Dimmer>
    </StyledWrapper>
  ),
)

export const FullWidthSpinner = styled(LoadingSpinner)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
