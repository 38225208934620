import * as uuid from 'uuid'

import * as constants from 'src/service-design/shared/constants'

export const notificationPost = (header, text, kind) => ({
  type: constants.NOTIFICATION_POST,
  payload: {
    header,
    text,
    kind,
    id: uuid.v4(),
  },
})

export const notificationDismiss = id => ({
  type: constants.NOTIFICATION_DISMISS,
  payload: id,
})
