import * as constants from 'src/service-design/shared/constants'

export const modalShow = (modalName: string, props = {}) => ({
  type: constants.MODAL_SHOW,
  payload: { modalName, props },
})

export const modalDismiss = () => ({
  type: constants.MODAL_DISMISS,
})

export const blockingErrorModalShow = (
  errors: { id: string; message: string }[],
) =>
  modalShow(constants.MODAL_BLOCKING_ERROR, {
    header: 'Document is invalid.',
    messages: errors.map(({ id, message }) => ({ id, message })),
  })

export const ackableErrorModalShow = (message: any) =>
  modalShow(constants.MODAL_ACKABLE_ERROR, {
    header: 'Errors occurred which were automatically fixed',
    message,
  })

export const errorModalShow = ({
  header,
  message,
  messages,
  entity,
}: {
  header: string
  message?: string
  messages?: { id: string; message: string }[]
  entity?: any
}) => modalShow(constants.MODAL_ERROR, { header, message, messages, entity })
