export {
  getDocuments,
  getDocument,
  getCollection,
} from 'src/service-design/shared/document/selectors'

export const getPlanId = state => state.documents['service-design'].meta.id

export const getParentId = state =>
  state.documents.scenario && state.documents.scenario.meta.id
