import { LogMessage } from 'src/service-design/shared/models/log-message'

import { RemoteRest } from './model'

export class CrewTypeCannotRemotelyRest extends LogMessage {
  static type = 'service-design::Crew Pool cannot have remote rests'

  static message =
    'service-design::Crew Pool {{entity.pool.name}} does not allow remote rests'

  static check(remoteRest: RemoteRest) {
    return !remoteRest.pool.type.canRemotelyRest
  }

  constructor({ entity }: { entity: RemoteRest }) {
    super({ entity })
  }
}

export class LocationCannotRemotelyRest extends LogMessage {
  static type = 'service-design::Location does not allow remote rests'

  static message =
    'service-design::Location {{entity.location.code}} does not allow remote rests'

  static check(remoteRest: RemoteRest) {
    return !remoteRest.location.allowRemoteRest
  }

  constructor({ entity }: { entity: RemoteRest }) {
    super({ entity })
  }
}

export class RemoteRestTooShort extends LogMessage {
  static type = 'service-design::Remote Rest too short'

  static message =
    'service-design::Remote Rest on {{entity.pool.name}} should be at least {{entity.remoteRestMinDurationHours}} hours'

  static check(remoteRest: RemoteRest) {
    return remoteRest.duration < remoteRest.pool.type.remoteRestMinDurationSecs
  }

  constructor({ entity }: { entity: RemoteRest }) {
    super({ entity })
  }
}

export default {
  warnings: [
    CrewTypeCannotRemotelyRest,
    LocationCannotRemotelyRest,
    RemoteRestTooShort,
  ],
}
