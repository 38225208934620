import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { CustomTaskAssignment } from './model'

export const customTaskAssignmentFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.customtaskassignments.map(data => {
      const item = new CustomTaskAssignment(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((customTaskAssignment: CustomTaskAssignment, state: any) => ({
    customTask: registry
      .provide('customTaskRepo')(state)
      .byId(customTaskAssignment.customTaskId),
    shift: registry
      .provide('shiftRepo')(state)
      .byId(customTaskAssignment.shiftId),
  })),
)
