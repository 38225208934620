// RJ: this is a *temporary* home for the domain layer while we migrate our
// architecture; it is necessary to force the side-effect registration of
// our model repos
import { registry } from './document-factory'
import * as AccessGroup from './models/access-group'
import * as BusinessGroup from './models/business-group'
import * as CargoType from './models/cargo-type'
import * as LocationChangeoverLock from './models/changeover-lock/location'
import * as TrainChangeoverLock from './models/changeover-lock/train'
import * as LocationChangeover from './models/changeover/location'
import * as TrainChangeover from './models/changeover/train'
import * as CompoundShift from './models/compound-shift'
import * as Corridor from './models/corridor'
import * as CrewPool from './models/crew-pool'
import * as CrewType from './models/crew-type'
import * as CustomTask from './models/custom-task'
import * as EmptyWagonEvent from './models/empty-wagon-event'
import * as ForeignRailroad from './models/foreign-railroad'
import * as RosterHead from './models/linked-list-head/roster'
import * as LoadCategory from './models/load-category'
import * as LoadTable from './models/load-table'
import * as LoadingWorkSplit from './models/loading-work-split'
import * as Location from './models/location'
import * as LocationCargoCompatibility from './models/location-cargo-compatibility'
import * as LocoEvent from './models/loco-event'
import * as Lococlass from './models/lococlass'
import * as LococlassAccessRight from './models/lococlass-access-right'
import * as LVTrip from './models/lv-trip'
import * as RDO from './models/rdo'
import * as RemoteRest from './models/remote-rest'
import * as ResourceAvailability from './models/resource-availability'
import * as ResourceUnavailability from './models/resource-unavailability'
import * as RosterLine from './models/roster-line'
import * as RouteKnowledge from './models/route-knowledge'
import * as Service from './models/service'
import * as ServiceEvent from './models/service-event'
import * as ServiceLock from './models/service-lock'
import * as Shift from './models/shift'
import * as CustomTaskAssignment from './models/shift-assignment/custom-task'
import * as DriverAssignment from './models/shift-assignment/driver'
import * as LoadingAssignment from './models/shift-assignment/loading'
import * as LocalAssignment from './models/shift-assignment/local'
import * as UncrewedLegTask from './models/shift-assignment/uncrewed-leg-task'
import * as ShiftLine from './models/shift-line'
import * as StartLeg from './models/start-leg'
import * as StartLegTaskOffset from './models/start-leg-task-offset'
import * as TemplateLeg from './models/template-leg'
import * as Timezone from './models/timezone'
import * as TrainBlock from './models/train-block'
import * as TrainGraph from './models/train-graph'
import * as TrainGraphLocation from './models/train-graph-location'
import * as TrainStart from './models/train-start'
import * as TrainTemplate from './models/train-template'
import * as TrainType from './models/train-type'
import * as TransitTimes from './models/transit-times'
import * as Wagon from './models/wagon'
import * as WagonAccessRight from './models/wagon-access-right'
import * as WagonAllocation from './models/wagon-allocation'
import * as WagonCargoCompatibility from './models/wagon-cargo-compatibility'
import * as WagonSet from './models/wagon-set'
import * as WorkingLocoLock from './models/working-loco-lock'
import * as YardBlock from './models/yard-block'
import * as YardBlockAssignment from './models/yard-block-assignment'

AccessGroup.setup({ repoRegistry: registry })
BusinessGroup.setup({ repoRegistry: registry })
CargoType.setup({ repoRegistry: registry })
CompoundShift.setup({ repoRegistry: registry })
Corridor.setup({ repoRegistry: registry })
CrewPool.setup({ repoRegistry: registry })
CrewType.setup({ repoRegistry: registry })
CustomTask.setup({ repoRegistry: registry })
CustomTaskAssignment.setup({ repoRegistry: registry })
DriverAssignment.setup({ repoRegistry: registry })
EmptyWagonEvent.setup({ repoRegistry: registry })
ForeignRailroad.setup({ repoRegistry: registry })
RosterHead.setup({ repoRegistry: registry })
RemoteRest.setup({ repoRegistry: registry })
LoadCategory.setup({ repoRegistry: registry })
LoadingWorkSplit.setup({ repoRegistry: registry })
LoadingAssignment.setup({ repoRegistry: registry })
LoadTable.setup({ repoRegistry: registry })
Location.setup({ repoRegistry: registry })
LocalAssignment.setup({ repoRegistry: registry })
LocationCargoCompatibility.setup({ repoRegistry: registry })
LocationChangeover.setup({ repoRegistry: registry })
LocationChangeoverLock.setup({ repoRegistry: registry })
Lococlass.setup({ repoRegistry: registry })
TemplateLeg.setup({ repoRegistry: registry })
LocoEvent.setup({ repoRegistry: registry })
LococlassAccessRight.setup({ repoRegistry: registry })
LVTrip.setup({ repoRegistry: registry })
RDO.setup({ repoRegistry: registry })
ResourceAvailability.setup({ repoRegistry: registry })
ResourceUnavailability.setup({ repoRegistry: registry })
RosterLine.setup({ repoRegistry: registry })
RouteKnowledge.setup({ repoRegistry: registry })
Service.setup({ repoRegistry: registry })
ServiceEvent.setup({ repoRegistry: registry })
ServiceLock.setup({ repoRegistry: registry })
Shift.setup({ repoRegistry: registry })
ShiftLine.setup({ repoRegistry: registry })
TrainChangeover.setup({ repoRegistry: registry })
TrainChangeoverLock.setup({ repoRegistry: registry })
UncrewedLegTask.setup({ repoRegistry: registry })
StartLeg.setup({ repoRegistry: registry })
StartLegTaskOffset.setup({ repoRegistry: registry })
Timezone.setup({ repoRegistry: registry })
TrainBlock.setup({ repoRegistry: registry })
TrainGraph.setup({ repoRegistry: registry })
TrainGraphLocation.setup({ repoRegistry: registry })
TrainStart.setup({ repoRegistry: registry })
TrainTemplate.setup({ repoRegistry: registry })
TrainType.setup({ repoRegistry: registry })
TransitTimes.setup({ repoRegistry: registry })
Wagon.setup({ repoRegistry: registry })
WagonAccessRight.setup({ repoRegistry: registry })
WagonAllocation.setup({ repoRegistry: registry })
WagonCargoCompatibility.setup({ repoRegistry: registry })
WagonSet.setup({ repoRegistry: registry })
WorkingLocoLock.setup({ repoRegistry: registry })
YardBlock.setup({ repoRegistry: registry })
YardBlockAssignment.setup({ repoRegistry: registry })
