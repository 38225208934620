import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { trainTypeFactory } from './factory'
import { ITrainTypeRepo, TrainTypeRepo } from './repo'

const trainTypeRepo = defaultMemoize(
  (input: FactoryInput) => new TrainTypeRepo(trainTypeFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  trainTypeRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type TrainTypeRepoReg = {
  trainTypeRepo: FromDocumentFactory<FactoryInput, ITrainTypeRepo>
}

export function setup<R extends TrainTypeRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('trainTypeRepo', trainTypeRepo)
}

export { TrainType } from './model'
