import * as constants from 'src/service-design/shared/constants'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { Task } from 'src/service-design/shared/models/task/base'
import { LegTask } from 'src/service-design/shared/models/task/leg'
import { mergeMultiplyCounts } from 'src/service-design/shared/models/task/mergeMultipyCounts'

export { Task }
export { LegTask }

export class PostArrival<T extends IStartLeg> extends LegTask<T> {
  /**
   * PostArrival represents the time between a TrainStart hitting the 'yard
   * limit' of a location and it coming to a complete stop.
   */
  static arrivingTask = true

  static kind = 'post-arrival'

  static requiresRailOperator = false

  static timeOffsetAllowed = false

  static build(startLeg: StartLeg) {
    return new this({
      origin: startLeg.dest,
      destination: startLeg.dest,
      startTimeLocal: startLeg.postArriveStartLocal,
      endTimeLocal: startLeg.postArriveEndLocal,
      startLeg,
      externals: startLeg.externals,
    })
  }

  get resourceDict() {
    return this.startLeg.resourceDict
  }

  get totalWorkingSecs() {
    return mergeMultiplyCounts(
      this.duration,
      this.startLeg.consist.workingDict,
      this.startLeg.wagonDict,
    )
  }

  get totalHauledSecs() {
    return mergeMultiplyCounts(this.duration, this.startLeg.consist.hauledDict)
  }

  get costs() {
    return this.startLeg.consist.workingLocos.reduce(
      (acc, l) =>
        acc.concat([
          {
            costType: constants.COST_FUEL_LOCAL,
            entity: `${l.name} post arrival on ${this.startLeg.name}`,
            rate: this.startLeg.singletons.costs.fuel,
            quantity:
              (this.duration / constants.SECONDS_PER_HOUR) *
              l.workingHourFuelBurn,
          },
          {
            costType: constants.COST_WORKING_HOUR,
            entity: `${l.name} post arrival on ${this.startLeg.name}`,
            rate: l.workingHourCost,
            quantity: this.duration / constants.SECONDS_PER_HOUR,
          },
        ]),
      [],
    )
  }
}
