import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { startLegFactory } from './factory'
import { IStartLegRepo, StartLegRepo } from './repo'

export const startLegRepo = defaultMemoize(
  (input: FactoryInput) => new StartLegRepo(startLegFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  startLegRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type StartLegRepoReg = {
  startLegRepo: FromDocumentFactory<FactoryInput, IStartLegRepo>
}

export function setup<R extends StartLegRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('startLegRepo', startLegRepo)
}

export { StartLeg } from './model'
