import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { compoundShiftFactory } from './factory'
import { ICompoundShiftRepo, CompoundShiftRepo } from './repo'

const compoundShiftRepo = defaultMemoize(
  (input: FactoryInput) => new CompoundShiftRepo(compoundShiftFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  compoundShiftRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type CompoundShiftRepoReg = {
  compoundShiftRepo: FromDocumentFactory<FactoryInput, ICompoundShiftRepo>
}

export function setup<R extends CompoundShiftRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('compoundShiftRepo', compoundShiftRepo)
}

export { CompoundShift } from './model'
