import get from 'get-or-else'
import { batchActions } from 'redux-batched-actions'

import i18n from 'src/i18n'
import * as constants from 'src/service-design/shared/constants'
import { getCollectionData } from 'src/service-design/shared/document-factory/factory-input'
import {
  instanceDelete,
  documentErrorsUpdate,
  revisionSaved,
  instanceEdit,
} from 'src/service-design/shared/document/actions'
import { InstanceRepair } from 'src/service-design/shared/document/repairs'
import { validateAll } from 'src/service-design/shared/document/validation'
import {
  ackableErrorModalShow,
  blockingErrorModalShow,
} from 'src/service-design/shared/forms/actions/modals'

import { BrokenRelationship } from './objects'
import { findBrokenRelations } from './relationships'

export const documentHasErrors = () => ({ type: constants.DOCUMENT_HAS_ERRORS })
export const acknowledgeDocumentErrors = () => ({
  type: constants.DOCUMENT_CLEAR_ERRORS,
})

export const resolveErrors = problems => {
  let resultObj = {}
  const brokenRels = problems.filter(p => p instanceof BrokenRelationship)
  for (const problem of brokenRels) {
    const objNameDelete = problem.collectionName
    const objIdDelete = problem.affectedObj.id
    const sourceMissingName = problem.relationDefinition.collection
    const sourceMissingId =
      problem.affectedObj[problem.relationDefinition.foreign]

    const topLevelKey = `${sourceMissingName}:${sourceMissingId}`

    if (!resultObj[topLevelKey]) {
      resultObj[topLevelKey] = {
        sourceMissingName,
        sourceMissingId,
        collections: {},
      }
    }

    if (!resultObj[topLevelKey].collections[objNameDelete]) {
      resultObj[topLevelKey].collections[objNameDelete] = new Set()
    }

    resultObj[topLevelKey].collections[objNameDelete].add(objIdDelete)
  }

  // Basically convert the Set's to plain objects
  resultObj = Object.assign(
    {},
    ...Object.entries(resultObj).map(([topKey, { collections, ...rest }]) => ({
      [topKey]: Object.assign(
        rest,
        ...Object.entries(collections).map(([collectionName, ids]) => ({
          collections: { [collectionName]: [...ids] },
        })),
      ),
    })),
  )
  const repairs = problems.filter(p => p instanceof InstanceRepair)
  for (const { message, context, derived } of repairs) {
    const objErrorMsg = i18n.t(message, { entity: context, ...derived })
    resultObj[objErrorMsg] = { message: objErrorMsg }
  }
  return resultObj
}

export const documentAckErrorsUpdate = problems => dispatch => {
  const resultObj = resolveErrors(problems)

  dispatch(ackableErrorModalShow(resultObj))
  dispatch(documentHasErrors())
}

// We loop here until all problems are fixed as fixing one may reveal another

const applyUntilFixed = func => async dispatch => {
  const fixedProblems = []
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { fixed = [] } = await dispatch(func)
    fixedProblems.push(...fixed)
    if (fixed.length !== 0) {
      return { fixed: fixedProblems }
    }
    return {}
  }
}

export const repairInstanceIntegrityRules = ({ repairs }) => async (
  dispatch,
  getState,
) => {
  const fixed = repairs ? Object.values(repairs.build(getState())).flat() : []
  if (fixed.length > 0) {
    await dispatch(
      batchActions(
        fixed.map(({ collectionName, affectedObj }) =>
          instanceDelete(collectionName, affectedObj.id),
        ),
      ),
    )
  }

  return { fixed }
}

export const fixBrokenRelations = brokenRelationships => dispatch => {
  const batchedActions = []
  const deletedObjIds = []
  for (const relationship of brokenRelationships) {
    if (relationship.relationDefinition.allowNull) {
      const obj = relationship.affectedObj
      obj[relationship.relationDefinition.name] = null
      batchedActions.push(instanceEdit(relationship.collectionName, obj))
    } else {
      if (!deletedObjIds.includes(relationship.affectedObj.id)) {
        batchedActions.push(
          instanceDelete(
            relationship.collectionName,
            relationship.affectedObj.id,
          ),
        )
      }
      deletedObjIds.push(relationship.affectedObj.id)
    }
  }
  return dispatch(batchActions(batchedActions))
}

export const cascadeParentDeletes = ({ relationships }) => (
  dispatch,
  getState,
) => {
  const problems = findBrokenRelations(
    relationships,
    getCollectionData(getState()),
  )

  if (problems.length > 0) {
    dispatch(fixBrokenRelations(problems))
  }

  return { fixed: problems }
}

export const validateDocumentSchema = documentSpec => (dispatch, getState) => {
  const { documents } = getState()
  const errors = validateAll(Object.values(documents), documentSpec)
  return { unfixable: errors }
}

export const documentLoaded = () => ({
  type: constants.DOCUMENT_LOADED,
})

export const postDocumentLoad = ({ documentSpec }) => async dispatch => {
  const fixedProblems = []

  for (const phase of [
    validateDocumentSchema(documentSpec),
    cascadeParentDeletes(documentSpec),
    applyUntilFixed(repairInstanceIntegrityRules(documentSpec)),
  ]) {
    // eslint-disable-next-line no-await-in-loop
    const { unfixable = [], fixed = [] } = await dispatch(phase)

    if (unfixable.length > 0) {
      return { unfixable }
    }

    fixedProblems.push(...fixed)
  }

  await dispatch(documentLoaded())
  return { fixed: fixedProblems }
}

export const postDocumentLoadDisplayModalError = ({
  documentSpec,
  documentRoot,
}) => async (dispatch, getState) => {
  if (documentRoot) {
    const initialData = get([getState(), documentRoot]).data
    dispatch(revisionSaved(initialData))
  }

  const { unfixable = [], fixed = [] } = await dispatch(
    postDocumentLoad({ documentSpec }),
  )

  if (unfixable.length > 0) {
    // eslint-disable-next-line no-await-in-loop
    await dispatch(documentErrorsUpdate(unfixable))
    await dispatch(blockingErrorModalShow(unfixable))
    return { unfixable }
  }
  if (fixed.length > 0) {
    dispatch(documentAckErrorsUpdate(fixed))
    return { fixed }
  }
  return {}
}

export const postDocumentLoadCheckOnly = ({
  documentSpec,
}) => async dispatch => {
  const { unfixable = [], fixed = [] } = await dispatch(
    postDocumentLoad({ documentSpec }),
  )

  return { unfixable, fixed: resolveErrors(fixed) }
}
