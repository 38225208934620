import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { ServiceEvent } from './model'

export const serviceEventFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.serviceevents.map(data => {
      const item = new ServiceEvent(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((serviceEvent: ServiceEvent, state: any) => ({
    start: registry
      .provide('trainStartRepo')(state)
      .byId(serviceEvent.startId),
    templateLeg: registry
      .provide('templateLegRepo')(state)
      .byId(serviceEvent.templateLegId),
    startLeg: registry
      .provide('startLegRepo')(state)
      .byId(`${serviceEvent.startId}:${serviceEvent.templateLegId}`),
    service: registry
      .provide('serviceRepo')(state)
      .byId(serviceEvent.serviceId),
  })),
)
