import { startCase } from 'lodash'

import { TemporalMapper } from 'src/service-design/shared/models/mapper/temporal'
import { LoadingAssignment } from 'src/service-design/shared/models/shift-assignment/loading/model'
import { LoadTask } from 'src/service-design/shared/models/task'

import validators from './validators'

export class LoadingWorkTask extends TemporalMapper {
  static validators = validators

  task: LoadTask
  requiresDriver: boolean
  startTimeLocal: number
  endTimeLocal: number
  index: 0 | 1
  requiresAssignment: boolean
  requiresTravel: boolean

  /**
   *
   * LoadingWorkTask is the (derived) crewable work associated with a LoadTask.
   * These tasks are derived from the LoadTask and LoadingWorkSplits and
   * assigned to Shift using LoadingAssignments.
   *
   * Note: Current policy is for LoadingWorkTasks to remain in Shifts (via LoadingAssignments)
   * even if they have been invalidated or are no longer necessary.
   * The rationale behind this policy is that:
   * 1. the many ways that LoadingWorkTasks can be invalidated are unclear and
   * 2. its derived nature means that delete functionality is cannot be relied upon
   * For example, if a Service is removed for a TrainStart this could mean that
   *  the loading for a cargo type no longer needs to happen.
   *
   * When `requireAssignment` is false it indicates that the work has been derived
   * but it does actually need to be put into a Shift.
   * via a composite foreign key cascade delete functionality cannot be relied
   * on to strip LoadingAssignments from Shift when the requirements for crew
   * changes. For example, if a Service is removed for a TrainStart this could
   * mean that the loading for a cargo type no longer needs to happen.
   *
   * Since it's so difficult to understand the many ways that LoadingWorkTasks
   * might be invalidated the presently policy within the application is to
   * leave LoadingWorkTasks in Shifts (via LoadingAssignments) even if they are
   * no longer necessary. When `requireAssignment` is false it indicates that
   * that the work has been derived but it does actually need to be put into a
   * Shift.
   *
   * Related models:
   *  - LoadWorkSplit;
   *  - LoadingAssignment;
   *  - LoadTask.
   *
   * @param {LoadTask} task - The task that is driving the demand for crew
   * @param {boolean} requiresDriver - If true a Driver is required for this
   *   work otherwise a RO will suffice.
   * @param {number} startTimeLocal - The time the work begins
   * @param {number} endTimeLocal - The time the work ends
   * @param {0 | 1} index -  If there is a LoadWorkSplit associated with the
   *   LoadingWorkTask this will be used to determine which side of the split
   *   this work refers to. Note the 0 always refers to the work that is closest
   *   to the TrainStart.
   * @param {object} externals - Used for warning generation
   * @param {boolean} requiresAssignment - Whether or not this task has to be
   *   assigned to a shift.
   *
   */
  constructor({
    task,
    requiresDriver,
    startTimeLocal,
    endTimeLocal,
    index,
    requiresAssignment,
    externals,
  }: {
    task: LoadTask
    requiresDriver: boolean
    startTimeLocal: number
    endTimeLocal: number
    index: 0 | 1
    externals: any
    requiresAssignment: boolean
  }) {
    super()
    this.task = task
    this.requiresDriver = requiresDriver
    this.startTimeLocal = startTimeLocal
    this.endTimeLocal = endTimeLocal
    this.index = index
    this.requiresAssignment = requiresAssignment
    this.requiresTravel = false
    this.setExternals(externals)
  }

  get displayName() {
    return `${this.start.name} - ${startCase(this.kind)} ${
      this.cargoType.name
    } as ${this.requiresDriver ? 'Driver' : 'RO'} [${
      this.index === 0 ? 'Primary' : 'Secondary'
    }]`
  }

  get name() {
    return this.displayName
  }

  static generateId(
    startId: string,
    templateLegId: string,
    kind: string,
    cargoTypeId: string,
    requiresDriver: boolean,
    index: number,
  ) {
    return `${startId}-${templateLegId}-${kind}-${cargoTypeId}-${requiresDriver}-${index}`
  }

  get id() {
    return LoadingWorkTask.generateId(
      this.startId,
      this.templateLegId,
      this.kind,
      this.cargoTypeId,
      this.requiresDriver,
      this.index,
    )
  }

  get startLeg() {
    return this.task.startLeg
  }

  get startLegId() {
    return this.startLeg.id
  }

  get start() {
    return this.startLeg.start
  }

  get startId() {
    return this.start.id
  }

  get templateLegId() {
    return this.startLeg.templateLegId
  }

  get cargoType() {
    return this.task.cargoType
  }

  get cargoTypeId() {
    return this.cargoType.id
  }

  get kind() {
    return this.task.kind
  }

  get startLocation() {
    return this.task.startLocation
  }

  get endLocation() {
    return this.task.endLocation
  }

  get origin() {
    return this.startLocation
  }

  get destination() {
    return this.endLocation
  }

  get assignments(): LoadingAssignment[] {
    return this.startLeg.loadingAssignments.filter(
      sa =>
        (this.constructor as any).generateId(
          sa.startId,
          sa.templateLegId,
          sa.kind,
          sa.cargoTypeId,
          sa.actingAsDriver,
          sa.index,
        ) === this.id,
    )
  }

  get isAssigned() {
    return Boolean(this.assignments.length)
  }

  get penaltyMultiplier() {
    return this.task.penaltyMultiplier
  }

  get shift() {
    return this.assignments.length && this.assignments[0].shift
  }

  get warnings() {
    return [
      ...super.warnings,
      ...this.assignments.flatMap(assignment => assignment.warnings),
    ]
  }
}
