import { groupBy } from 'lodash'

import { v4 as uuid4 } from 'uuid'

import { getCollection } from 'src/service-design/shared/document/selectors'

const getNeighbors = (
  state,
  documentType,
  followRels,
  { collection, target: { id } },
) =>
  followRels
    .filter(({ parent }) => parent === collection)
    .map(({ child, foreign }) => {
      const childCollection = getCollection(state, documentType, child)

      if (!childCollection) {
        throw new Error(
          `Cannot find child collection '${child}' in` +
            ` the '${documentType}' document.`,
        )
      }

      return childCollection
        .filter(target => target[foreign] === id)
        .map(target => ({
          target,
          collection: child,
        }))
    })
    .reduce((acc, x) => acc.concat(x), [])

const findNeighborhood = (state, documentType, collection, id, followRels) => {
  const target = getCollection(state, documentType, collection).find(
    entity => entity.id === id,
  )
  const node = { collection, target }
  const neighborhood = new Set([node])
  const queue = [node]

  while (queue.length) {
    const neighbors = getNeighbors(
      state,
      documentType,
      followRels,
      queue.shift(),
    )
    neighbors.forEach(neighbor => {
      if (!neighborhood.has(neighbor)) {
        neighborhood.add(neighbor)
        queue.push(neighbor)
      }
    })
  }

  return [...neighborhood]
}

const cloneNeighborhood = (followRels, neighborhood) => {
  const old2NewIds = new Map(
    neighborhood.map(({ target: { id: old } }) => [old, uuid4()]),
  )

  if (old2NewIds.size !== neighborhood.length) {
    throw new Error('Entity ids must be unique to perform a document clone.')
  }

  const neighborCollections = groupBy(neighborhood, x => x.collection)
  return Object.entries(neighborCollections).reduce(
    (acc, [collection, entities]) => {
      const rels = followRels.filter(({ child }) => child === collection)
      const updateEntities = entities.map(({ target: entity }) => {
        const updateRels = Object.assign(
          {},
          ...rels.map(({ foreign }) => ({
            [foreign]: old2NewIds.get(entity[foreign]),
          })),
        )
        return {
          ...entity,
          id: old2NewIds.get(entity.id),
          ...updateRels,
        }
      })
      return {
        ...acc,
        [collection]: updateEntities,
      }
    },
    {},
  )
}

// WARNING: not type safe
// TODO: We need to find a type safe replacement for this
export const copyEntity = ({
  state,
  documentType,
  collection,
  id,
  followRels = [],
}) => {
  const neighborhood = findNeighborhood(
    state,
    documentType,
    collection,
    id,
    followRels,
  )

  return cloneNeighborhood(followRels, neighborhood)
}
