import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { businessGroupFactory } from './factory'
import { IBusinessGroupRepo, BusinessGroupRepo } from './repo'

const businessGroupRepo = defaultMemoize(
  (input: FactoryInput) => new BusinessGroupRepo(businessGroupFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  businessGroupRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type BusinessGroupRepoReg = {
  businessGroupRepo: FromDocumentFactory<FactoryInput, IBusinessGroupRepo>
}

export function setup<R extends BusinessGroupRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('businessGroupRepo', businessGroupRepo)
}

export { BusinessGroup } from './model'
