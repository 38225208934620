import { createSelector, createStructuredSelector } from 'reselect'

import { ScenarioDocument } from 'src/service-design/scenario/document/types'
import { getDocumentGraph as getScenarioDocumentGraph } from 'src/service-design/scenario/selectors'
import { ServiceDesignDocument } from 'src/service-design/sd-plan/document/types'
import { getCollection } from 'src/service-design/shared/document/selectors'
import * as LocationChangeoverLock from 'src/service-design/shared/models/changeover-lock/location'
import * as TrainChangeoverLock from 'src/service-design/shared/models/changeover-lock/train'
import * as LocationChangeover from 'src/service-design/shared/models/changeover/location'
import * as TrainChangeover from 'src/service-design/shared/models/changeover/train'
import * as CompoundShift from 'src/service-design/shared/models/compound-shift'
import * as CrewPool from 'src/service-design/shared/models/crew-pool'
import * as CustomTask from 'src/service-design/shared/models/custom-task'
import * as EmptyWagonEvent from 'src/service-design/shared/models/empty-wagon-event'
import * as RosterHead from 'src/service-design/shared/models/linked-list-head/roster'
import * as LoadingWorkSplit from 'src/service-design/shared/models/loading-work-split'
import * as LocoEvent from 'src/service-design/shared/models/loco-event'
import * as Rdo from 'src/service-design/shared/models/rdo'
import * as RemoteRest from 'src/service-design/shared/models/remote-rest'
import * as ResourceAvailability from 'src/service-design/shared/models/resource-availability'
import * as ResourceUnavailability from 'src/service-design/shared/models/resource-unavailability'
import * as RosterLine from 'src/service-design/shared/models/roster-line'
import * as Service from 'src/service-design/shared/models/service'
import * as ServiceEvent from 'src/service-design/shared/models/service-event'
import * as ServiceLock from 'src/service-design/shared/models/service-lock'
import * as Shift from 'src/service-design/shared/models/shift'
import * as CustomTaskAssignment from 'src/service-design/shared/models/shift-assignment/custom-task'
import * as DriverAssignment from 'src/service-design/shared/models/shift-assignment/driver'
import * as LoadingAssignment from 'src/service-design/shared/models/shift-assignment/loading'
import * as LocalAssignment from 'src/service-design/shared/models/shift-assignment/local'
import * as UncrewedLegTask from 'src/service-design/shared/models/shift-assignment/uncrewed-leg-task'
import * as ShiftLine from 'src/service-design/shared/models/shift-line'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as StartLegTaskOffset from 'src/service-design/shared/models/start-leg-task-offset'
import * as TemplateLeg from 'src/service-design/shared/models/template-leg'
import * as TrainBlock from 'src/service-design/shared/models/train-block'
import * as TrainStart from 'src/service-design/shared/models/train-start'
import * as TrainTemplate from 'src/service-design/shared/models/train-template'
import * as WagonAllocation from 'src/service-design/shared/models/wagon-allocation'
import * as WagonSet from 'src/service-design/shared/models/wagon-set'
import * as WorkingLocoLock from 'src/service-design/shared/models/working-loco-lock'
import * as YardBlock from 'src/service-design/shared/models/yard-block'
import * as YardBlockAssignment from 'src/service-design/shared/models/yard-block-assignment'

// NOTE: These selectors shouldn't really be here but by putting them here
// we're able to simplify calls to getDocumentGraphFactory which puts
// us in a better place to depreciate it.

const EMPTY = {}

export const getUserPreferences = state => state.userPreferences

export const getFatigueScores = state =>
  state.fatigue ? state.fatigue.shiftlines : EMPTY

export const getExternals = createStructuredSelector({
  preferences: getUserPreferences,
  fatigue: getFatigueScores,
})

export const getServiceDesignSingletons = state =>
  getCollection(state, 'service-design', 'singletons')

const getPlanDocumentGraph = createStructuredSelector({
  singletons: getServiceDesignSingletons,
  compoundshifts: CompoundShift.values,
  crewpools: CrewPool.values,
  customtasks: CustomTask.values,
  customtaskassignments: CustomTaskAssignment.values,
  driverassignments: DriverAssignment.values,
  emptywagonevents: EmptyWagonEvent.values,
  remoterests: RemoteRest.values,
  loadingassignments: LoadingAssignment.values,
  loadingworksplits: LoadingWorkSplit.values,
  localassignments: LocalAssignment.values,
  locationchangeovers: LocationChangeover.values,
  locationchangeoverlocks: LocationChangeoverLock.values,
  locoevents: LocoEvent.values,
  rdos: Rdo.values,
  resourceavailabilities: ResourceAvailability.values,
  resourceunavailabilities: ResourceUnavailability.values,
  rosterheads: RosterHead.values,
  rosterlines: RosterLine.values,
  services: Service.values,
  serviceevents: ServiceEvent.values,
  servicelocks: ServiceLock.values,
  shiftlines: ShiftLine.values,
  shifts: Shift.values,
  startlegtaskoffsets: StartLegTaskOffset.values,
  startlegs: StartLeg.values,
  templatelegs: TemplateLeg.values,
  trainblocks: TrainBlock.values,
  trainchangeovers: TrainChangeover.values,
  trainchangeoverlocks: TrainChangeoverLock.values,
  trainstarts: TrainStart.values,
  traintemplates: TrainTemplate.values,
  uncrewedlegtasks: UncrewedLegTask.values,
  wagonallocations: WagonAllocation.values,
  wagonsets: WagonSet.values,
  workinglocolocks: WorkingLocoLock.values,
  yardblocks: YardBlock.values,
  yardblockassignments: YardBlockAssignment.values,
})

export const getDocumentGraph = createSelector(
  getScenarioDocumentGraph,
  getPlanDocumentGraph,
  (scenario, plan) => ({
    ...scenario,
    ...plan,
    singletons: { ...scenario.singletons, ...plan.singletons },
  }),
)

export const getScenarioData = (state: any): ScenarioDocument =>
  state.documents.hasOwnProperty('scenario') && state.documents.scenario.data

export const getServiceDesignData = (state: any): ServiceDesignDocument =>
  state.documents.hasOwnProperty('service-design') &&
  state.documents['service-design'].data

export const getServiceDesignName = (state): ServiceDesignDocument =>
  state.documents['service-design'] &&
  state.documents['service-design'].meta.name

export const getRevisionData = createStructuredSelector({
  scenario: getScenarioData,
  'service-design': getServiceDesignData,
})
