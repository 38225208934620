export class LogMessage {
  static dedupe = false

  static type = 'service-design::Log Message'

  static message = 'service-design::Log Message'

  constructor(public context: object, public derived: object = {}) {}

  get dedupe(): boolean {
    return (this.constructor as any).dedupe
  }

  get id(): string {
    return `${Object.values(this.context)
      .map(o => o.id)
      .join('-')}-${this.type}`
  }

  get type(): string {
    return (this.constructor as any).type
  }

  get message(): string {
    return (this.constructor as any).message
  }

  get i18nMessage(): [string, any] {
    return [this.message, { ...this.context, ...this.derived }]
  }
}
