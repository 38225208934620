import { Duration as LuxonDuration } from 'luxon'
import moment from 'moment'

import * as constants from 'src/service-design/shared/constants'

import { Delta } from './delta'
import { Duration } from './duration'
import { EpochTime } from './epoch-time'

export const DAY_DURATION = Duration.fromSeconds(constants.SECONDS_PER_DAY)
export const DAY_DELTA = Delta.fromSeconds(constants.SECONDS_PER_DAY)
export const WEEK_DURATION = Duration.fromSeconds(constants.SECONDS_PER_WEEK)
export const WEEK_DELTA = Delta.fromSeconds(constants.SECONDS_PER_WEEK)
export const END_OF_WEEK_EPOCH_TIME = EpochTime.fromSeconds(
  constants.SECONDS_PER_WEEK,
)

const zeroPad = (value: number, length: number): string =>
  `${value}`.padStart(length, '00')

const hoursMinutes = (seconds: number, unwrapped = false): number[] => {
  // converts number of seconds to a number of minutes and the total hours
  // number of hours is not normalized to be under 24.
  const secondsOffset = unwrapped
    ? seconds
    : seconds % constants.SECONDS_PER_ROSTER
  const s = secondsOffset % constants.SECONDS_PER_MINUTE

  const minutes = (secondsOffset - s) / constants.SECONDS_PER_MINUTE
  const m = minutes % constants.MINUTES_PER_HOUR

  const hours = (minutes - m) / constants.MINUTES_PER_HOUR
  return [hours, m]
}

export const normalize = (seconds: number): number =>
  (seconds + constants.SECONDS_PER_ROSTER) % constants.SECONDS_PER_ROSTER

export const offsetTime = (offset: number): string => {
  const [hours, m] = hoursMinutes(normalize(offset))
  const h = hours % constants.HOURS_PER_DAY

  const pm = zeroPad(m, 2)
  const ph = zeroPad(h, 2)

  return `${ph}:${pm}`
}

export const offsetString = (offset: number): string => {
  const [hours, m] = hoursMinutes(normalize(offset))
  const h = hours % constants.HOURS_PER_DAY

  const d = (hours - h) / constants.HOURS_PER_DAY

  const pm = zeroPad(m, 2)
  const ph = zeroPad(h, 2)
  const days = constants.DAYS
  return `${days[d]} ${ph}:${pm}`
}

export const durationString = (
  seconds: number,
  space: boolean = false,
): string => {
  const [h, m] = hoursMinutes(Math.abs(seconds), true)
  return `${seconds < 0 ? '-' : ''}${zeroPad(h, 2)}h${
    space ? ' ' : ''
  }${zeroPad(m, 2)}m`
}

export const timeOfWeekToDayAndTime = (
  offset: number,
): { dayOffset: number; time: LuxonDuration } => {
  const duration = LuxonDuration.fromObject({ seconds: offset })
  const dayOffset = parseInt(
    LuxonDuration.fromObject({ seconds: offset }).toFormat('d'),
    10,
  )
  const time = duration.minus({ days: dayOffset }).normalize()

  return { dayOffset, time }
}

export const timeOfDayString = (offset: number): string => {
  const { time } = timeOfWeekToDayAndTime(offset)
  return time.toFormat('hh:mm')
}

export const dayOffsetString = (offset: number): string => {
  const { dayOffset } = timeOfWeekToDayAndTime(offset)

  if (dayOffset > 0) {
    return `+${dayOffset}d`
  }
  if (dayOffset < 0) {
    return `${dayOffset}d`
  }

  return ''
}

export const intersectionExclusive = (
  [t1Start, t1End]: [number, number],
  [t2Start, t2End]: [number, number],
): boolean => t1Start < t2End && t1End > t2Start

export const intersectionExclusiveWithNegative = (
  t1: [number, number],
  [t2Start, t2End]: [number, number],
): boolean =>
  intersectionExclusive(
    t1,
    t2Start > t2End ? [t2End, t2Start] : [t2Start, t2End],
  )

export const intersectionInclusive = (
  [t1Start, t1End]: [number, number],
  [t2Start, t2End]: [number, number],
): boolean => t1Start <= t2End && t1End >= t2Start

export const toUtc = (offset: number, timezoneOffset: number): number =>
  offset - timezoneOffset

export const snapTo = (normalized: number, near: number): number =>
  Math.round((near - normalized) / constants.SECONDS_PER_WEEK) *
    constants.SECONDS_PER_WEEK +
  normalized

export const timeToDelta = (
  initialDelta: number,
  initialTime: number,
  newTime: number = initialTime,
): number => {
  const boundedTime = snapTo(newTime, initialTime - initialDelta)
  return initialDelta + (boundedTime - initialTime)
}

export const inBoundsUnWrapped = (
  [boundStart, boundEnd]: [number, number],
  [tStart, tEnd]: [number, number],
): boolean => tStart >= boundStart && tEnd <= boundEnd

export const inBounds = (
  [boundStart, boundEnd]: [number, number],
  [tStart, tEnd]: [number, number],
): boolean => {
  let normbStart = normalize(boundStart)
  let normbEnd = boundEnd

  const normtStart = normalize(tStart)
  let normtEnd = tEnd

  // Bound defined as in next week
  if (
    boundStart >= constants.SECONDS_PER_WEEK &&
    boundEnd >= constants.SECONDS_PER_WEEK
  ) {
    normbEnd = normalize(boundEnd)
  }

  // task defined as in next week
  if (
    tStart >= constants.SECONDS_PER_WEEK &&
    tEnd >= constants.SECONDS_PER_WEEK
  ) {
    normtEnd = normalize(tEnd)
  }

  // Is crossing over the week boundary
  if (
    boundStart < constants.SECONDS_PER_WEEK &&
    boundEnd > constants.SECONDS_PER_WEEK
  ) {
    const result = inBoundsUnWrapped(
      [normbStart, normbEnd],
      [normtStart, normtEnd],
    )

    if (result) {
      return true
    }

    return inBoundsUnWrapped(
      [normbStart, normbEnd],
      [
        normtStart + constants.SECONDS_PER_WEEK,
        normtEnd + constants.SECONDS_PER_WEEK,
      ],
    )
  }

  if (normbStart > normbEnd) {
    normbStart -= constants.SECONDS_PER_WEEK
  }

  return inBoundsUnWrapped([normbStart, normbEnd], [normtStart, normtEnd])
}

export const roundToMinute = (seconds: number): number =>
  Math.round(seconds / constants.SECONDS_PER_MINUTE) *
  constants.SECONDS_PER_MINUTE

export function wrappedDuration(
  startTime: number,
  endTime: number,
  lengthOfWeek: number = constants.SECONDS_PER_ROSTER,
): number {
  if (startTime > endTime) {
    return endTime + lengthOfWeek - startTime
  }

  return endTime - startTime
}

export const secondsToTimeOfDay = (
  seconds: number,
): { time: number; day: number } => {
  if (Number.isInteger(seconds)) {
    const time = seconds % constants.SECONDS_PER_DAY
    const dayPart = seconds - time

    const day = dayPart / constants.SECONDS_PER_DAY
    return { time, day }
  }
  return null
}

export const dayTimeToSeconds = (day: number, time: number): number => {
  if (!Number.isInteger(day) || !Number.isInteger(time)) {
    return undefined
  }

  return constants.SECONDS_PER_DAY * day + time
}

export const inBoundsOfDay = (
  [boundStart, boundEnd]: [number, number],
  [tStart, tEnd]: [number, number],
): boolean => {
  const { day } = secondsToTimeOfDay(tStart)

  const offsetBoundStart = dayTimeToSeconds(day, boundStart)
  let offsetBoundEnd = dayTimeToSeconds(day, boundEnd)

  if (boundEnd < boundStart) {
    offsetBoundEnd = dayTimeToSeconds(day + 1, boundEnd)
  }

  return inBoundsUnWrapped([offsetBoundStart, offsetBoundEnd], [tStart, tEnd])
}

const PAY_MULTIPLIERS = [
  [dayTimeToSeconds(0, 0), constants.PENALTY_SUNDAY],
  [dayTimeToSeconds(1, 0), constants.PENALTY_OUTSIDE_WEEKDAY],
  [
    dayTimeToSeconds(1, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(1, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [
    dayTimeToSeconds(2, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(2, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [
    dayTimeToSeconds(3, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(3, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [
    dayTimeToSeconds(4, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(4, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [
    dayTimeToSeconds(5, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(5, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [dayTimeToSeconds(6, 0), constants.PENALTY_SATURDAY],
  [dayTimeToSeconds(7, 0), constants.PENALTY_SUNDAY],
  [dayTimeToSeconds(8, 0), constants.PENALTY_OUTSIDE_WEEKDAY],
  [
    dayTimeToSeconds(8, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(8, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [
    dayTimeToSeconds(9, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(9, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [
    dayTimeToSeconds(10, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(10, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [
    dayTimeToSeconds(11, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(11, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [
    dayTimeToSeconds(12, constants.SECONDS_PER_HOUR * 6),
    constants.PENALTY_NORMAL_WEEKDAY,
  ],
  [
    dayTimeToSeconds(12, constants.SECONDS_PER_HOUR * 18),
    constants.PENALTY_OUTSIDE_WEEKDAY,
  ],
  [dayTimeToSeconds(13, 0), constants.PENALTY_SATURDAY],
  [dayTimeToSeconds(14, 0), constants.PENALTY_SUNDAY],
]

// Adjusts penalty multiplier to account for leave accrued and taken at the
// loading rate rather than the current penalty multiplier.
export const adjustPenaltyForLeave = (
  penaltyMultiplier: number,
  annualLeaveLoading: number,
  annualLeavePercentage: number,
): number =>
  penaltyMultiplier * (1 - annualLeavePercentage) +
  (1 + annualLeaveLoading / 100) * annualLeavePercentage -
  1

export const penaltyMultiplier = (
  startTime: number,
  endTime: number,
): number => {
  let totalMultiplier = 0
  let upper: number
  let lower: number
  for (let i = 0; i < PAY_MULTIPLIERS.length - 1; i++) {
    const [lowerBound, multiplier] = PAY_MULTIPLIERS[i]
    const [upperBound] = PAY_MULTIPLIERS[i + 1]
    lower = Math.max(lowerBound, startTime)
    upper = Math.min(upperBound, endTime)

    if (upper > lower) {
      const percentage = (upper - lower) / (endTime - startTime)
      totalMultiplier += multiplier * percentage
    }
  }
  return totalMultiplier
}

export const convertSecToTime = (seconds: number, offset: number): Date =>
  moment(offset)
    .add(seconds, 'seconds')
    .toDate()

export { CyclicTime } from './cyclic-time'
export { Interval } from './interval'
export { TimeOfDay } from './time-of-day'
export { Duration, Delta, EpochTime }
