import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { TemplateLeg } from 'src/service-design/shared/models/template-leg'
import { TrainBlock } from 'src/service-design/shared/models/train-block'
import { YardBlock } from 'src/service-design/shared/models/yard-block'

export class InvalidYardBlockAssignmentOrigin extends LogMessage {
  static type = 'service-design::Invalid yard block assignment at origin'

  static message =
    "service-design::{{entity.yardBlock.name}}'s origin is {{entity.yardBlock.origin.code}} but is being assigned to {{entity.trainBlock.name}} at {{entity.departingLeg.origin.code}}."

  static check({
    yardBlock,
    departingLeg,
  }: {
    yardBlock: YardBlock
    departingLeg: TemplateLeg
  }) {
    return yardBlock.origin !== departingLeg.origin
  }
}

export class InvalidYardBlockAssignmentLegs extends LogMessage {
  static type =
    'service-design::Invalid yard block is assigned after train block set out'

  static message =
    'service-design::{{entity.yardBlock.name}} is assigned to leg {{entity.departingLeg.legNum}} of {{entity.departingLeg.template.name}} which is after {{entity.trainBlock.name}} is set out after arriving on leg {{entity.trainBlock.arrivingLeg.legNum}}.'

  static check({
    trainBlock,
    departingLeg,
  }: {
    trainBlock: TrainBlock
    departingLeg: TemplateLeg
  }) {
    return departingLeg.legNum > trainBlock.templateLeg.legNum
  }
}

export class InvalidYardBlockAssignmentDestination extends LogMessage {
  static type = 'service-design::Invalid yard block assignment at destination'

  static message =
    "service-design::{{entity.yardBlock.name}}'s destination does not match {{entity.trainBlock.name}}'s set out location."

  static check({
    trainBlock,
    yardBlock,
  }: {
    trainBlock: TrainBlock
    yardBlock: YardBlock
  }) {
    return trainBlock.dest !== yardBlock.destination
  }
}

export default {
  warnings: flags.blocks
    ? [
        InvalidYardBlockAssignmentOrigin,
        InvalidYardBlockAssignmentLegs,
        InvalidYardBlockAssignmentDestination,
      ]
    : [],
}
