import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { ResourceUnavailability } from './model'

export const resourceUnavailabilityFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.resourceunavailabilities.map(data => {
      const item = new ResourceUnavailability(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec(
    (resourceUnavailability: ResourceUnavailability, state: any) => ({
      availability: registry
        .provide('resourceAvailabilityRepo')(state)
        .byId(resourceUnavailability.availabilityId),
    }),
  ),
)
