import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { rosterHeadFactory } from './factory'
import { IRosterHeadRepo, RosterHeadRepo } from './repo'

const rosterHeadRepo = defaultMemoize(
  (input: FactoryInput) => new RosterHeadRepo(rosterHeadFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  rosterHeadRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type RosterHeadRepoReg = {
  rosterHeadRepo: FromDocumentFactory<FactoryInput, IRosterHeadRepo>
}

export function setup<R extends RosterHeadRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('rosterHeadRepo', rosterHeadRepo)

  relationships.addConstraints('rosterheads', {
    unique: [['parentId'], ['headId']],
    rels: [
      {
        collection: 'crewpools',
        foreign: 'parentId',
        name: 'parent',
      },
      {
        collection: 'rosterlines',
        foreign: 'headId',
        name: 'head',
      },
    ],
  })
}

export { RosterHead } from './model'
