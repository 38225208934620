import { flatMap, sumBy } from 'lodash'

import {
  SECONDS_PER_WEEK,
  SECONDS_PER_HOUR,
} from 'src/service-design/shared/constants'
import { CompoundShift } from 'src/service-design/shared/models/compound-shift'
import { CrewPool } from 'src/service-design/shared/models/crew-pool'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { RDO } from 'src/service-design/shared/models/rdo'
import { Shift } from 'src/service-design/shared/models/shift'
import { ShiftLine } from 'src/service-design/shared/models/shift-line'
import {
  Duration,
  END_OF_WEEK_EPOCH_TIME,
  EpochTime,
} from 'src/service-design/shared/utils/dates'

import validators, {
  ShiftExceedsFatigueLimit,
  TemporalWarning,
} from './validators'

export class InvalidCycle extends Error {}

interface Attrs {
  id: string
  parentId: string
  nextId: string
  relief: boolean
}

interface Rels {
  next: RosterLine
  prev: RosterLine
  parent: CrewPool
  shiftLines: ShiftLine[]
  RDOs: RDO[]
}

class RosterLine extends Mapper {
  static validators = validators

  /**
   * A RosterLine represents a line in a CrewPool's roster. See CrewPool.
   *
   * Related models:
   * - `CrewPool`;
   * - `Shift`;
   * - `RDO`;
   * - `ShiftLine`;
   * - `RosterHead`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} parentId - The id of the CrewType this pool represents.
   * @param {string} nextId - The id of the CrewType this pool represents.
   * @param {boolean} relief - If true, this represents a relief line.
   **/
  constructor({ id, parentId, nextId, relief }: Attrs) {
    super()
    this.id = id
    this.parentId = parentId
    this.nextId = nextId
    this.relief = relief
  }

  setRels({ next, prev, parent, shiftLines = [], RDOs = [] }: Rels) {
    this.next = next
    this.prev = prev
    this.parent = parent
    this.shiftLines = shiftLines
    this.RDOs = RDOs
  }

  get num(): number {
    return this.crewPool.cycle.indexOf(this) + 1
  }

  get crewPoolId(): string {
    return this.parentId
  }

  get crewPool(): CrewPool {
    return this.parent
  }

  get maxFatigue(): number {
    return Math.max(
      0,
      ...this.shiftLines.map(sl => sl.fatigue).filter(x => x !== undefined),
    )
  }

  get cycle(): RosterLine[] {
    let cur: RosterLine = this
    const cycle = new Set([cur])
    while (!cycle.has(cur.next)) {
      cur = cur.next
      cycle.add(cur)
    }
    if (cur.next !== this) {
      throw new InvalidCycle()
    }
    return [...cycle]
  }

  get dutyHours(): number {
    const duty = this.relief
      ? this.crewPool.type.dutyPerWeekSecsVO
      : Duration.sum(...this.shiftLines.map(line => line.shift.durationVO))

    return duty.toSeconds() / SECONDS_PER_HOUR
  }

  static buildCycle(line: RosterLine, count: number): RosterLine[] {
    const cycle = []
    for (let i = 0, cur = line; i < count; i += 1, cur = cur.next) {
      cycle.push(cur)
    }
    return cycle
  }

  get weekendRDOCycle(): RosterLine[] {
    return RosterLine.buildCycle(this, this.maxWeeksBetweenWeekendRDOs)
  }

  get dutyCycle(): RosterLine[] {
    return RosterLine.buildCycle(this, this.weeksInDutyCycle)
  }

  get dutyCycleHours(): number {
    return sumBy(this.dutyCycle, x => x.dutyHours)
  }

  get dutyPercentOfNominalCycle(): number {
    return this.dutyCycleHours / this.crewPool.type.nominalDutyCycleHours
  }

  get shifts(): Shift[] {
    return this.shiftLines.map(sl => sl.shift)
  }

  rdoForDay(day: number): RDO | undefined {
    return this.RDOs.find(rdo => rdo.day === day)
  }

  get numRDOs(): number {
    return this.relief && !this.RDOs.length
      ? this.crewPool.type.targetRDOsPerLine
      : this.RDOs.length
  }

  get temporalWarnings(): TemporalWarning[] {
    return flatMap(
      validators.temporal.filter(
        v => !this.preferences.warningsBlacklist.includes(v.type),
      ),
      v => v.check(this),
    )
  }

  get fatigueWarnings(): LogMessage[] {
    return !this.preferences.warningsBlacklist.includes(
      ShiftExceedsFatigueLimit.type,
    )
      ? this.shifts
          .map(shift => ShiftExceedsFatigueLimit.build(shift))
          .filter(Boolean)
      : []
  }

  get warnings(): LogMessage[] {
    if (!this._warnings) {
      this._warnings = [
        ...super.warnings,
        ...this.temporalWarnings,
        ...this.fatigueWarnings,
      ]
    }
    return this._warnings
  }

  static getValidatorTree(): typeof LogMessage[] {
    return [...super.getValidatorTree(), ...validators.temporal]
  }

  canFit(shift: Shift): boolean {
    const line = this
    return !validators.temporal.some(v => v.check(line, shift).length > 0)
  }

  get startsWithWeekendRDO(): boolean {
    const sunday = this.rdoForDay(0)
    return Boolean(sunday && sunday.weekend)
  }

  get rolloverShifts(): Shift[] {
    return this.prev.shifts.filter(s =>
      s.signOffLocalVO
        .makeLater(this.minHomeRestSecsVO)
        .isStrictlyLater(END_OF_WEEK_EPOCH_TIME),
    )
  }

  get rollbackShifts(): Shift[] {
    return this.next.shifts.filter(s =>
      s.signOnLocalVO.isStrictlyEarlier(
        EpochTime.epoch.makeLater(this.minHomeRestSecsVO),
      ),
    )
  }

  get compoundShifts(): { compoundShift: CompoundShift; offset: number }[] {
    // don't render the same compound shift twice unless it's been offset
    // by rollover/back or week offsets
    const arr = []
    const all = [this.rolloverShifts, this.shifts, this.rollbackShifts]
    const seen = new Set()
    all.forEach((lineShifts, index) => {
      lineShifts.forEach(shift => {
        const shiftOffset = shift.compoundShift.offsetFor(shift)
        const offset = (index - 1) * SECONDS_PER_WEEK - shiftOffset
        const key = `${shift.compoundShift.id}T${offset}`
        if (!seen.has(key)) {
          arr.push({
            compoundShift: shift.compoundShift,
            offset,
          })
          seen.add(key)
        }
      })
    })
    return arr
  }

  get rolloverWarnings(): TemporalWarning[] {
    return this.prev.temporalWarnings.filter(
      s => s.endTimeLocal > SECONDS_PER_WEEK,
    )
  }

  get weeksInDutyCycle(): number {
    return this.crewPool.type.weeksInDutyCycle
  }

  /**
   * @deprecated
   */
  get minHomeRestSecs(): number {
    return this.minHomeRestSecsVO.toSeconds()
  }

  get minHomeRestSecsVO(): Duration {
    return this.crewPool.type.minHomeRestSecsVO
  }

  /**
   * @deprecated
   */
  get reliefBufferDuration(): number {
    return this.reliefBufferDurationVO.toSeconds()
  }

  get reliefBufferDurationVO(): Duration {
    return this.crewPool.type.reliefBufferSecsVO
  }

  get maxWeeksBetweenWeekendRDOs(): number {
    return this.crewPool.type.maxWeeksBetweenWeekendRDOs
  }
}

interface RosterLine extends Attrs, Rels {}
export { RosterLine }
