import { blank } from 'src/service-design/scenario/document'
import {
  Schema,
  toDuration,
  fromDuration,
} from 'src/service-design/shared/exporter/schema'

export const AccessGroup = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
])

export const LococlassAccessRight = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'accessGroupId',
    dumpFrom: 'accessGroupId',
  },
  {
    header: 'Access Group Name',
    dumpFrom: 'accessGroup.name',
    load: false,
  },
  {
    header: 'lococlassId',
    dumpFrom: 'lococlassId',
  },
  {
    header: 'Loco Class Name',
    dumpFrom: 'lococlass.name',
    load: false,
  },
  {
    header: 'Can Lead',
    dumpFrom: 'canLead',
  },
])

export const BusinessGroup = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
  {
    header: 'BG Color',
    dumpFrom: 'bgColor',
  }, // TODO: color validation
])

export const CargoType = new Schema(
  [
    {
      header: 'id',
      dumpFrom: 'id',
    },
    {
      header: 'name',
      dumpFrom: 'name',
    },
    {
      header: 'wagonId',
      dumpFrom: 'wagonId',
    },
    {
      header: 'Volume Unit',
      dumpFrom: 'volumeUnit',
      default: '',
      dump: x => (x === '' ? 't' : x),
      load: x => (x === 't' ? '' : x),
    },
  ],
  {
    hooks: {
      postLoad: obj => ({
        ...obj,
        usesTonnage: obj.volumeUnit === '',
      }),
    },
  },
)

export const Corridor = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'accessGroupId',
    dumpFrom: 'accessGroupId',
  },
  {
    header: 'Access Group Name',
    dumpFrom: 'accessGroup.name',
    load: false,
  },
  {
    header: 'Access Base Cost',
    dumpFrom: 'accessBaseCost',
  },
  {
    header: 'Access Per Tonne Cost',
    dumpFrom: 'accessPerTonneCost',
  },
  {
    header: 'Axle Load Limit',
    dumpFrom: 'axleLoadLimit',
  },
  {
    header: 'Distance',
    dumpFrom: 'distance',
  },
  {
    header: 'loc1Id',
    dumpFrom: 'loc1Id',
  },
  {
    header: 'loc1 Code',
    dumpFrom: 'loc1.code',
    load: false,
  },
  {
    header: 'loc2Id',
    dumpFrom: 'loc2Id',
  },
  {
    header: 'loc2 Code',
    dumpFrom: 'loc2.code',
    load: false,
  },
  {
    header: 'Loco Haul Cost',
    dumpFrom: 'locoHaulCost',
  },
  {
    header: 'Max Length',
    dumpFrom: 'maxLength',
  },
  {
    header: 'Max Locos Per Train',
    dumpFrom: 'maxLocosPerTrain',
  },
  {
    header: 'Max Passes',
    dumpFrom: 'maxPasses',
  },
  {
    header: 'Min Headway',
    dumpFrom: 'minHeadway',
    dump: toDuration,
    load: fromDuration,
  },
])

export const CrewType = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
  {
    header: 'locId',
    dumpFrom: 'locId',
  },
  {
    header: 'Sign On',
    dumpFrom: 'signOnSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Sign Off',
    dumpFrom: 'signOffSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Meal Break Duration',
    dumpFrom: 'mealBreakSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Meal Break Window Duration',
    dumpFrom: 'mealBreakWindowSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Meal Break Shift Duration',
    dumpFrom: 'mealBreakShiftSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Minimum Shift Duration',
    dumpFrom: 'minimumShiftSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Maximum Shift Duration',
    dumpFrom: 'maximumShiftSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Duty Per Week',
    dumpFrom: 'dutyPerWeekSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Is Driver',
    dumpFrom: 'isDriver',
  },
  {
    header: 'Base Salary',
    dumpFrom: 'baseSalary',
  },
  {
    header: 'APM Base Salary',
    dumpFrom: 'apmBaseSalary',
  },
  {
    header: 'Allowance',
    dumpFrom: 'allowance',
  },
  {
    header: 'On-cost Percentage',
    dumpFrom: 'onCostPercentage',
  },
  {
    header: 'Annual Leave',
    dumpFrom: 'annualLeaveSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Annual Leave Loading',
    dumpFrom: 'annualLeaveLoading',
  },
  {
    header: 'Weeks in Duty Cycle',
    dumpFrom: 'weeksInDutyCycle',
  },
  {
    header: 'Minimum Home Rest',
    dumpFrom: 'minHomeRestSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Target RDOs per line',
    dumpFrom: 'targetRDOsPerLine',
  },
  {
    header: 'Maximum Days Between RDOs',
    dumpFrom: 'maxDaysBetweenRDOs',
  },
  {
    header: 'Maximum Weeks Between Weekend RDOs',
    dumpFrom: 'maxWeeksBetweenWeekendRDOs',
  },
  {
    header: 'Relief Buffer',
    dumpFrom: 'reliefBufferSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Max FAID Score',
    dumpFrom: 'maxFAIDScore',
  },
  {
    header: 'Can Remotely Rest',
    dumpFrom: 'canRemotelyRest',
  },
  {
    header: 'Minimum Remote Rest Duration',
    dumpFrom: 'remoteRestMinDurationSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Remote Rest Meal Allowance Period',
    dumpFrom: 'remoteRestMealAllowancePeriodSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Remote Rest Meal Allowance Amount',
    dumpFrom: 'remoteRestMealAllowanceAmount',
  },
  {
    header: 'Remote Rest Min Shift Duration',
    dumpFrom: 'remoteRestMinimumShiftDurationSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Remote Rest Costing Min Duration',
    dumpFrom: 'remoteRestCostingMinDurationSecs',
    dump: toDuration,
    load: fromDuration,
  },
])

export const ForeignRailroad = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
  {
    header: 'BG Color',
    dumpFrom: 'bgColor',
  }, // TODO: color validation
])

export const LoadCategory = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
])

export const LoadTable = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'corridorId', dumpFrom: 'corridorId' },
  {
    header: 'loc1 Code',
    dumpFrom: 'corridor.loc1.code',
    load: false,
  },
  {
    header: 'loc2 Code',
    dumpFrom: 'corridor.loc2.code',
    load: false,
  },
  { header: 'trainTypeId', dumpFrom: 'trainTypeId' },
  {
    header: 'Train Type Name',
    dumpFrom: 'trainType.name',
    load: false,
  },
  { header: 'loadCategoryId', dumpFrom: 'loadCategory' },
  {
    header: 'Load Category Name',
    dumpFrom: 'loadCategoryObj.name',
    load: false,
  },
  { header: 'Forward Tonnage', dumpFrom: 'forwardTonnage' },
  { header: 'Reverse Tonnage', dumpFrom: 'reverseTonnage' },
  { header: 'Comment', dumpFrom: 'comment', default: '' },
])

export const Location = new Schema([
  { header: 'id', dumpFrom: 'id' },
  { header: 'timezoneId', dumpFrom: 'timezoneId' },
  {
    header: 'Timezone Name',
    dumpFrom: 'timezone.name',
    load: false,
  },
  { header: 'Name', dumpFrom: 'name' },
  { header: 'Code', dumpFrom: 'code' },
  {
    header: 'Attach/Detach Cost',
    dumpFrom: 'attachDetachCost',
  },
  {
    header: 'Dwell Allocation',
    dumpFrom: 'dwellAllowanceSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Max Occupying Trains',
    dumpFrom: 'maxOccupyingTrains',
  },
  {
    header: 'Max Train Length (m)',
    dumpFrom: 'maxTrainLength',
  },
  {
    header: 'Post-Arrival Duration',
    dumpFrom: 'postArrivalSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Pre-Departure Duration',
    dumpFrom: 'preDepartureSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Provision Duration',
    dumpFrom: 'provisionSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Demand Attach Duration',
    dumpFrom: 'serviceAttachSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Demand Detach Duration',
    dumpFrom: 'serviceDetachSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Out of Network',
    dumpFrom: 'outOfNetwork',
  },
  {
    header: 'Yard',
    dumpFrom: 'yard',
  },
  {
    header: 'Can Turn Locos',
    dumpFrom: 'canTurnLocos',
  },
  {
    header: 'Can Change Locos',
    dumpFrom: 'canChangeLocos',
  },
  {
    header: 'Allow Remote Rest',
    dumpFrom: 'allowRemoteRest',
  },
  {
    header: 'Remote Rest Cost',
    dumpFrom: 'remoteRestCost',
  },
])

export const LocationCargoCompatibility = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'cargoTypeId',
    dumpFrom: 'cargoTypeId',
  },
  {
    header: 'locationId',
    dumpFrom: 'locationId',
  },
  {
    header: 'Loading Rate Per Hour',
    dumpFrom: 'loadingRatePerHour',
  },
  {
    header: 'Unloading Rate Per Hour',
    dumpFrom: 'unloadingRatePerHour',
  },
])

export const Lococlass = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
  {
    header: 'loadCategoryId',
    dumpFrom: 'loadCategory',
  },
  {
    header: 'Load Category Name',
    dumpFrom: 'loadCategoryObj.name',
    load: false,
  },
  {
    header: 'Axle Load',
    dumpFrom: 'axleLoad',
  },
  {
    header: 'ECP Braking',
    dumpFrom: 'ecpBraking',
  },
  {
    header: 'BG Color',
    dumpFrom: 'bgColor',
  }, // TODO: color validation
  {
    header: 'Fixed Cost',
    dumpFrom: 'fixedCost',
  },
  {
    header: 'Horsepower',
    dumpFrom: 'horsepower',
  },
  {
    header: 'Length',
    dumpFrom: 'length',
  },
  {
    header: 'Weight',
    dumpFrom: 'weight',
  },
  {
    header: 'Working Hour Cost',
    dumpFrom: 'workingHourCost',
  },
  {
    header: 'Working Hour Fuel Burn',
    dumpFrom: 'workingHourFuelBurn',
  },
  {
    header: 'Working KM Cost',
    dumpFrom: 'workingKmCost',
  },
  {
    header: 'Working KM Fuel Burn',
    dumpFrom: 'workingKmFuelBurn',
  },
  {
    header: 'Comment',
    dumpFrom: 'comment',
    default: '',
  },
])

export const LVTrip = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'loc1Id',
    dumpFrom: 'loc1Id',
  },
  {
    header: 'loc2Id',
    dumpFrom: 'loc2Id',
  },
  {
    header: 'Distance KMs',
    dumpFrom: 'distanceKMs',
  },
  {
    header: 'Duration',
    dumpFrom: 'durationSecs',
    dump: toDuration,
    load: fromDuration,
  },
])

export const TrainType = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
  {
    header: 'Headway',
    dumpFrom: 'headwaySecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Clearance',
    dumpFrom: 'clearanceSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Max Tonnage',
    dumpFrom: 'maxTonnage',
  },
])

export const Timezone = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
  {
    header: 'Abbreviation',
    dumpFrom: 'abbreviation',
  },
  {
    header: 'Offset',
    dumpFrom: 'offset',
    dump: toDuration,
    load: fromDuration,
  },
])

export const TrainGraph = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Name',
    dumpFrom: 'name',
  },
])

export const TrainGraphLocation = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'trainGraphId',
    dumpFrom: 'trainGraphId',
  },
  {
    header: 'locationId',
    dumpFrom: 'locationId',
  },
  {
    header: 'Y Position',
    dumpFrom: 'yPosition',
  },
])

export const TransitTime = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'corridorId',
    dumpFrom: 'corridorId',
  },
  {
    header: 'loc1 Code',
    dumpFrom: 'corridor.loc1.code',
    load: false,
  },
  {
    header: 'loc2 Code',
    dumpFrom: 'corridor.loc2.code',
    load: false,
  },
  {
    header: 'trainTypeId',
    dumpFrom: 'trainTypeId',
  },
  {
    header: 'Train Type Name',
    dumpFrom: 'trainType.name',
    load: false,
  },
  {
    header: 'Forward Duration',
    dumpFrom: 'forwardDuration',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Reverse Duration',
    dumpFrom: 'reverseDuration',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Extra Forward Duration',
    dumpFrom: 'extraForwardDuration',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Extra Reverse Duration',
    dumpFrom: 'extraReverseDuration',
    dump: toDuration,
    load: fromDuration,
  },
])

export const WagonAccessRight = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'accessGroupId',
    dumpFrom: 'accessGroupId',
  },
  {
    header: 'Access Group Name',
    dumpFrom: 'accessGroup.name',
    load: false,
  },
  {
    header: 'wagonId',
    dumpFrom: 'wagonId',
  },
  {
    header: 'Wagon Name',
    dumpFrom: 'wagon.name',
    load: false,
  },
])

export const Wagon = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'code',
    dumpFrom: 'code',
  },
  {
    header: 'length',
    dumpFrom: 'length',
  },
  {
    header: 'Tare Weight',
    dumpFrom: 'tareWeight',
  },
  {
    header: 'Color',
    dumpFrom: 'bgColor',
  },
  {
    header: 'Cost Per Week',
    dumpFrom: 'fixedCost',
  },
  {
    header: 'Cost per working KM',
    dumpFrom: 'workingKmCost',
  },
  {
    header: 'Coupled Set Size',
    dumpFrom: 'coupledSetSize',
  },
  {
    header: 'Comment',
    dumpFrom: 'comment',
    default: '',
  },
])

export const WagonCargoCompatibility = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'cargoTypeId',
    dumpFrom: 'cargoTypeId',
  },
  {
    header: 'wagonId',
    dumpFrom: 'wagonId',
  },
  {
    header: 'volume',
    dumpFrom: 'volume',
  },
])

export const RouteKnowledge = new Schema([
  {
    header: 'id',
    dumpFrom: 'id',
  },
  {
    header: 'Crew Type Id',
    dumpFrom: 'crewTypeId',
  },
  {
    header: 'Corridor Id',
    dumpFrom: 'corridorId',
  },
])

export const Costs = new Schema([
  {
    header: 'Fuel',
    dumpFrom: 'fuel',
  },
])

export const Crew = new Schema([
  {
    header: 'Changeover Duration',
    dumpFrom: 'changeoverSecs',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'Single RDO Duration',
    dumpFrom: 'singleRDODuration',
    dump: toDuration,
    load: fromDuration,
  },
  {
    header: 'First RDO in Sequence Duration',
    dumpFrom: 'firstRDOSequenceDuration',
    dump: toDuration,
    load: fromDuration,
  },
])

export const LV = new Schema(
  [
    {
      header: 'Fixed Cost',
      dumpFrom: 'fixedCost',
    },
    {
      header: 'Cost $/km',
      dumpFrom: 'kmCost',
    },
    {
      header: 'Fuel Burn l/km',
      dumpFrom: 'kmFuelBurn',
    },
    {
      header: 'Fuel Price',
      dumpFrom: 'fuelPrice',
    },
    {
      header: 'Color',
      dumpFrom: 'bgColor',
    },
  ],
  {
    hooks: {
      postLoad: obj => ({ ...blank.singletons.lv, ...obj }),
    },
  },
)
