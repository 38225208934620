import * as constants from 'src/service-design/shared/constants'
import { LocoEvent } from 'src/service-design/shared/models/loco-event'
import { Lococlass } from 'src/service-design/shared/models/lococlass'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { WorkingLocoLock } from 'src/service-design/shared/models/working-loco-lock'
import { count, compareMaps } from 'src/service-design/shared/utils/math'

interface Attrs {
  workingLocoLock?: WorkingLocoLock
}

interface Rels {
  startLeg: StartLeg
  allocations: LocoEvent[]
}

class Consist extends Mapper {
  _allocations: LocoEvent[]
  static defaults = {
    workingLocoLock: false,
  }

  /**
   * A Consists represent a set of Locos allocated to the StartLeg of a
   * TrainStart.
   *
   * TERMINOLOGY: historically we used the term 'consist' to describe the set
   * of locos associated with a train. This was before the tools included
   * wagons, blocks and demands. In reality, a rail operator is more likely to
   * use the term consist to describe the complele set of rolling stock (locos
   * and wagons) that form the train.
   *
   * Related models:
   * - `ForeignRailroad`;
   * - `Lococlass`;
   * - `StartLeg`;
   * - `TemplateLeg`;
   * - `TrainStart`.
   *
   * @constructor
   * @param {WorkingLocoLock | null} workingLocoLock - The entity id.
   * @param {LocoEvent[]} allocations - The id of the Lococlass.
   * @param {StartLeg} startLeg -
   *
   **/
  constructor({ workingLocoLock, ...rels }: Attrs & Rels) {
    super()
    this.workingLocoLock = workingLocoLock
    this.applyDefaults()
    this.setRels(rels)
  }

  setRels({ allocations = [], startLeg }: Rels) {
    this._allocations = allocations
    this.startLeg = startLeg
  }

  get startLegId(): string {
    return this.startLeg.id
  }

  get allocations(): LocoEvent[] {
    return this._allocations.filter(a => a.lococlass)
  }

  get brokenAllocations(): LocoEvent[] {
    return this._allocations.filter(a => !a.lococlass)
  }

  get allocationsUnfiltered(): LocoEvent[] {
    return this._allocations
  }

  get brokenWorkingAllocations(): LocoEvent[] {
    return this.brokenAllocations.filter(a => a.working)
  }

  get brokenHauledAllocations(): LocoEvent[] {
    return this.brokenAllocations.filter(a => !a.working)
  }

  get lockTypes(): Set<string> {
    const result = new Set<string>()
    if (this.workingLocoLock) {
      result.add(constants.LOCK_WORKING_LOCO)
    }
    return result
  }

  get workingLocos(): Lococlass[] {
    return this.workingAllocations.map(a => a.lococlass)
  }

  get hauledLocos(): Lococlass[] {
    return this.hauledAllocations.map(a => a.lococlass)
  }

  get locos(): Lococlass[] {
    return [...this.workingLocos, ...this.hauledLocos]
  }

  get locoDict(): Map<Lococlass, number> {
    return count(this.locos)
  }

  get numLocos(): number {
    return this.allocations.length
  }

  get locoIds(): string[] {
    return this.allocations.map(allocation => allocation.lococlassId).sort()
  }

  get length(): number {
    return this.allocations.reduce(
      (total, allocation) => total + allocation.lococlass.length,
      0,
    )
  }

  get weight(): number {
    return this.allocations.reduce(
      (total, allocation) => total + allocation.lococlass.weight,
      0,
    )
  }

  get workingLocoIds(): string[] {
    return this.workingAllocations
      .map(allocation => allocation.lococlassId)
      .sort()
  }

  get hauledLocoIds(): string[] {
    return this.hauledAllocations
      .map(allocation => allocation.lococlassId)
      .sort()
  }

  get workingAllocations(): LocoEvent[] {
    return this.allocations.filter(allocation => allocation.working)
  }

  get workingDict(): Map<Lococlass, number> {
    return count(this.workingLocos)
  }

  get numWorkingLocos(): number {
    return this.workingAllocations.length
  }

  get workingLocoIdDict(): Map<string, number> {
    return count(this.workingLocoIds)
  }

  get hauledLocoIdDict(): Map<string, number> {
    return count(this.hauledLocoIds)
  }

  get leadAllocations(): LocoEvent[] {
    return this.workingAllocations.filter(allocation =>
      allocation.lococlass.canLead(this.startLeg.accessGroup),
    )
  }

  get numLeadLocos(): number {
    return this.leadAllocations.length
  }

  get secondaryAllocations(): LocoEvent[] {
    const leads = this.leadAllocations
    return this.workingAllocations.filter(
      allocation => leads.indexOf(allocation) < 0,
    )
  }

  get hauledAllocations(): LocoEvent[] {
    return this.allocations.filter(allocation => !allocation.working)
  }

  get hauledDict(): Map<Lococlass, number> {
    return count(this.hauledLocos)
  }

  get numHauledLocos(): number {
    return this.hauledAllocations.length
  }

  get invalidLocos(): Lococlass[] {
    return [
      ...new Set(
        this.allocations
          .map(allocation => allocation.lococlass)
          .filter(lococlass => !lococlass.hasAccess(this.startLeg.accessGroup)),
      ),
    ]
  }

  get haulageCapacity() {
    return this.workingAllocations.reduce(
      (acc, allocation) =>
        acc +
        this.startLeg.loadCategoryPower(allocation.lococlass.loadCategoryObj),
      0,
    )
  }

  get axleLoad() {
    return Math.max(
      ...this.allocations.map(allocation => allocation.lococlass.axleLoad),
    )
  }

  get ecpBraking() {
    return this.workingAllocations.every(
      allocation => allocation.lococlass.ecpBraking,
    )
  }

  get missingLoadTables() {
    return [
      ...new Set(
        this.workingAllocations
          .map(allocation => allocation.lococlass)
          .filter(
            lococlass =>
              this.startLeg.loadCategoryPower(lococlass.loadCategoryObj) ===
              null,
          ),
      ),
    ]
  }

  isEquivalent(other) {
    return (
      compareMaps(this.workingLocoIdDict, other.workingLocoIdDict) &&
      compareMaps(this.hauledLocoIdDict, other.hauledLocoIdDict)
    )
  }
}

interface Consist extends Attrs, Rels {}
export { Consist }
