import Cookies from 'js-cookie'
import React from 'react'
import { Dropdown, Flag } from 'semantic-ui-react'

import { selectedLanguage } from 'src/i18n'
import { flags } from 'src/service-design/shared/flags'

const flagStyle = { marginRight: '0' }
const LANGUAGE_OPTIONS = [
  flags.languageOptions.en
    ? {
        text: <Flag style={flagStyle} name="us" />,
        flag: 'us',
        content: 'English (US)',
        key: 'en',
        value: 'en',
      }
    : null,
  flags.languageOptions['en-AU']
    ? {
        text: <Flag style={flagStyle} name="au" />,
        flag: 'au',
        content: 'English (AUS)',
        key: 'en-AU',
        value: 'en-AU',
      }
    : null,
  flags.languageOptions['es-MX']
    ? {
        text: <Flag style={flagStyle} name="mx" />,
        flag: 'mx',
        content: 'Español (MX)',
        key: 'es-MX',
        value: 'es-MX',
      }
    : null,
].filter(Boolean)

const handleChange = (e, { value }) => {
  if (value !== selectedLanguage()) {
    Cookies.set('lng', value)
    window.location.reload()
  }
}

export const LanguageChange = () => (
  <Dropdown
    className="nav-actions"
    defaultValue={selectedLanguage()}
    options={LANGUAGE_OPTIONS}
    onChange={handleChange}
  />
)
