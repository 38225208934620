import { Location, Action } from 'history'
import { isEqual } from 'lodash'
import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

import {
  formatParams,
  parseParams,
} from 'src/service-design/sd-plan/components/focus/params'
import * as constants from 'src/service-design/sd-plan/constants'

const EMPTY_FOCUS = {}

const getRawFocus = state => state.focus ?? EMPTY_FOCUS
const hasServiceDesignState = state =>
  state.documents.hasOwnProperty('service-design')

type Focus = { [attr: string]: string }
type FocusValidator = (state: any, id: string) => Focus

// the validation and expansion of focus attrs always results in new objects
// being created each time we check, so use a deep equality memoizer to
// ensure we don't thrash when the contents don't change
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export class FocusParamSchema {
  constructor(
    public readonly namespace: string,
    public registered: { [attr: string]: FocusValidator } = {},
  ) {
    this.getFocus = this.getFocus.bind(this)
  }

  register(attr: string, selector: FocusValidator): void {
    if (attr in this.registered) {
      throw new Error(`attr ${attr} already registered`)
    }
    this.registered[attr] = selector
  }

  parseParams(params: string) {
    return parseParams(params)[this.namespace] || {}
  }

  getNamespaceFocus = createSelector(
    getRawFocus,
    hasServiceDesignState,
    (focus, hasServiceDesign) => {
      // TODO this is awfully defensive; the FocusProvider is higher in the DOM than the thing that loads
      // the service design document into the store, but the so are the MODALS which need the context
      // provided by FocusProvider but aren't ever opened until after the document's in the store :-(
      if (!hasServiceDesign) {
        return EMPTY_FOCUS
      }
      return focus[this.namespace] ?? EMPTY_FOCUS
    },
  )

  // filter the supplied focus attr(s) to ensure they're even usable as focus
  getUsableFocusAttrs = createDeepEqualSelector(
    state => this.getNamespaceFocus(state),
    (namespace): [string, string][] => {
      const validAttrs = Object.keys(namespace).filter(
        attr => this.registered[attr],
      )
      return validAttrs.map(attr => [attr, namespace[attr]])
    },
  )

  // now validate the focus attrs, potentially annotating them with additional focus attrs
  getFocus = createDeepEqualSelector(
    state =>
      this.getUsableFocusAttrs(state).map(([attr, value]) =>
        this.registered[attr](state, value),
      ),
    results => Object.assign({}, ...results),
  )

  setFocusAction(attrs: FocusAttrs) {
    for (const attr of Object.keys(attrs)) {
      if (!(attr in this.registered)) {
        throw new Error(`${attr} is not a registered param`)
      }
    }
    return {
      type: 'SET_FOCUS',
      payload: { namespace: this.namespace, attrs },
    }
  }

  setFocus = (attr: string, value: string, context: FocusAttrs) => {
    if (!(attr in this.registered)) {
      throw new Error(`${attr} is not a registered param`)
    }
    return { [attr]: value, ...context }
  }

  mergeQueryParams(location, newFocus: { [x: string]: string }, url?: string) {
    const params = {
      ...parseParams(location.search),
      [this.namespace]: newFocus,
    }
    return `${url || location.pathname}?${formatParams(params)}`
  }
}

type FocusAttrs = { [name: string]: string }

export type FocusContextType = {
  schema: FocusParamSchema
}

export type UseFocusType = {
  focus: FocusAttrs
  setFocus: (attr: string, value: string, context?: FocusAttrs) => any
  clearFocus: () => any
  schema: FocusParamSchema
}

export const focusReducer = (state = {}, action) => {
  switch (action.type) {
    case constants.SET_FOCUS: {
      const { namespace, attrs } = action.payload
      return { ...state, [namespace]: attrs }
    }
    default:
      return state
  }
}

type Props = {
  schema: FocusParamSchema
  context: React.Context<FocusContextType>
  children: any
}

export const FocusProvider: React.FC<Props> = ({
  schema,
  context,
  children,
}: {
  schema: FocusParamSchema
  context: React.Context<FocusContextType>
  children: any
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLocationChange = React.useCallback(
    (location: Location, action: Action) => {
      /**
       * Only run on POP, which is when page starts, url is manually changed or
       * browser navigation buttons are pressed */
      if (action === 'POP') {
        const raw = schema.parseParams(location.search)
        dispatch(schema.setFocusAction(raw))
      }
    },
    [dispatch, schema],
  )

  useEffect(() => {
    handleLocationChange(history.location, history.action)
  }, [handleLocationChange, history.location, history.action])

  return <context.Provider value={{ schema }}>{children}</context.Provider>
}

export const useFocus = (Context: any): UseFocusType => {
  const history = useHistory()
  const { schema } = useContext(Context)

  return {
    focus: useSelector(schema.getFocus),

    // this is an action that must be dispatched by the user of setFocus
    // (it is not automatically dispatched so that it may be batched with other actions)
    setFocus: (attr: string, value: string, focusContext?: FocusAttrs) => {
      const newFocus = schema.setFocus(attr, value, focusContext)
      history.push(schema.mergeQueryParams(history.location, newFocus))
      return schema.setFocusAction(newFocus)
    },

    clearFocus: () => {
      history.push(schema.mergeQueryParams(history.location, {}))
      return schema.setFocusAction({})
    },

    schema,
  }
}
