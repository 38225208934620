import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { loadTableFactory } from './factory'
import { ILoadTableRepo, LoadTableRepo } from './repo'

const loadTableRepo = defaultMemoize(
  (input: FactoryInput) => new LoadTableRepo(loadTableFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  loadTableRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type LoadTableRepoReg = {
  loadTableRepo: FromDocumentFactory<FactoryInput, ILoadTableRepo>
}

export function setup<R extends LoadTableRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('loadTableRepo', loadTableRepo)

  relationships.addConstraints('loadtables', {
    unique: [['corridorId', 'trainTypeId', 'loadCategory']],
    rels: [
      {
        collection: 'corridors',
        foreign: 'corridorId',
        name: 'corridor',
      },
      {
        collection: 'traintypes',
        foreign: 'trainTypeId',
        name: 'trainType',
      },
      {
        collection: 'loadcategories',
        foreign: 'loadCategory',
        name: 'loadCategoryObj',
      },
    ],
  })
}

export { LoadTable } from './model'
