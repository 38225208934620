import { createSelector, createStructuredSelector } from 'reselect'

import { getCollection } from 'src/service-design/shared/document/selectors'
import * as AccessGroup from 'src/service-design/shared/models/access-group'
import * as BusinessGroup from 'src/service-design/shared/models/business-group'
import * as CargoType from 'src/service-design/shared/models/cargo-type'
import * as Corridor from 'src/service-design/shared/models/corridor'
import * as CrewType from 'src/service-design/shared/models/crew-type'
import * as ForeignRailroad from 'src/service-design/shared/models/foreign-railroad'
import * as LoadCategory from 'src/service-design/shared/models/load-category'
import * as LoadTable from 'src/service-design/shared/models/load-table'
import * as Location from 'src/service-design/shared/models/location'
import * as LocationCargoCompatibility from 'src/service-design/shared/models/location-cargo-compatibility'
import * as Lococlass from 'src/service-design/shared/models/lococlass'
import * as LococlassAccessRight from 'src/service-design/shared/models/lococlass-access-right'
import * as LvTrip from 'src/service-design/shared/models/lv-trip'
import * as RouteKnowledge from 'src/service-design/shared/models/route-knowledge'
import * as Timezone from 'src/service-design/shared/models/timezone'
import * as TrainGraph from 'src/service-design/shared/models/train-graph'
import * as TrainGraphLocation from 'src/service-design/shared/models/train-graph-location'
import * as TrainType from 'src/service-design/shared/models/train-type'
import * as TransitTimes from 'src/service-design/shared/models/transit-times'
import * as Wagon from 'src/service-design/shared/models/wagon'
import * as WagonAccessRight from 'src/service-design/shared/models/wagon-access-right'
import * as WagonCargoCompatibility from 'src/service-design/shared/models/wagon-cargo-compatibility'

export const getCurrentPath = state => state.router.location.pathname
export const getQueryString = state => state.router.location.search

export const getSingletons = state =>
  getCollection(state, 'scenario', 'singletons')
export const getRawAccessGroups = state =>
  getCollection(state, 'scenario', 'accessgroups')
export const getRawLococlassAccessRights = state =>
  getCollection(state, 'scenario', 'lococlassaccessrights')
export const getRawWagonAccessRights = state =>
  getCollection(state, 'scenario', 'wagonaccessrights')
export const getRawBusinessGroups = state =>
  getCollection(state, 'scenario', 'businessgroups')
export const getRawCargoTypes = state =>
  getCollection(state, 'scenario', 'cargotypes')
export const getRawCorridors = state =>
  getCollection(state, 'scenario', 'corridors')
export const getRawCrewTypes = state =>
  getCollection(state, 'scenario', 'crewtypes')
export const getRawForeignRailroads = state =>
  getCollection(state, 'scenario', 'foreignrailroads')
export const getRawLoadCategories = state =>
  getCollection(state, 'scenario', 'loadcategories')
export const getRawLoadTables = state =>
  getCollection(state, 'scenario', 'loadtables')
export const getRawLocations = state =>
  getCollection(state, 'scenario', 'locations')
export const getRawLococlasses = state =>
  getCollection(state, 'scenario', 'lococlasses')
export const getRawLVTrips = state =>
  getCollection(state, 'scenario', 'lvtrips')
export const getRawTrainTypes = state =>
  getCollection(state, 'scenario', 'traintypes')
export const getRawTimezones = state =>
  getCollection(state, 'scenario', 'timezones')
export const getRawTrainGraphLocations = state =>
  getCollection(state, 'scenario', 'traingraphlocations')
export const getRawTransitTimes = state =>
  getCollection(state, 'scenario', 'transittimes')
export const getRawWagons = state => getCollection(state, 'scenario', 'wagons')
export const getRawWagonCargoCompatibilities = state =>
  getCollection(state, 'scenario', 'wagoncargocompatibilities')
export const getRawLocationCargoCompatibilities = state =>
  getCollection(state, 'scenario', 'locationcargocompatibilities')
export const getRawRouteKnowledge = state =>
  getCollection(state, 'scenario', 'routeknowledge')

export const getDocumentGraph = createStructuredSelector({
  singletons: getSingletons,
  accessgroups: AccessGroup.values,
  businessgroups: BusinessGroup.values,
  cargotypes: CargoType.values,
  corridors: Corridor.values,
  crewtypes: CrewType.values,
  foreignrailroads: ForeignRailroad.values,
  loadcategories: LoadCategory.values,
  loadtables: LoadTable.values,
  locations: Location.values,
  locationcargocompatibilities: LocationCargoCompatibility.values,
  lococlassaccessrights: LococlassAccessRight.values,
  lococlasses: Lococlass.values,
  lvtrips: LvTrip.values,
  routeknowledge: RouteKnowledge.values,
  timezones: Timezone.values,
  traingraphs: TrainGraph.values,
  traingraphlocations: TrainGraphLocation.values,
  traintypes: TrainType.values,
  transittimes: TransitTimes.values,
  wagonaccessrights: WagonAccessRight.values,
  wagons: Wagon.values,
  wagoncargocompatibilities: WagonCargoCompatibility.values,
})

const locationOption = location => ({
  key: location.id,
  value: location.id,
  text: `${location.name}, ${location.code}`,
})

const option = item => ({
  key: item.id,
  value: item.id,
  text: item.name,
})

const sortByName = (a, b) => a.name.localeCompare(b.name)
const sortByCode = (a, b) => a.code.localeCompare(b.code)
export const getOrderedOptionsByName = items =>
  items.sort(sortByName).map(option)
export const getOrderedLocationOptions = locations =>
  locations.sort(sortByName).map(locationOption)
export const getOrderedWagons = createSelector(getRawWagons, wagons =>
  wagons.sort(sortByCode),
)
export const getSelectedCorridorId = createSelector(
  getQueryString,
  queryString => new URLSearchParams(queryString).get('corridorId'),
)

export const getUnselectedAccessGroupsForLococlass = createSelector(
  getRawAccessGroups,
  getRawLococlassAccessRights,
  (accessGroups, accessRights) => (lococlassId, accessRightId = null) => {
    const selectedAccessRights = accessRights
      .filter(a => a.lococlassId === lococlassId && a.id !== accessRightId)
      .map(a => a.accessGroupId)

    return getOrderedOptionsByName(
      accessGroups.filter(a => !selectedAccessRights.includes(a.id)),
    )
  },
)

export const getUnselectedAccessGroupsForWagon = createSelector(
  getRawAccessGroups,
  getRawWagonAccessRights,
  (accessGroups, accessRights) => (wagonId, accessRightId = null) => {
    const selectedAccessRights = accessRights
      .filter(a => a.wagonId === wagonId && a.id !== accessRightId)
      .map(a => a.accessGroupId)

    return getOrderedOptionsByName(
      accessGroups.filter(a => !selectedAccessRights.includes(a.id)),
    )
  },
)

export const getUnselectedWagons = (wagonId, selectedCargoTypeId = null) =>
  createSelector(
    getRawWagons,
    getRawWagonCargoCompatibilities,
    (wagons, compatibilities) => {
      const selectedCompatibilities = compatibilities
        .filter(
          c => c.wagonId !== wagonId && c.cargoTypeId === selectedCargoTypeId,
        )
        .map(c => c.wagonId)

      return wagons.filter(c => !selectedCompatibilities.includes(c.id))
    },
  )

export const getUnselectedLocations = (cargoTypeId, locationId = null) =>
  createSelector(
    getRawLocations,
    getRawLocationCargoCompatibilities,
    (locations, compatibilities) => {
      const selectedCompatibilities = compatibilities
        .filter(
          c => c.cargoTypeId === cargoTypeId && c.locationId !== locationId,
        )
        .map(c => c.locationId)

      return getOrderedLocationOptions(
        locations.filter(l => !selectedCompatibilities.includes(l.id)),
      )
    },
  )

export const getCorridorsWithoutRouteKnowledge = crewTypeId =>
  createSelector(
    Corridor.values,
    getRawRouteKnowledge,
    (corridors, routeKnowledges) => {
      const unSelectedCorridors = corridors.filter(
        corridor =>
          !routeKnowledges.find(
            routeKnowledge =>
              routeKnowledge.crewTypeId === crewTypeId &&
              routeKnowledge.corridorId === corridor.id,
          ),
      )

      return getOrderedOptionsByName(unSelectedCorridors)
    },
  )

export const getSelectedCorridor = createSelector(
  getSelectedCorridorId,
  Corridor.values,
  (corridorId, corridors) => corridors.find(x => x.id === corridorId),
)

export const getVolumeUnitTypes = createSelector(
  getRawCargoTypes,
  cargoTypes => {
    const uniqueVolumeUnits = new Set(
      cargoTypes
        .filter(
          cargoType =>
            typeof cargoType.volumeUnit !== 'undefined' &&
            !cargoType.usesTonnage,
        )
        .map(cargoType => cargoType.volumeUnit),
    )
    return Array.from(uniqueVolumeUnits)
  },
)
