import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { loadCategoryFactory } from './factory'
import { ILoadCategoryRepo, LoadCategoryRepo } from './repo'

const loadCategoryRepo = defaultMemoize(
  (input: FactoryInput) => new LoadCategoryRepo(loadCategoryFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  loadCategoryRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type LoadCategoryRepoReg = {
  loadCategoryRepo: FromDocumentFactory<FactoryInput, ILoadCategoryRepo>
}

export function setup<R extends LoadCategoryRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('loadCategoryRepo', loadCategoryRepo)
}

export { LoadCategory } from './model'
