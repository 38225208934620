import React from 'react'

import { setupSave } from 'src/service-design/shared/document/actions'

interface Props {
  hasErrors: boolean
  load: (documentId: string) => Promise<void>
  loaded: boolean
  loadedId: number
  documentId: string
  documentRoot?: string
  store: any
  fallback?: React.ReactNode
  children: React.ReactNode
}

export class DocumentLoader extends React.Component<Props> {
  static displayName = 'DocumentLoader'
  static defaultProps = {
    fallback: null,
  }

  componentDidMount() {
    return this.load()
  }

  componentDidUpdate() {
    if (
      this.props.loadedId &&
      this.props.loadedId !== parseInt(this.props.documentId, 10)
    ) {
      window.location.reload()
    }
    return null
  }

  load() {
    const { documentRoot, store, documentId } = this.props
    return this.props.load(documentId).then(() => {
      if (documentRoot) {
        setupSave(store, documentRoot)
      }
    })
  }

  render() {
    const { loaded, hasErrors, fallback, children } = this.props
    return loaded ? !hasErrors && children : fallback
  }
}
