import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

/* eslint-disable react/forbid-foreign-prop-types */
// Monkey patching the proptypes of DropDown.Item.value to allow for objects as well as the existing
// allowed types of bool, number, string. Objects seem to work perfectly fine as values, they're
// just not included in the allowed types. Have raised an issue on the project page at:
// https://github.com/Semantic-Org/Semantic-UI-React/issues/3153
// Previous issue and context at:
// https://github.com/Semantic-Org/Semantic-UI-React/issues/1368
const valueType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
])

Dropdown.propTypes.value = valueType
Dropdown.Item.propTypes.value = valueType

Dropdown.propTypes.options = PropTypes.arrayOf(
  PropTypes.shape(Dropdown.Item.propTypes),
)
