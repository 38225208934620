import { flow, flatMap, flatten } from 'lodash/fp'
import { createSelector } from 'reselect'

import { graphFocusSchema } from 'src/service-design/sd-plan/components/focus/FocusGraph/focus-config'
import { getLocations } from 'src/service-design/sd-plan/selectors/scenario'
import * as CustomTask from 'src/service-design/shared/models/custom-task'
import { Location } from 'src/service-design/shared/models/location'
import {
  ResourceProfile,
  IResourceProfiler,
} from 'src/service-design/shared/models/resource-summaries/types'
import * as Shift from 'src/service-design/shared/models/shift'
import * as TrainStart from 'src/service-design/shared/models/train-start'

export const generateLocationWork = (
  locations: Location[],
  ...resourceProfiler: IResourceProfiler[][]
): { location: Location; resourceProfiles: ResourceProfile[] }[] =>
  locations.map(location => ({
    location,
    resourceProfiles: flow(
      flatten,
      flatMap((c: IResourceProfiler) => c.getResourceProfiles(location)),
    )(resourceProfiler),
  }))

export const getSelectedYardIds = createSelector(
  graphFocusSchema.getFocus,
  focus => focus.yardId?.split(',') || [],
)

export const getSelectedYards = createSelector(
  getSelectedYardIds,
  getLocations,
  (selectedIds, yards) => selectedIds.map(id => yards.get(id)),
)

export const getSelectedResourceProfiles = createSelector(
  getSelectedYards,
  TrainStart.values,
  Shift.values,
  CustomTask.values,
  generateLocationWork,
)
