import { sumBy } from 'lodash'

import { CargoType } from 'src/service-design/shared/models/cargo-type'
import { LoadingWorkTask } from 'src/service-design/shared/models/loading-work-task'
import { Service } from 'src/service-design/shared/models/service'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { LoadTask } from 'src/service-design/shared/models/task/load'

export class Loading extends LoadTask {
  /**
   * Loading captures the task of putting Services on wagons. Each Loading task
   * is associated with a specific cargo type as generally different cargo types
   * are loaded in different parts of a yard at different rates.
   */
  static kind = 'loading'

  static timeOffsetAllowed = true

  static build(
    startLeg: StartLeg,
    cargoType: CargoType,
    services: Service[] = [],
  ) {
    const offset = startLeg.getTaskTimeOffset(this.kind)
    const end = startLeg.attachStartLocal + offset
    return new this({
      origin: startLeg.origin,
      destination: startLeg.origin,
      startTimeLocal: end - sumBy(services, x => x.loadingSecs),
      requiresDriver: services.some(x => x.requiresLoadingDriver),
      requiresRO: services.some(x => x.requiresLoadingRO),
      endTimeLocal: end,
      externals: startLeg.externals,
      startLeg,
      cargoType,
      services,
    })
  }

  get preResourceDict() {
    return new Map([...this.wagonDict.keys()].map(w => [w, 0]))
  }

  get startResourceDict() {
    return this.wagonDict
  }

  get resourceDict() {
    return this.wagonDict
  }

  get endResourceDict() {
    return this.wagonDict
  }

  getSplitWorkTasks(forDriver: boolean) {
    if (this.split) {
      return [
        new LoadingWorkTask({
          task: this,
          requiresDriver: forDriver,
          requiresAssignment: forDriver ? this.requiresDriver : this.requiresRO,
          startTimeLocal: Math.min(this.startTimeLocal, this.split.timeLocal),
          endTimeLocal: this.split.timeLocal,
          index: 1,
          externals: this.externals,
        }),
        new LoadingWorkTask({
          task: this,
          requiresDriver: forDriver,
          requiresAssignment: forDriver ? this.requiresDriver : this.requiresRO,
          startTimeLocal: Math.max(this.startTimeLocal, this.split.timeLocal),
          endTimeLocal: this.endTimeLocal,
          index: 0,
          externals: this.externals,
        }),
      ]
    }
    return [
      new LoadingWorkTask({
        task: this,
        requiresDriver: forDriver,
        requiresAssignment: forDriver ? this.requiresDriver : this.requiresRO,
        startTimeLocal: this.startTimeLocal,
        endTimeLocal: this.endTimeLocal,
        index: 0,
        externals: this.externals,
      }),
    ]
  }
}
