import { createSelector, defaultMemoize } from 'reselect'

import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { accessGroupFactory } from './factory'
import { IAccessGroupRepo, AccessGroupRepo } from './repo'

const accessGroupRepo = defaultMemoize(
  (input: FactoryInput) => new AccessGroupRepo(accessGroupFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  accessGroupRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type AccessGroupRepoReg = {
  accessGroupRepo: FromDocumentFactory<FactoryInput, IAccessGroupRepo>
}

export function setup<R extends AccessGroupRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('accessGroupRepo', accessGroupRepo)
}

export { AccessGroup } from './model'
