import React from 'react'

import { ENVIRONMENT } from 'src/core/constants'
import { LoadingSpinner } from 'src/service-design/shared/ui'

const EntryPointMap = {
  TR: React.lazy(() => import('./clients/tr/EntryPoint')),
  KCS: React.lazy(() => import('./clients/kcs/EntryPoint')),
  NRDemo: React.lazy(() => import('./clients/nrdemo/EntryPoint')),
}

if (ENVIRONMENT !== 'prod') {
  document.title = `${document.title} [${ENVIRONMENT}]`
}

const App = () => {
  const EntryPoint = EntryPointMap[process.env.REACT_APP_TARGET_ENV]
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <EntryPoint />
    </React.Suspense>
  )
}

App.displayName = 'App'

export default App
