import PropTypes from 'prop-types'

export const INPUT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any,
})

export const INPUT_META = PropTypes.shape({
  touched: PropTypes.bool.isRequired,
  error: PropTypes.string,
  warning: PropTypes.string,
})

export const OPTION = PropTypes.shape({
  key: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  text: PropTypes.string.isRequired,
})

export const NOTIFICATION = PropTypes.shape({
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['error', 'success', 'info', 'warning']).isRequired,
})

export const DOCUMENT = PropTypes.shape({
  id: PropTypes.number.isRequired,
})

export const DOCUMENT_TYPE = PropTypes.shape({
  children: PropTypes.arrayOf(PropTypes.string).isRequired,
})

export const GROUP = PropTypes.shape({
  parentId: PropTypes.number,
  documents: PropTypes.arrayOf(DOCUMENT).isRequired,
})
