import React from 'react'
import { Popup as SemanticPopup } from 'semantic-ui-react'

const Popup = props => (
  <SemanticPopup
    popperModifiers={{
      preventOverflow: { boundariesElement: 'window' },
    }}
    {...props}
  />
)
Popup.Header = SemanticPopup.Header
Popup.Content = SemanticPopup.Content

export { Popup }
