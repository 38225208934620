import { groupBy } from 'lodash'

import { LONG_DAYS } from 'src/service-design/shared/constants'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { TrainStart } from 'src/service-design/shared/models/train-start'

import { TrainTemplate } from '.'

export class DuplicateStarts extends LogMessage {
  static type = 'service-design::Duplicate Starts'

  static message =
    'service-design::Train Template {{entity.name}} has duplicate starts on {{duplicates}}'

  static findDupes(starts: TrainStart[]) {
    const dupedDays = Object.entries(
      groupBy(starts, x => LONG_DAYS[x.departsDayOfWeek]),
    )
      .filter(([, g]) => g.length > 1)
      .map(([k]) => k)

    return dupedDays
  }

  static check(template: TrainTemplate) {
    return this.findDupes(template.starts).length > 0
  }

  constructor({ entity }) {
    super({ entity })
    this.derived = {
      duplicates: DuplicateStarts.findDupes(entity.starts).join(', '),
    }
  }
}

export default {
  warnings: [DuplicateStarts],
}
