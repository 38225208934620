import { createSelector } from 'reselect'

import { getBatchIds } from 'src/service-design/sd-plan/selectors/trains'

import { CustomTask } from 'src/service-design/shared/models/custom-task'
import { Lococlass } from 'src/service-design/shared/models/lococlass'
import { Service } from 'src/service-design/shared/models/service'
import { Shift } from 'src/service-design/shared/models/shift'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { Wagon } from 'src/service-design/shared/models/wagon'

export const getIsSelected = createSelector(
  getBatchIds,
  (_, selected) => selected,
  (batchIds, selected) => target => {
    if (target instanceof CustomTask) {
      // handle the task or its assignment being focused
      if (target.id === selected.customTaskId) {
        return true
      }
      if (
        target.assignment &&
        target.assignment.id === selected.customTaskAssignmentId
      ) {
        return true
      }
    } else if (target instanceof TrainStart) {
      if (
        target.id === selected.trainStartId ||
        (!selected.trainStartId &&
          target.templateId === selected.trainTemplateId) ||
        batchIds.includes(target.templateId)
      ) {
        return true
      }
    } else if (target instanceof Wagon) {
      if (target.id === selected.wagonId) {
        return true
      }
    } else if (target instanceof Lococlass) {
      if (target.id === selected.lococlassId) {
        return true
      }
    } else if (target instanceof Service) {
      if (target.id === selected.serviceId) {
        return true
      }
    } else if (target instanceof Shift) {
      if (target.id === selected.shiftId) {
        return true
      }
    }

    return batchIds.includes(target.id)
  },
)
