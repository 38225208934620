import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { WagonSet } from './model'

export const wagonSetFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.wagonsets.map(data => {
      const item = new WagonSet(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((t: WagonSet, state: any) => ({
    service: registry
      .provide('serviceRepo')(state)
      .byId(t.serviceId),
    allocations: registry
      .provide('wagonAllocationRepo')(state)
      .byWagonsetId(t.id),
  })),
)
