import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/scenario/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { wagonAccessRightFactory } from './factory'
import { IWagonAccessRightRepo, WagonAccessRightRepo } from './repo'

const wagonAccessRightRepo = defaultMemoize(
  (input: FactoryInput) =>
    new WagonAccessRightRepo(wagonAccessRightFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  wagonAccessRightRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type WagonAccessRightRepoReg = {
  wagonAccessRightRepo: FromDocumentFactory<FactoryInput, IWagonAccessRightRepo>
}

export function setup<R extends WagonAccessRightRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('wagonAccessRightRepo', wagonAccessRightRepo)

  relationships.addConstraints('wagonaccessrights', {
    unique: [['wagonId', 'accessGroupId']],
    rels: [
      {
        collection: 'wagons',
        foreign: 'wagonId',
        name: 'wagon',
        allowCascadeDelete: true,
      },
      {
        collection: 'accessgroups',
        foreign: 'accessGroupId',
        name: 'accessGroup',
      },
    ],
  })
}

export { WagonAccessRight } from './model'
