import { createSelector } from 'reselect'

import { flags } from 'src/service-design/sd-plan/flags'
import { getCorridors } from 'src/service-design/sd-plan/selectors/scenario'
import { Corridor } from 'src/service-design/shared/models/corridor'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { OffsetLeg } from 'src/service-design/shared/models/offset-leg'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import {
  intersectionInclusive,
  Interval,
} from 'src/service-design/shared/utils/dates'

export const wellClear = (l1: OffsetLeg, l2: OffsetLeg) =>
  l2.departsLocal.toSeconds() - l1.arrivesLocal.toSeconds() >
  l1.startLeg.corridor.minHeadway

export const headway = (l1: OffsetLeg, l2: OffsetLeg) =>
  Math.abs(
    Interval.fromEpochTimes(l2.departsLocal, l1.departsLocal)
      .duration()
      .toSeconds(),
  ) < l1.startLeg.corridor.minHeadway ||
  Math.abs(
    Interval.fromEpochTimes(l2.arrivesLocal, l1.arrivesLocal)
      .duration()
      .toSeconds(),
  ) < l1.startLeg.corridor.minHeadway

class CongestionWarning extends LogMessage {
  others: any[] = []
  constructor(public leg) {
    super({ leg })
  }

  push(leg) {
    if (this.leg !== leg && !this.others.includes(leg)) {
      this.others.push(leg)
    }
    return this
  }

  get i18nMessage(): [string, object] {
    const train = this.leg.start.name
    const others = this.others.map(o => o.start.name).join(', ')
    return [
      this.message,
      {
        train,
        others,
        count: others.length,
      },
    ]
  }
}

export class Headway extends CongestionWarning {
  static message =
    'service-design::{{train}} is too close to trains: {{others}}'

  static type = 'service-design::Insufficient Headway'
}

export class Pass extends CongestionWarning {
  static message =
    'service-design::{{train}} passes too many trains: {{others}}'

  static type = 'service-design::Too many passes'
}

export const getCongestionWarnings = createSelector(
  StartLeg.values,
  getCorridors,
  (startLegs: StartLeg.StartLeg[], corridors: Map<string, Corridor>) => {
    if (!flags.congestion) {
      return []
    }
    const warnings: {
      Headway: { [key: string]: Headway }
      Pass: { [key: string]: Pass }
    } = {
      Headway: {},
      Pass: {},
    }
    const classes = {
      Headway,
      Pass,
    }
    /**
     * TODO: this code looks very suspect. It appears to assume that every
     * startLeg.departsLocal is going to be in 'week 0' which is not true.
     * departsLocal is completely unbounded and this is likely to have a pretty
     * big impact on the way these calculations play out.
     */
    corridors.forEach(corridor => {
      const original = startLegs
        .filter(l => l.corridor === corridor)
        .map(startLeg => new OffsetLeg({ startLeg, offset: 0 }))
        .sort(
          (l1, l2) => l1.departsLocal.toSeconds() - l2.departsLocal.toSeconds(),
        )

      const repeated = [
        ...original,
        ...original.map(offsetLeg => offsetLeg.getNextWeek()),
      ]
      for (let i = 0; i < original.length; i += 1) {
        const offsetLeg = original[i]
        for (let j = i + 1; j < repeated.length; j += 1) {
          const other = repeated[j]
          if (wellClear(offsetLeg, other)) {
            break
          }

          let type: 'Headway' | 'Pass'
          if (offsetLeg.startLeg.forward === other.startLeg.forward) {
            if (headway(offsetLeg, other)) {
              type = 'Headway'
            }
          } else if (
            intersectionInclusive(
              [offsetLeg.departsUtc, offsetLeg.arrivesUtc],
              [other.departsUtc, other.arrivesUtc],
            )
          ) {
            type = 'Pass'
          }
          const [typeWarnings, Cls] = [warnings[type], classes[type]]
          if (typeWarnings) {
            typeWarnings[offsetLeg.id] = (
              typeWarnings[offsetLeg.id] || new Cls(offsetLeg.startLeg)
            ).push(other.startLeg)
            typeWarnings[other.id] = (
              typeWarnings[other.id] || new Cls(other.startLeg)
            ).push(offsetLeg.startLeg)
          }
        }
      }
    })

    return [
      ...Object.values(warnings.Headway),
      ...Object.values(warnings.Pass).filter(
        w => w.others.length > w.leg.corridor.maxPasses,
      ),
    ]
  },
)
