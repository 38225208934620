import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { resourceUnavailabilityFactory } from './factory'
import { IResourceUnavailabilityRepo, ResourceUnavailabilityRepo } from './repo'

const resourceUnavailabilityRepo = defaultMemoize(
  (input: FactoryInput) =>
    new ResourceUnavailabilityRepo(resourceUnavailabilityFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  resourceUnavailabilityRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type ResourceUnavailabilityRepoReg = {
  resourceUnavailabilityRepo: FromDocumentFactory<
    FactoryInput,
    IResourceUnavailabilityRepo
  >
}

export function setup<R extends ResourceUnavailabilityRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('resourceUnavailabilityRepo', resourceUnavailabilityRepo)

  relationships.addConstraints('resourceunavailabilities', {
    rels: [
      {
        collection: 'resourceavailabilities',
        foreign: 'availabilityId',
        name: 'availability',
      },
    ],
  })
}

export { ResourceUnavailability } from './model'
