import { createSelector } from 'reselect'

import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import * as Shift from 'src/service-design/shared/models/shift'

export const getEngine = state => state.engine.context
export const getEngineId = createSelector(getEngine, engine => engine.id)
export const getEngineName = createSelector(getEngine, engine => engine.name)

export const getEngineStatus = createSelector(
  getEngine,
  engine => engine.status,
)
export const getEngineLog = state => state.engine.log

export const getEngineSolution = state => state.engine.solution

export const getSingletons = state =>
  getCollection(state, 'service-design', 'singletons')

export const getLVTasks = createSelector(Shift.values, shifts =>
  shifts.reduce((acc, shift) => acc.concat(shift.lvTasks), []),
)
