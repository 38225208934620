import React from 'react'
import { Icon, Menu } from 'semantic-ui-react'

import i18n from 'src/i18n'
import { flags } from 'src/service-design/shared/flags'
import { Popup } from 'src/service-design/shared/ui/components/Popup'

import { StyledMenu } from './styled'

interface Props {
  data: {
    id: number
    archived: boolean
    modified: boolean
  }
  archiveDocument?: () => void
  copyDocument?: () => void
  copyDocumentTo?: () => void
  copyDocumentAndOpen?: () => void
  editDocument?: () => void
  exportDocument?: () => void
  openAuditLog?: () => void
  trigger: React.ReactNode
  showClose?: boolean
  renameDocument?: () => void
  unarchiveDocument?: () => void
}

interface State {
  isOpen: boolean
}
export class DocumentActions extends React.Component<Props, State> {
  static displayName = 'DocumentActions'

  static defaultProps = {
    trigger: null,
    showClose: true,
    archiveDocument: null,
    copyDocument: null,
    copyDocumentTo: null,
    copyDocumentAndOpen: null,
    editDocument: null,
    exportDocument: null,
    openAuditLog: null,
    renameDocument: null,
    unarchiveDocument: null,
  }

  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  closeAndExec(fn) {
    const self = this
    return e => {
      e.stopPropagation()
      self.setState({ isOpen: false })
      return fn()
    }
  }

  handleOpen(e) {
    e.stopPropagation()
    this.setState({ isOpen: true })
  }

  handleClose(e) {
    e.stopPropagation()
    this.setState({ isOpen: false })
  }

  render() {
    const {
      data,
      copyDocument,
      editDocument,
      copyDocumentTo,
      copyDocumentAndOpen,
      archiveDocument,
      unarchiveDocument,
      exportDocument,
      openAuditLog,
      renameDocument,
      trigger,
      showClose,
    } = this.props

    return (
      <Popup
        on="click"
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        position="bottom right"
        style={{ padding: '0', border: 'none' }}
        basic
        trigger={
          trigger || (
            <Icon className="plan-actions" name="ellipsis horizontal" />
          )
        }
        content={
          <StyledMenu className="plan-menu" size="tiny" vertical>
            {showClose && (
              <Menu.Header>
                <Icon name="cancel" onClick={this.handleClose} />
              </Menu.Header>
            )}
            {editDocument && (
              <Menu.Item onClick={editDocument}>
                <Icon name="edit" />
                {i18n.t('Open')}
              </Menu.Item>
            )}
            {renameDocument && (
              <Menu.Item onClick={this.closeAndExec(renameDocument)}>
                <Icon name="text cursor" />
                {i18n.t('Rename')}
              </Menu.Item>
            )}
            {copyDocument && (
              <Menu.Item onClick={this.closeAndExec(copyDocument)}>
                <Icon name="copy" />
                {i18n.t('Copy')}
              </Menu.Item>
            )}
            {copyDocumentTo && (
              <Menu.Item onClick={this.closeAndExec(copyDocumentTo)}>
                <Icon name="external share" />
                {i18n.t('Copy to...')}
              </Menu.Item>
            )}
            {copyDocumentAndOpen && (
              <Menu.Item
                onClick={this.closeAndExec(copyDocumentAndOpen)}
                disabled={data.modified}
              >
                <Icon name="clone" />
                {i18n.t('Open Copy')}
              </Menu.Item>
            )}
            {flags.auditLog && openAuditLog && (
              <Menu.Item onClick={this.closeAndExec(openAuditLog)}>
                <Icon name="history" />
                {i18n.t('Audit Log')}
              </Menu.Item>
            )}
            {archiveDocument && !data.archived && (
              <Menu.Item onClick={this.closeAndExec(archiveDocument)}>
                <Icon name="archive" />
                {i18n.t('Archive')}
              </Menu.Item>
            )}
            {unarchiveDocument && data.archived && (
              <Menu.Item onClick={this.closeAndExec(unarchiveDocument)}>
                <Icon name="archive" />
                {i18n.t('Unarchive')}
              </Menu.Item>
            )}
            {exportDocument && (
              <Menu.Item onClick={this.closeAndExec(exportDocument)}>
                <Icon name="download" />
                {i18n.t('Export')}
              </Menu.Item>
            )}
          </StyledMenu>
        }
      />
    )
  }
}
