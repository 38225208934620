import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  Registry,
  FromDocumentFactory,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { driverAssignmentFactory } from './factory'
import { IDriverAssignmentRepo, DriverAssignmentRepo } from './repo'

const driverAssignmentRepo = defaultMemoize(
  (input: FactoryInput) =>
    new DriverAssignmentRepo(driverAssignmentFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  driverAssignmentRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type DriverAssignmentRepoReg = {
  driverAssignmentRepo: FromDocumentFactory<FactoryInput, IDriverAssignmentRepo>
}

export function setup<R extends DriverAssignmentRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('driverAssignmentRepo', driverAssignmentRepo)

  relationships.addConstraints('driverassignments', {
    rels: [
      {
        collection: 'trainstarts',
        foreign: 'trainId',
        name: 'train',
      },
      {
        collection: 'shifts',
        foreign: 'shiftId',
        name: 'shift',
      },
      {
        collection: 'locationchangeovers',
        foreign: 'startLocationChangeoverId',
        name: 'startLocationChangeover',
      },
      {
        collection: 'locationchangeovers',
        foreign: 'endLocationChangeoverId',
        name: 'endLocationChangeover',
      },
      {
        collection: 'trainchangeovers',
        foreign: 'startTrainChangeoverId',
        name: 'startTrainChangeover',
      },
      {
        collection: 'trainchangeovers',
        foreign: 'endTrainChangeoverId',
        name: 'endTrainChangeover',
      },
    ],
  })
}

export { DriverAssignment } from './model'
