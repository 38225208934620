import { createSelector, defaultMemoize } from 'reselect'

// FIXME: Remove this cross-module import
// eslint-disable-next-line no-restricted-imports
import relationships from 'src/service-design/sd-plan/document/relationships'
import {
  FactoryInput,
  FromDocumentFactory,
  Registry,
} from 'src/service-design/shared/document-factory'
import {
  getFactoryInput,
  FactoryInputParameters,
} from 'src/service-design/shared/document-factory/factory-input'

import { trainChangeoverLockFactory } from './factory'
import { ITrainChangeoverLockRepo, TrainChangeoverLockRepo } from './repo'

export const trainChangeoverLockRepo = defaultMemoize(
  (input: FactoryInput) =>
    new TrainChangeoverLockRepo(trainChangeoverLockFactory(input)),
)

export const selector = createSelector(getFactoryInput, (input: FactoryInput) =>
  trainChangeoverLockRepo(input),
)
export const values = (state: FactoryInputParameters) => selector(state).values

export type TrainChangeoverLockRepoReg = {
  trainChangeoverLockRepo: FromDocumentFactory<
    FactoryInput,
    ITrainChangeoverLockRepo
  >
}

export function setup<R extends TrainChangeoverLockRepoReg>({
  repoRegistry,
}: {
  repoRegistry: Registry<R>
}) {
  repoRegistry.bind('trainChangeoverLockRepo', trainChangeoverLockRepo)

  relationships.addConstraints('trainchangeoverlocks', {
    unique: [['changeoverId']],
    rels: [
      {
        collection: 'trainchangeovers',
        foreign: 'changeoverId',
        name: 'changeover',
      },
    ],
  })
}

export { TrainChangeoverLock } from './model'
