import { FactoryInput } from 'src/service-design/shared/document-factory'
import { createFactoryFromDocument } from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { BusinessGroup } from './model'

export const businessGroupFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.businessgroups.map(data => {
      const item = new BusinessGroup(data)
      item.setExternals(externals)
      return item
    }),
)
