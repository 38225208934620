import { flags } from 'src/service-design/shared/flags'
import { LogMessage } from 'src/service-design/shared/models/log-message'
import { WagonAccess } from 'src/service-design/shared/models/wagon/validator'
import { durationString } from 'src/service-design/shared/utils/dates'

export class TrainDepartsTooEarly extends LogMessage {
  static type = 'service-design::Train departs too early'

  static message =
    'service-design::{{entity.start.name}} departs {{entity.origin.name}} {{duration}} too early for {{entity.service.name}}'

  static check(assignment) {
    const missesDeliveryCutoff =
      !assignment.prev &&
      assignment.service.deliveryCutOffLocal > assignment.activityStartLocal

    const missesTransshipment =
      assignment.prev &&
      assignment.prev.activityEndLocal > assignment.activityStartLocal
    return Boolean(missesDeliveryCutoff || missesTransshipment)
  }

  constructor(context) {
    const assignment = context.entity
    const startTime = !assignment.prev
      ? assignment.service.deliveryCutOffLocal
      : assignment.prev.activityEndLocal
    super(context, {
      duration: durationString(startTime - assignment.activityStartLocal),
    })
  }
}

export class TrainArrivesTooLate extends LogMessage {
  static type = 'service-design::Train departs too late'

  static message =
    'service-design::{{entity.start.name}} arrives at {{entity.destination.name}} {{duration}} too late for {{entity.service.name}}'

  static check(assignment) {
    return (
      !assignment.next &&
      assignment.service.dueLocal < assignment.activityEndLocal
    )
  }

  constructor(context) {
    const assignment = context.entity
    super(context, {
      duration: durationString(
        assignment.activityEndLocal - assignment.service.dueLocal,
      ),
    })
  }
}

export class ServiceWagonAccess extends WagonAccess {
  static message =
    'service-design::[{{invalidWagons}}] wagons assigned to freight demand {{service.name}} are not permitted from {{leg.origin.code}} to {{leg.dest.code}}.'

  constructor(invalidWagons, leg, service) {
    super(
      { leg, service },
      { invalidWagons: invalidWagons.map(l => l.name).join('; ') },
    )
  }
}

export default {
  warnings: [
    ...(flags.freightServices
      ? [TrainDepartsTooEarly, TrainArrivesTooLate]
      : []),
  ],
}
