import { Event } from 'src/service-design/shared/models/dwell-resource-profile'
import {
  ResourceProfile,
  IResource,
} from 'src/service-design/shared/models/resource-summaries/types'
import { LoadTask } from 'src/service-design/shared/models/task'
import { normalize } from 'src/service-design/shared/utils/dates'
import { sumCounts } from 'src/service-design/shared/utils/math'

export function diffResources<T>(
  prevDict: Map<T, number>,
  resourceDict: Map<T, number>,
): [T, number][] {
  return [...resourceDict.entries()].map(([resource, currentQuantity]) => {
    const prevQuantity = prevDict.get(resource) || 0
    const difference = prevQuantity - currentQuantity
    return [resource, difference]
  })
}

export class LoadResourceProfile implements ResourceProfile {
  /**
   * LoadResourceProfile tracks how the resources associated with a LoadTask
   * change over time.
   *
   * This information is used to conjunction other ResourceProfiles to determine
   * total resource usage over the cyclic week.
   *
   * @param activity {LoadTask}: The load task
   */
  constructor(public activity: LoadTask) {}

  get resources() {
    return [
      ...this.activity.startResourceDict.keys(),
      ...this.activity.endResourceDict.keys(),
    ]
  }

  get offsetWorkingSecs() {
    /**
     * This captures the wagon utilization associated with advancing /
     * delaying loading / unloading tasks. As currently model this doesn't fit
     * super neatly against DwellResourceProfiles or LoadResourceProfiles for
     * now though we will put it here.
     */
    const offset = this.activity.startLeg.getTaskTimeOffset(this.activity.kind)
    return new Map(
      [...this.activity.resourceDict.entries()].map(([k, v]) => [
        k,
        v * Math.abs(offset),
      ]),
    )
  }

  get totalWorkingSecs() {
    return sumCounts(this.activity.totalWorkingSecs, this.offsetWorkingSecs)
  }

  get deltas(): {
    delta: Map<IResource, number>
    offset: number
    event: Event
  }[] {
    const {
      preResourceDict,
      startResourceDict,
      endResourceDict,
    } = this.activity

    // offset needs to fall within week boundary
    const deltas = [
      {
        delta: new Map(diffResources(preResourceDict, startResourceDict)),
        offset: normalize(this.activity.startTimeLocalNormalized),
        event: new Event(this.activity, 'start', this),
      },
      {
        delta: new Map<IResource, number>(
          diffResources(startResourceDict, endResourceDict),
        ),
        offset: normalize(this.activity.endTimeLocalNormalized),
        event: new Event(this.activity, 'end', this),
      },
    ]
    return deltas
  }

  get costs() {
    return this.activity.costs.filter(x => x)
  }

  get activities() {
    return [this.activity]
  }

  get target() {
    return this.activity.startLeg.start
  }

  get startTimeLocalNormalized() {
    return this.activity.startTimeLocalNormalized
  }

  get endTimeLocalNormalized() {
    return this.activity.endTimeLocalNormalized
  }
}
