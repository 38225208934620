import { LocationChangeover } from 'src/service-design/shared/models/changeover/location'
import { TrainChangeover } from 'src/service-design/shared/models/changeover/train'
import {
  ShiftAssignment,
  Attrs as BaseAttrs,
  Rels as BaseRels,
} from 'src/service-design/shared/models/shift-assignment/base'
import { LegTask } from 'src/service-design/shared/models/task'
import { TrainStart } from 'src/service-design/shared/models/train-start'

import validators from './validator'

interface Attrs {
  trainId: string
  startLocationChangeoverId: string
  endLocationChangeoverId: string
  startTrainChangeoverId: string
  endTrainChangeoverId: string
}

interface Rels {
  train: TrainStart
  startLocationChangeover?: LocationChangeover
  endLocationChangeover?: LocationChangeover
  startTrainChangeover?: TrainChangeover
  endTrainChangeover?: TrainChangeover
}

class DriverAssignment extends ShiftAssignment {
  static validators = validators
  static collection = 'driverassignments'
  static defaults = {
    startLocationChangeoverId: null,
    startTrainChangeoverId: null,
    endLocationChangeoverId: null,
    endTrainChangeoverId: null,
  }

  /**
   * A DriverAssignment associates DriverTask with with a Shift.
   *
   * Related models:
   * - `TrainStart`;
   * - `DriverTask`;
   * - `Shift`.
   *
   * @constructor
   * @param {string} id - The entity id.
   * @param {string} customTaskId - The id of the CustomTask.
   * @param {string} shiftId - The id of the Shift.
   * @param {string|null} startLocationChangeoverId - The id of
   *  LocationChangeover at the beginning of the assignment. Only one of
   *  startLocationChangeoverId and startTrainChangeoverId should be non-null.
   * @param {string|null} endLocationChangeoverId - The id of LocationChangeover
   *  at the end of the assignment. Only one of endLocationChangeoverId and
   *  endTrainChangeoverId should be non-null.
   * @param {string|null} startTrainChangeoverId - The id of the TrainChangeover
   *  at the beginning of the assignment. Only one of startLocationChangeoverId
   *  and startTrainChangeoverId should be non-null.
   * @param {string|null} endTrainChangeoverId - The id of TrainChangeover at
   *  the end of the assignment. Only one of endLocationChangeoverId and
   *  endTrainChangeoverId should be non-null.
   **/
  constructor({
    trainId,
    startLocationChangeoverId,
    endLocationChangeoverId,
    startTrainChangeoverId,
    endTrainChangeoverId,
    ...rest
  }: Attrs & BaseAttrs) {
    super(rest)
    this.trainId = trainId
    this.startLocationChangeoverId = startLocationChangeoverId
    this.endLocationChangeoverId = endLocationChangeoverId
    this.startTrainChangeoverId = startTrainChangeoverId
    this.endTrainChangeoverId = endTrainChangeoverId
  }

  setRels({
    train,
    startLocationChangeover,
    endLocationChangeover,
    startTrainChangeover,
    endTrainChangeover,
    ...rest
  }: Rels & BaseRels) {
    this.train = train
    this.startLocationChangeover = startLocationChangeover
    this.endLocationChangeover = endLocationChangeover
    this.startTrainChangeover = startTrainChangeover
    this.endTrainChangeover = endTrainChangeover
    super.setRels(rest)
  }

  // TODO: trainId should be renamed to startId in the document
  get startId() {
    return this.trainId
  }

  get startChangeoverId() {
    return this.startLocationChangeoverId || this.startTrainChangeoverId
  }

  get endChangeoverId() {
    return this.endLocationChangeoverId || this.endTrainChangeoverId
  }

  get startChangeover() {
    return this.startLocationChangeover || this.startTrainChangeover
  }

  get endChangeover() {
    return this.endLocationChangeover || this.endTrainChangeover
  }

  get name() {
    return `${this.train.name} - ${this.startLocation.code} to ${this.endLocation.code}`
  }

  get task() {
    return this.train.getDriverTask(
      this.startChangeoverId,
      this.endChangeoverId,
    )
  }

  get potentialMealBreaks() {
    const mealBreaks = []
    let mealBreak = null
    this.task.subTasks.forEach(subTask => {
      if (
        subTask instanceof LegTask &&
        ['detach', 'dwell', 'attach'].indexOf(subTask.kind) > -1
      ) {
        if (!mealBreak) {
          mealBreak = {
            startTimeLocalNormalized: Math.max(
              this.task.startTimeLocalNormalized,
              subTask.startTimeLocalNormalized,
            ),
          }
        }
        mealBreak.endTimeLocalNormalized = Math.min(
          this.task.endTimeLocalNormalized,
          subTask.endTimeLocalNormalized,
        )
      } else if (mealBreak) {
        mealBreaks.push(mealBreak)
        mealBreak = null
      }
    })

    if (mealBreak) {
      mealBreaks.push(mealBreak)
    }
    return mealBreaks
  }

  get startLocation() {
    return this.task.startLocation
  }

  get endLocation() {
    return this.task.endLocation
  }
}

interface DriverAssignment extends Attrs, Rels {}
export { DriverAssignment }
