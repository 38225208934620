export class BrokenRelationship {
  public collectionName
  public affectedObj
  public relationDefinition

  constructor({ collectionName, affectedObj, relationDefinition }) {
    this.collectionName = collectionName
    this.affectedObj = affectedObj
    this.relationDefinition = relationDefinition
  }
}
