import { createSelector } from 'reselect'

import { getCollection } from 'src/service-design/sd-plan/selectors/base'
import {
  getCrewFilter,
  filterItems,
} from 'src/service-design/sd-plan/selectors/filters'
import * as CrewPool from 'src/service-design/shared/models/crew-pool'

export const matchesLocation = (pool, { locId }) =>
  !locId || pool.locId === locId

const CREW_POOL_FILTERS = [matchesLocation]

export const getRawCrewPools = state =>
  getCollection(state, 'service-design', 'crewpools')
export const getRawShifts = state =>
  getCollection(state, 'service-design', 'shifts')

export const getCrewPools = CrewPool.values

export const getCrewPoolTotal = createSelector(
  CrewPool.selector,
  repo => repo.values.length,
)

export const getFatigueUpdating = state =>
  state.fatigue.dirty || state.fatigue.updating

export const getFilteredCrewPools = createSelector(
  getCrewFilter,
  CrewPool.selector,
  (filter, repo) => filterItems(filter, repo.values, CREW_POOL_FILTERS),
)

export const getShiftById = createSelector(
  CrewPool.selector,
  (state, props) => props,
  (repo, id) => {
    const shifts = repo.values.flatMap(x => x.shifts)
    return shifts.find(y => id === y.id)
  },
)
