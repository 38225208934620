import { Location } from 'src/service-design/shared/models/location'
import { Mapper } from 'src/service-design/shared/models/mapper'
import { YardBlockAssignment } from 'src/service-design/shared/models/yard-block-assignment'

import validators from './validators'

type Attrs = {
  id: string
  name: string
  originId: string
  destinationId: string
  tonnage: number
  blockLength: number
  numWagons: number
  comment: string
  avgTareWeight: number
}

type Rels = {
  origin: Location
  destination: Location
  assignment?: YardBlockAssignment
}

class YardBlock extends Mapper {
  entityName: string

  static validators = validators

  /**
   * A YardBlock is an aggregated unit of freight with an
   * origin and destination. The YardBlock includes details
   * on the number of wagons/cars used, tonnage and length.
   *
   * A YardBlock is assigned to a TrainBlock via a
   * YardBlockAssignment.
   *
   * Aside: where KCS use YardBlock, TR uses Service (Demands)
   *
   * Related models:
   * - Location
   * - YardBlockAssignment
   *
   * @param {string} id
   * @param {string} name
   * @param {string} originId - id of the origin
   * @param {string} destinationId id of the destination
   * @param {number} tonnage - weight in tonnes
   * @param {number} blockLength - number of blocks
   * @param {number} numWagons - number of wagons
   * @param {string} comment
   * @param {number} avgTareWeight
   */
  constructor({
    id,
    name,
    originId,
    destinationId,
    tonnage,
    blockLength,
    numWagons,
    comment,
    avgTareWeight,
  }: Attrs) {
    super()
    this.id = id
    this.name = name
    this.originId = originId
    this.destinationId = destinationId
    this.tonnage = tonnage
    this.blockLength = blockLength
    this.numWagons = numWagons
    this.comment = comment
    this.avgTareWeight = avgTareWeight
    this.entityName = 'YardBlock'
  }

  setRels({ origin, destination, assignment }: Rels) {
    this.origin = origin
    this.destination = destination
    this.assignment = assignment
  }

  get departingLeg() {
    return this.assignment ? this.assignment.departingLeg : null
  }

  get arrivingLeg() {
    return this.assignment ? this.assignment.trainBlock.templateLeg : null
  }

  get trainBlock() {
    return this.assignment ? this.assignment.trainBlock : null
  }

  get numStarts() {
    return this.trainBlock ? this.trainBlock.template.starts.length : null
  }

  get warnings() {
    if (!this._warnings) {
      this._warnings = super.warnings
      if (this.assignment) {
        this._warnings.push(...this.assignment.warnings)
      }
    }
    return this._warnings
  }

  static getValidatorTree() {
    return [
      ...super.getValidatorTree(),
      ...YardBlockAssignment.getValidatorTree(),
    ]
  }
}

interface YardBlock extends Attrs, Rels {}

export { YardBlock }
