import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { CargoType } from './model'

export const cargoTypeFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.cargotypes.map(data => {
      const item = new CargoType(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((cargoType: CargoType, state: any) => ({
    wagon: registry
      .provide('wagonRepo')(state)
      .byId(cargoType.wagonId),
    compatibilities: registry
      .provide('locationCargoCompatibilityRepo')(state)
      .byCargoTypeId(cargoType.id),
    wagoncompatibilities: registry
      .provide('wagonCargoCompatibilityRepo')(state)
      .byCargoTypeId(cargoType.id),
  })),
)
