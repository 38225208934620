import { createSelector } from 'reselect'

import { getUserPreferences } from 'src/service-design/sd-plan/selectors/document-graph'
import { getLocationSummaries } from 'src/service-design/sd-plan/selectors/location-summaries'
import { getLocoClassesWithForeign } from 'src/service-design/sd-plan/selectors/lococlasses'
import {
  getBusinessGroups,
  getLVs,
} from 'src/service-design/sd-plan/selectors/scenario'
import { ResourceSummary } from 'src/service-design/shared/models/resource-summaries'
import { IResource } from 'src/service-design/shared/models/resource-summaries/types'
import * as Service from 'src/service-design/shared/models/service'
import * as Shift from 'src/service-design/shared/models/shift'
import * as StartLeg from 'src/service-design/shared/models/start-leg'
import * as Wagon from 'src/service-design/shared/models/wagon'

export const getResources = createSelector(
  Wagon.values,
  getLocoClassesWithForeign,
  getLVs,
  (...args) => args.flat(),
)

export const getResourceConsumers = createSelector(
  StartLeg.values,
  Service.values,
  Shift.values,
  (...args) => args.flat(),
)

export const getResourceSummaries = createSelector(
  getResources,
  getLocationSummaries,
  getResourceConsumers,
  getUserPreferences,
  ResourceSummary.buildCollection,
)

const resourceSummaryFactory = resourceSelector =>
  createSelector(
    resourceSelector,
    getResourceSummaries,
    (resources: IResource[], resourceSummaries: ResourceSummary[]) =>
      resources.map(resource =>
        resourceSummaries.find(
          resourceSummary => resourceSummary.resource === resource,
        ),
      ),
  )

export const getLococlassSummaries = resourceSummaryFactory(
  getLocoClassesWithForeign,
)

export const getWagonSummaries = resourceSummaryFactory(Wagon.values)

export const getLVSummaries = resourceSummaryFactory(getLVs)

export const getFleetPlan = createSelector(
  getLococlassSummaries,
  getBusinessGroups,
  (locoSummaries, businessgroups) => {
    const data = []
    const extraRows = [
      { name: 'Fleet Total', key: 'fleetTotal' },
      { name: 'Idle', key: 'rosteredIdle' },
      { name: 'Hauled', key: 'rosteredHauled' },
      { name: 'Spare', key: 'spare' },
    ]

    const makeRowFactory = (name, category, getQuantity) => summary => ({
      category,
      utilizationType: name,
      resource: summary.resource,
      quantity: getQuantity(summary) || 0,
    })

    for (const row of extraRows) {
      const makeRow = makeRowFactory(row.name, 'Other', summ => summ[row.key])
      data.push(...locoSummaries.map(makeRow))
    }

    let unavailReason = new Set()
    for (const group of businessgroups.values()) {
      const makeRow = makeRowFactory(group.name, 'Rostered', summ =>
        summ.bgRostered.get(group.id),
      )
      data.push(...locoSummaries.map(makeRow))

      unavailReason = new Set(
        [].concat(
          ...locoSummaries.map(summ =>
            summ.unavailabilities.map(unavailability => unavailability.reason),
          ),
        ),
      )
    }

    for (const reason of unavailReason) {
      for (const summary of locoSummaries) {
        const row = {
          category: 'Unavailable',
          resource: summary.resource,
          utilizationType: reason,
          quantity: 0,
        }
        for (const unavail of summary.unavailabilities) {
          if (unavail.reason === reason) {
            row.quantity = unavail.quantity
          }
        }
        data.push(row)
      }
    }
    return data
  },
)
