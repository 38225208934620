import { ScenarioDocument } from './types'

const blank: ScenarioDocument = {
  accessgroups: [],
  lococlassaccessrights: [],
  businessgroups: [],
  cargotypes: [],
  corridors: [],
  crewtypes: [],
  foreignrailroads: [],
  loadcategories: [],
  loadtables: [],
  locations: [],
  lococlasses: [],
  lvtrips: [],
  singletons: {
    costs: {
      fuel: 0,
    },
    crew: {
      changeoverSecs: 600,
      singleRDODuration: 30*60*60, 
      firstRDOSequenceDuration: 35*60*60, 
    },
    lv: {
      fixedCost: 0,
      kmCost: 0,
      kmFuelBurn: 0,
      fuelPrice: 0,
      id: 'LV',
      name: 'LV',
      entityName: 'LV',
      bgColor: '#2b2b2b',
    },
  },
  traintypes: [],
  traingraphs: [],
  traingraphlocations: [],
  transittimes: [],
  timezones: [],
  wagonaccessrights: [],
  wagons: [],
  locationcargocompatibilities: [],
  wagoncargocompatibilities: [],
  routeknowledge: [],
}
export default blank
