import { Relationships } from 'src/service-design/shared/document/relationships'
import {
  Document,
  DocumentSpec,
  DocumentData,
} from 'src/service-design/shared/document/types'
import {
  validateSchema,
  SchemaError,
} from 'src/service-design/shared/validation'

const getDocumentSpec = <T extends string>(
  type: T,
  documentSpec: DocumentSpec<T>,
): DocumentSpec<T> | null => {
  let spec = documentSpec
  while (spec) {
    if (spec.kind === type) {
      return spec
    }
    spec = spec.parent
  }
  return null
}

const validateUniquenessConstraints = (
  data: DocumentData,
  relationships: Relationships<{ [collection: string]: { id: string } }>, // shame a bit hard to type
) =>
  relationships.collections.reduce((acc, collection) => {
    const errors = relationships.validateConstraint(
      collection,
      data[collection],
    )
    acc.push(
      ...errors.map(({ row, constraint }, i: number) => {
        const columns = constraint.map(c => `${c}=${row[c]}`).join(', ')
        return {
          id: `${row.id}-${i}`,
          type: 'Failed Uniqueness Checks',
          message: `Row ${row.id} clashes on the uniqueness of columns ${columns} in ${collection}`,
        }
      }),
    )
    return acc
  }, [])

export const validateAll = (
  revisions: Document[],
  documentSpec: DocumentSpec<string>,
) => {
  const errors = []
  for (const rev of revisions) {
    // TODO only call getDocumentSpec once, even though it's not expensive
    if (rev.meta && getDocumentSpec(rev.meta.type, documentSpec)) {
      const spec = getDocumentSpec(rev.meta.type, documentSpec)

      const revErrors = validateSchema(rev.data, spec.schema)
      errors.push(...revErrors)

      const uniquenessErrors = validateUniquenessConstraints(
        rev.data,
        spec.relationships,
      )
      errors.push(...uniquenessErrors)

      const workSheetErrors = spec.worksheets.validate(rev.data)

      // TODO: Validate that there isn't extra top-levels
      errors.push(...workSheetErrors)
    } else if (!rev.meta) {
      errors.push(
        new SchemaError(
          `Revision ${rev.id} invalid.`, // TODO: i18n
          `Revision ${rev.id} missing field 'meta'.`, // TODO: i18n
        ),
      )
    } else {
      const msg = `Schema "${rev.meta.type}" not found.` // TODO: i18n
      errors.push(
        new SchemaError(
          msg,
          `${msg} The document you are opening may be of the wrong type`, // TODO: i18n
        ),
      )
    }
  }
  return errors
}
