import { SECONDS_PER_WEEK } from 'src/service-design/shared/constants'
import { Consist } from 'src/service-design/shared/models/consist'
import { Corridor } from 'src/service-design/shared/models/corridor'
import { Location } from 'src/service-design/shared/models/location'
import { LocalAssignment } from 'src/service-design/shared/models/shift-assignment'
import { UncrewedLegTask } from 'src/service-design/shared/models/shift-assignment/uncrewed-leg-task'
import { StartLeg } from 'src/service-design/shared/models/start-leg'
import { IStartLeg } from 'src/service-design/shared/models/start-leg/istartleg'
import { TrainStart } from 'src/service-design/shared/models/train-start'
import { Duration, EpochTime } from 'src/service-design/shared/utils/dates'

export class OffsetLeg implements IStartLeg {
  offset: number
  startLeg: StartLeg
  /**
   * An OffsetLeg is a proxy to a StartLeg with offset timings.
   *
   * @param startLeg {startLeg}: The startLeg to proxy.
   * @param offset {number}: The number of seconds the startLeg should be
   *  offset by.
   */
  constructor({ offset, startLeg }: { offset: number; startLeg: StartLeg }) {
    this.offset = offset
    this.startLeg = startLeg
  }

  get id(): string {
    return this.startLeg.id
  }

  get name(): string {
    return this.startLeg.name
  }

  get startId(): string {
    return this.startLeg.startId
  }

  get start(): TrainStart {
    return this.startLeg.start
  }

  get templateLegId(): string {
    return this.startLeg.templateLegId
  }

  get externals(): any {
    return this.startLeg.externals
  }

  get singletons(): any {
    return this.startLeg.singletons
  }

  get resourceDict(): Map<any, number> {
    return this.startLeg.resourceDict
  }

  get wagonDict(): Map<any, number> {
    return this.startLeg.wagonDict
  }

  getNextWeek(): OffsetLeg {
    return new OffsetLeg({
      offset: this.offset + SECONDS_PER_WEEK,
      startLeg: this.startLeg,
    })
  }

  get origin(): Location {
    return this.startLeg.origin
  }

  get dest(): Location {
    return this.startLeg.dest
  }

  get corridor(): Corridor {
    return this.startLeg.corridor
  }

  get attachStartLocal(): number {
    return this.startLeg.attachStartLocal + this.offset
  }

  get attachEndLocal(): number {
    return this.startLeg.attachEndLocal + this.offset
  }

  get preDepartureDuration(): number {
    return this.startLeg.preDepartureDurationVO.toSeconds()
  }

  get preDepartStartLocal(): number {
    return this.startLeg.preDepartStartLocal + this.offset
  }

  get preDepartEndLocal(): number {
    return this.startLeg.preDepartEndLocal + this.offset
  }

  get departsLocal(): EpochTime {
    return this.startLeg.departsLocal.makeLater(
      Duration.fromSeconds(this.offset),
    )
  }

  get departsUtc(): number {
    return this.startLeg.departsUtc + this.offset
  }

  get arrivesLocal(): EpochTime {
    return this.startLeg.arrivesLocal.makeLater(
      Duration.fromSeconds(this.offset),
    )
  }

  get arrivesUtc(): number {
    return this.startLeg.arrivesUtc + this.offset
  }

  get postArrivalDuration(): Duration {
    return this.startLeg.postArrivalDuration
  }

  get postArriveStartLocal(): number {
    return this.startLeg.postArriveStartLocalVO.toSeconds() + this.offset
  }

  get postArriveEndLocal(): number {
    return this.startLeg.postArriveEndLocalVO.toSeconds() + this.offset
  }

  get detachDuration(): number {
    return this.startLeg.detachDuration
  }

  get detachStartLocal(): number {
    return this.startLeg.detachStartLocal + this.offset
  }

  get detachEndLocal(): number {
    return this.startLeg.detachEndLocal + this.offset
  }

  get nextDepartsLocal(): number {
    const next = this.startLeg.start.legs[this.startLeg.legNum]
    const nextDepartsLocal = next.departsLocal.toSeconds() + this.offset
    if (nextDepartsLocal < this.arrivesLocal.toSeconds()) {
      return nextDepartsLocal + SECONDS_PER_WEEK
    }

    return nextDepartsLocal
  }

  get consist(): Consist {
    return this.startLeg.consist
  }

  get originating(): boolean {
    return this.startLeg.originating
  }

  get terminating(): boolean {
    return this.startLeg.terminating
  }

  get uncrewedLegTasks(): UncrewedLegTask[] {
    return this.startLeg.uncrewedLegTasks
  }

  get localAssignments(): LocalAssignment[] {
    return this.startLeg.localAssignments
  }

  getTaskTimeOffset(kind: string): number {
    return this.startLeg.getTaskTimeOffset(kind)
  }
}
