import { FactoryInput } from 'src/service-design/shared/document-factory'
import { createFactoryFromDocument } from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { AccessGroup } from './model'

export const accessGroupFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.accessgroups.map(data => {
      const item = new AccessGroup(data)
      item.setExternals(externals)
      return item
    }),
)
