import {
  FactoryInput,
  registry,
} from 'src/service-design/shared/document-factory'
import {
  createFactoryFromDocument,
  setRelsFromSpec,
} from 'src/service-design/shared/document-factory/createFactoryFromDocument'

import { LoadTable } from './model'

export const loadTableFactory = createFactoryFromDocument(
  ({ collectionData, externals }: FactoryInput) =>
    collectionData.loadtables.map(data => {
      const item = new LoadTable(data)
      item.setExternals(externals)
      return item
    }),
  setRelsFromSpec((loadTable: LoadTable, state: any) => ({
    corridor: registry
      .provide('corridorRepo')(state)
      .byId(loadTable.corridorId),
    trainType: registry
      .provide('trainTypeRepo')(state)
      .byId(loadTable.trainTypeId),
    loadCategoryObj: registry
      .provide('loadCategoryRepo')(state)
      .byId(loadTable.loadCategory),
  })),
)
