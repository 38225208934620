import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styled from 'styled-components'

import ConnectedLink from 'src/service-design/shared/ui/components/ConnectedLink'

export const StyledNavList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;

  .nav-list-header {
    background: #4a4a4a;
    color: white;
    font-size: 1.1em;
  }

  .nav-list-items {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-x: hidden;
    font-size: 1.2em;
    background: white;
  }

  .nav-list-link {
    flex: 0 0 auto;
    padding: 0.5em;
    color: #4c5152;
  }

  .nav-list-link:hover {
    color: #4c5152;
    background: #fafafa;
  }

  .nav-list-link.active {
    color: white;
    background: #4a90e2;
  }
`

export const NavList = ({ className, header, children, footer }) => (
  <StyledNavList className={classnames('nav-list', className)}>
    {header && <div className="nav-list-header">{header}</div>}
    <div className="nav-list-items">{children}</div>
    {footer && <div className="nav-list-footer">{footer}</div>}
  </StyledNavList>
)

NavList.displayName = 'NavList'
NavList.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  footer: PropTypes.node,
}

NavList.defaultProps = {
  className: '',
  header: null,
  footer: null,
}

export const NavListLink = props => (
  <ConnectedLink
    className="nav-list-link"
    activeClassName="active"
    {...props}
  />
)
