import React from 'react'

import styled from 'styled-components'

import * as styleConsts from 'src/service-design/shared/style-constants'

// eslint-disable-next-line import/no-deprecated
import { Table } from './Table'

interface Style {
  columns: number
  customSizedColumns: string[]
}

// TODO: no idea how to pass the TRow type through to styled components
// eslint-disable-next-line import/no-deprecated
const StyledGridTable = styled(Table)<Style>`
  /* +1 for controls column */
  --columns: ${props => props.columns + 1};
  --customColumns: ${props => props.customSizedColumns.length};

  display: grid;
  grid-template-columns:
    ${props => props.customSizedColumns.join(' ')}
    repeat(calc(var(--columns) - var(--customColumns)), minmax(0, auto));
  grid-auto-rows: min-content;
  width: 100%;
  overflow-y: auto;

  .header,
  .body,
  .row {
    display: contents;
  }

  .header .cell,
  .footer {
    position: sticky;
    background: white;
    z-index: 101; /* controls is z-index 100 */
    border: 0 solid ${styleConsts.lightgrey0};
  }

  .header .cell {
    top: 0;
    border-bottom-width: 1px;
  }

  .footer {
    bottom: 0;
    grid-column: span var(--columns);
    border-top-width: 1px;
  }

  .cell {
    background: inherit;
  }

  .row .controls {
    width: 0;
    position: relative;
    display: flex;
    align-items: center;

    > div {
      transform: translateX(-100%);
    }
  }
`

interface RowType {
  id: string
}

interface CellType<TRow extends RowType> {
  displayName: string
  key: string
  cellDisplay?: (row: TRow) => React.ReactNode
  sortBy?: keyof TRow | ((row1: TRow, row2: TRow) => number)
}

interface GridTableProps<TRow extends RowType> {
  className?: string
  customSizedColumns?: string[]
  cells: CellType<TRow>[]
  data: TRow[]
  activateItem?: (id: string) => void
  active?: string
  RowActions?: React.ComponentType<{ row: TRow }>
  bodyScrolls?: boolean
  footer: React.ReactNode
}

export const GridTable = <TRow extends RowType>({
  cells,
  customSizedColumns = [],
  ...props
}: GridTableProps<TRow>) => (
  <StyledGridTable
    columns={cells.length}
    customSizedColumns={customSizedColumns}
    // @ts-ignore no idea how to pass the TRow type through to styled components
    cells={cells}
    {...props}
  />
)
